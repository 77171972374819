<template>
  <div>
    <skgmenu />
    <div class="PCIndex" @click="hideImgShow">
      <div class="contain">
        <swiper />
        <div>
          <div class="story">
            <video-swiper />
          </div>
          <div id="produts">
            <div
              class="k6"
              @click="openProDetail('newk5', '0')"
              style="
                backgroundimage: url('https://files.skg.com/skg/2023-02-10/8746f534-b7b3-4d85-b6ac-2c0190d4a0b3.jpg');
              "
            ></div>
            <ul class="kBox">
              <li
                @click="openProDetail('F5', '0')"
                style="
                  backgroundimage: url('https://files.skg.com/skg/2023-02-10/f74840d5-7ab9-4e5b-9306-9b8b10b1791f.jpg');
                "
              ></li>
              <li
                @click="openProDetail('E3', '0')"
                style="
                  backgroundimage: url('https://files.skg.com/skg/2023-02-10/a78a43d0-f9f0-44dd-96fe-c703de7d9134.jpg');
                "
              ></li>
            </ul>
          </div>
          <div class="nav">
            <div
              class="app"
              @mouseenter.stop="(showCode = false), (imgshow = true)"
              @click.stop="checkImgShow"
            >
              <img
                src="https://files.skg.com/skg/2023-02-08/d616c71b-07fc-4e0d-8e66-d1c871780ff0.png"
                alt=""
                class="icon"
              />
              <div class="text">下载APP</div>
              <img
                :class="imgshow ? 'toast' : 'toastHide'"
                src="https://files.skg.com/skg/2023-02-08/f13bf77e-517b-4351-b88a-4e11bd2328e3.png"
                @click.stop="stopEvent"
                alt=""
              />
            </div>
            <div
              class="app"
              @mouseenter.stop="(showCode = true), (imgshow = false)"
            >
              <img
                src="https://files.skg.com/skg/2024-04-24/e101f639-452d-4979-8e34-90712b3635a3.png"
                alt=""
                class="icon"
              />
              <div class="text">企业团购</div>
              <img
                :class="showCode ? 'code' : 'codeHide'"
                src="https://files.skg.com/skg/2024-04-24/35913a35-8d31-44d0-8976-9e0c14f3c60d.png"
                @click.stop="stopEvent"
                alt=""
              />
            </div>
            <div class="yiqu" @click="openKefu">
              <img
                src="https://files.skg.com/skg/2023-02-08/0275edfb-95ae-40e3-b7f8-70560aaaa643.png"
                alt=""
                class="icon"
              />
              <div class="text">在线客服</div>
            </div>
          </div>
          <!-- <div class="startUse">
            <p>明星都在用的</p>
            <p style="padding-top:10px">颈椎按摩仪</p>
            <swiper2 />
          </div>
          <div class="product">
            <div class="textBox">
              <div class="proTit">众多综艺指定按摩仪</div>
              <div class="proName">《乘风破浪的姐姐》《妻子的浪漫旅行4》《亲爱的请放松》</div>
              <div class="proName">《说唱听我的》《我要这样的生活》《我是唱作人2》</div>
              <div class="proName">《喜欢你我也是2》......</div>
            </div>
            <div class="videoBox">
              <video
                src="https://files.skg.com/skg.com/video/c81e728d9d4c2f636f067f89cc14862c.mp4"
                poster="https://files.skg.com/skg.com/20200909/1a7fd4067dc5445cb961b81d0a7e2c2a.jpg"
                class="indexVideo"
                controls="controls"
              ></video>
            </div>
          </div> -->
        </div>
      </div>
    </div>
    <div class="wrapIndex">
      <div class="contain">
        <div class="navApp">
          <img
            src="https://files.skg.com/skg/2023-02-08/e75e2973-e1f2-4b8b-8542-2f6159bea5d8.png"
            alt=""
            class="applogo"
          />
          <div class="navtitle">SKG健康</div>
          <div class="desc">你的贴身健康管家</div>
          <div class="downApp" @click="downloadApp">下载APP</div>
        </div>
        <swiper />
        <div>
          <div class="story">
            <video-swiper />
          </div>

          <div
            class="products"
            ref="product"
            v-for="(item, index) in modelInfo"
            :key="index"
            :style="{
              backgroundImage: 'url(' + item.bgUrl + ')',
              height: height,
            }"
            @click="openProDetail(item.params, '0')"
          >
            <div class="title" v-if="item.title">
              <img :src="item.title" alt class="model" />
            </div>
            <div class="notitle" v-if="!item.title"></div>
            <div class="productText" v-text="item.description"></div>
            <div class="productText" v-text="item.description2"></div>
            <div class="fun" v-text="item.function"></div>
            <div class="more" v-if="item.color" :style="{ color: item.color }">
              探索更多
              <img :src="item.to" alt />
            </div>
          </div>
          <!-- <div class="jinmo" @click="openProDetail('jinmo')"></div> -->
          <!-- <div class="startUse">
            <p>明星都在用的</p>
            <p>颈椎按摩仪</p>
            <swiper2 v-if="isPC" />
          </div> -->
          <!-- <div class="product">
            <div class="proTit">众多综艺指定按摩仪</div>
            <div class="proName">《乘风破浪的姐姐》《妻子的浪漫旅行4》《亲爱的请放松》</div>
            <div class="proName">《说唱听我的》《我要这样的生活》《我是唱作人2》</div>
            <div class="proName">《喜欢你我也是2》......</div>
            <div class="videoBox">
              <video
                src="https://files.skg.com/skg.com/20200902/6a378912ba1f40cabe882075b71cbb22.MP4"
                class="indexVideo"
                controls="controls"
                poster="https://files.skg.com/skg.com/20200909/1a7fd4067dc5445cb961b81d0a7e2c2a.jpg"
              ></video>
            </div>
          </div> -->
        </div>
      </div>
    </div>
    <myfooter />
  </div>
</template>


<script>
import skgmenu from "@/components/Modules/menu/menu.vue";
import myfooter from "@/components/Modules/footer/footer.vue";
import topMenu from "@/components/Modules/innerMenu/topMenu.vue";
import swiper from "@/components/Modules/Swiper/Swiper.vue";
import videoSwiper from "@/components/Modules/videoSwiper/videoSwiper.vue";
import swiper2 from "@/components/Modules/smallSwiper/smallSwiper.vue";
import swiper2Pc from "@/components/Modules/smallPcSwiper/smallSwiper.vue";
import { useRouter } from "vue-router";
export default {
  name: "temp",
  components: {
    skgmenu,
    myfooter,
    topMenu,
    swiper,
    swiper2,
    swiper2Pc,
    videoSwiper,
  },
  computed: {},
  data() {
    return {
      title: `SKG - 官方网站`,
      Keywords:
        "颈椎按摩仪,护颈仪,筋膜枪,按摩腰带,眼部按摩器,颈部按摩器,腰部按摩器",
      description:
        "SKG作为时尚的颈椎按摩仪品牌,集研发、生产、销售、培训为一体的专业大型国际企业,主要经营颈椎按摩仪,护颈仪,筋膜枪,按摩腰带,眼部按摩器,腰部按摩器,颈部按摩器,颈椎按摩器等,以超时尚设计理念,启发全球用户追求智能健康生活方式",
      id: -1,
      isopen: true,
      imgshow: false,
      showCode: false,
      src: {
        brandImg:
          "https://files.skg.com/skg.com/20200724/70a1e716d94f4bffb07695ff2fe4a8fb.png",
        videoImg: require("@/static/img/videoImg.png"),
        videoAutoPlay: require("@/static/img/autoPlay.png"),
        program:
          "https://files.skg.com/skg.com/20200724/ddefa79bcc4b41669a0d51216296e5b8.png",
        videoBottom: require("@/static/img/videoBottom.png"),
        to: require("@/static/img/whiteTo.png"),
      },
      videoImgShow: true,
      modelInfo: [
        {
          bgUrl:
            "https://files.skg.com/skg.com/20200918/8e2c38f181e84249887b9abe9f0ddc24.jpg",
          params: "newk5",
        },
        {
          bgUrl:
            "https://files.skg.com/skg.com/20200918/c4ca515a14154579992bb8628f962279.jpg",
          params: "F5",
        },
        {
          bgUrl:
            "https://files.skg.com/skg.com/20200918/9285315ccde1464b90858cb084f89d99.jpg",
          params: "E3",
        },
      ],
      height: "",
      offsetWidth: 0,
      isPC: false,
      patent: 450,
    };
  },
  props: {},
  created() {
    this.getPatentData();
  },
  mounted() {
    var width = document.body.clientWidth;
    this.height = width / 0.6 + "px";
    document.title = this.title;
    document
      .querySelector('meta[name="Keywords"]')
      .setAttribute("content", this.Keywords);
    document
      .querySelector('meta[name="description"]')
      .setAttribute("content", this.description);
    this.$nextTick(() => {
      this.setFootCenter();
      window.onresize = () => {
        this.setFootCenter();
      };
    });

    // (function (w, d, n, a, j) {
    //   w[n] = w[n] || function () {
    //     (w[n].a = w[n].a || []).push(arguments);
    //   };
    //   j = d.createElement('script');
    //   j.async = true;
    //   j.src ='https://askq.qiyukf.com/script/js?appkey=fccdd3dc5ce1863b36a6a397769cce44&subdomain=1';
    //   d.body.appendChild(j);
    // })(window, document, 'ysf');

    (function (w, d, n, a, j) {
      // w[n] = w[n] || function () {
      // 	(w[n].a = w[n].a || []).push(arguments);
      // };
      var timer = null;
      function debounce(fn, delay, mustRun) {
        var timeout,
          startTime = new Date();

        return function () {
          var context = this,
            args = arguments,
            curTime = new Date();

          clearTimeout(timeout);
          // 如果达到了规定的触发时间间隔，触发 handler
          if (curTime - startTime >= mustRun) {
            fn.apply(context, args);
            startTime = curTime;
            // 没达到触发间隔，重新设定定时器
          } else {
            timeout = setTimeout(fn, delay);
          }
        };
      }
      var node1 = document.createElement("div");
      node1.addEventListener("click", function () {
        if (document.documentElement.scrollTop > 1000) {
          let top =
            document.documentElement.scrollTop || document.body.scrollTop;
          const timeTop = setInterval(() => {
            document.body.scrollTop =
              document.documentElement.scrollTop =
              top -=
                50;
            if (top <= 0) {
              clearInterval(timeTop);
            }
          }, 10);
        }
      });
      window.addEventListener(
        "scroll",
        debounce(
          function () {
            if (document.documentElement.scrollTop > 1000) {
              node1.className = "backTop show";
            } else {
              node1.className = "backTop";
            }
          },
          100,
          100
        )
      );
      d.body.appendChild(node1);
    })(window, document, "123");
  },
  methods: {
    openProDetail(type, index) {
      this.$store.commit("setProductFlag", !this.$store.state.productFlag);
      this.$store.commit("setProductParams", type);
      this.showSearch = false;
      this.openburger = false;
    },
    openMobleCenter() {
      if (this.isopen) {
        this.$message({
          message: "敬请期待",
          center: true,
          offset: 150,
        });
        this.isopen = false;
      }
    },
    openKefu() {
      window.open(
        "https://webchat-sh.clink.cn/chat.html?accessId=f044a176-831b-4e26-8be4-267884a2bb09&tinetWebChatType=tinetResponsive"
      );
    },
    checkImgShow() {
      this.imgshow = true;
    },
    checkImgState() {
      this.imgshow = !this.imgshow;
    },
    hideImgShow() {
      this.imgshow = false;
      this.showCode = false;
    },
    stopEvent() {
      return false;
    },
    downloadApp() {
      window.open("https://app.skg.com/qrCodeDownload");
    },
    openCenter() {
      if (this.isopen) {
        this.$message({
          message: "敬请期待",
          center: true,
          offset: 400,
        });
        this.isopen = false;
      }
    },
    autoPlay: function () {
      if (document.body.clientWidth > 750) {
        var video = document.getElementById("pcVideo");
      } else {
        var video = document.getElementById("video");
      }
      // video.contro
      if (this.videoImgShow) {
        this.videoImgShow = false;
        video.play();
      } else {
        this.videoImgShow = true;
        video.pause();
      }
    },
    setFootCenter() {
      this.offsetWidth = document.body.offsetWidth;
      // var menu = document.getElementsByClassName('menu')[0]
      // if (this.offsetWidth >= 780 && this.offsetWidth < 1280) {
      //   menu.style.marginLeft = (this.offsetWidth - 740) / 2 + 'px'
      // } else if (this.offsetWidth >= 1280) {
      //   menu.style.marginLeft = (this.offsetWidth - 940) / 2 + 'px'
      // }
      if (this.offsetWidth < 420) {
        this.isPC = false;
      } else {
        this.isPC = true;
      }
      // console.log("offsetwidth",this.offsetWidth,this.isPC)
    },
    getPatentData() {
      let that = this;
      let opt = {
        url: `https://cn.skg.com/service/web/config/getByKey/patent`,
        method: "get",
      };
      console.log("111111", this.$store.state.imgUrl);
      this.$http(opt).then((res) => {
        if (res.code == 0) {
          that.patent = res.value;
        }
      });
    },
  },
  beforeRouteUpdate(to, from, next) {
    this.currentpath = to.path;
    this.dataList = [];
    console.log("to.meta.templateID", to.meta.templateID);
    this.id = to.meta.templateID;

    // this.dataList = this.testData.filter(item => item.templateID === to.meta.templateID)[0]||[],
    (this.dataList =
      this.initdata.filter(
        (item) => item.templateID === to.meta.templateID
      )[0] || []),
      // console.log(this.id)
      next();
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      document.documentElement.scrollTop = document.body.scrollTop = 0;
      vm.id = to.meta.templateID;
      //vm.dataList = vm.testData.filter(item => item.templateID === vm.id)[0]||[];
      vm.currentpath = to.path;
      // vm.$nextTick(()=>setTimeout(()=>vm.BScroll?vm.refreshScroll():vm.initScroll(),100))
    });
  },
  setup() {
    let that = this;
    const router = useRouter();

    const openProDetail = (type, index) => {
      that.$store.commit("setProductFlag", !this.$store.state.productFlag);
      that.$store.commit("setProductParams", type);
      that.showSearch = false;
      that.openburger = false;
      router.push({
        path: `/proDetail/${type}?index=${index}`,
      });
    };
    return {
      openProDetail,
    };
  },
};
</script>


<style scoped lang="scss">
.PCIndex {
  display: none;
}
.story {
  width: 100vw;
  margin-top: 0;
  background-color: #000;
}
.wrapIndex .story {
  padding: 10px 0;
}
p {
  font-family: DFPGB_Y5;
}

.navApp {
  width: 100%;
  height: 64px;
  background-color: rgba(239, 239, 239, 1);
  position: fixed;
  top: 48px;
  left: 0;
  z-index: 9999;
  .applogo {
    width: 40px;
    height: 40px;
    position: absolute;
    left: 12px;
    top: 12px;
  }
  .navtitle {
    color: #000;
    font-size: 12px;
    position: absolute;
    left: 65px;
    top: 12px;
  }
  .desc {
    font-size: 12px;
    color: #606060;
    position: absolute;
    left: 65px;
    top: 36px;
  }
  .downApp {
    width: 90px;
    height: 30px;
    background-color: #42d7c8;
    color: #fff;
    border-radius: 15px;
    text-align: center;
    line-height: 30px;
    position: absolute;
    right: 13px;
    top: 16px;
  }
}
.story p.cn {
  font-size: 29px;
  color: #42d7c8;
  text-align: center;
  margin: 0;
  padding-top: 41px;
}
.story p.en {
  text-align: center;
  margin: 0;
  color: rgba(0, 0, 0, 1);
  opacity: 0.1;
}
.title img {
  width: 100%;
}
.k6 {
  background-repeat: no-repeat;
}
.description {
  font-family: auto;
  text-align: center;
  color: #000;
  font-size: 12px;
  line-height: 20px;
}
.brandImg {
  width: 90vw;
  height: 90vw;
  margin-left: 5vw;
  margin-top: 5vw;
}
.brandImg img {
  width: 100%;
}
.products {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin-bottom: 2px;
}
.indexVideo {
  width: 90vw;
  height: 50vw;
  margin: 35px 0 0 5vw;
}
#videoAutoPlay {
  width: 20vw;
  height: 20vw;
  position: absolute;
  left: 40%;
  top: 27%;
}
#pcVideoAutoPlay {
  width: 20vw;
  height: 20vw;
  position: absolute;
  left: 29%;
  top: 22%;
}

.videoBottom {
  width: 80vw;
  height: 1vh;
  position: absolute;
  bottom: 0.5vh;
  left: 7vw;
}
.videoBottom img {
  width: 95%;
  height: 4vh;
}
.jinmo {
  width: 100vw;
  height: 163vw;
  background-color: #42d7c8;
  background: url("https://files.skg.com/skg.com/20200914/e0290ff9db26418188fb28363a7fc172.jpg")
    no-repeat;
  background-size: 100% 100%;
}
.startUse {
  width: 100vw;
  height: 131vw;
  background: #42d7c8;
  font-size: 30px;
  padding-top: 10vh;
}
.startUse p {
  margin: 0;
  text-align: center;
}
.product {
  margin: 0;
  width: 100vw;
  height: 110vw;
}
.title {
  font-family: DFPGB_Y5;
  font-size: 24px;
  text-align: center;
  width: 30vw;
  height: 14vh;
  line-height: 10vh;
  margin-left: 35vw;
  display: flex;
  align-items: center;
}
.notitle {
  padding-top: 10vh;
}
.model {
  width: 12vw;
}
.productText {
  font-family: DFPGB_Y5;
  font-size: 28px;
  width: 100vw;
  text-align: center;
}
.fun {
  width: 100vw;
  text-align: center;
  font-size: 12px;
  margin-top: 1vh;
}
.more {
  font-family: DFPGB_Y5;
  font-size: 4vw;
  text-align: center;
  line-height: 7vh;
  width: 24vw;
  margin-left: 38vw;
}
.more img {
  width: 6px;
  height: 10px;
  margin-left: 6px;
}
.proTit {
  font-family: DFPGB_Y5;
  font-size: 30px;
  text-align: center;
  padding: 7vh 0 3vh 0;
}
.proName {
  width: 100vw;
  font-size: 12px;
  text-align: center;
}
@media screen and (min-width: 768px) {
  .PCIndex {
    display: block;
  }
  .wrapIndex {
    display: none;
  }
  .PCIndex {
    overflow-x: hidden;
    .nav {
      width: 89px;
      border-radius: 14px;
      position: fixed;
      user-select: none;
      background-color: #fff;
      box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.2);
      bottom: 100px;
      right: 40px;
      z-index: 999;
      box-sizing: border-box;
      padding: 7px;
      .app,
      .yiqu {
        width: 100%;
        height: 98px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        cursor: pointer;
        align-items: center;
        .icon {
          width: 30px;
          height: 30px;
        }
        .text {
          font-family: DFPGB_y5;
          font-size: 12px;
          margin-top: 10px;
        }
      }
      .app {
        border-bottom: 1px solid #d2d1d1;
        .code {
          opacity: 0; /*设置不透明度*/
          width: 200px;
          height: 200px;
          position: absolute;
          right: 100px;
          animation: codeInAnimation ease 0.5s;
          animation-iteration-count: 1; /*设置动画播放次数*/
          animation-fill-mode: forwards;
        }
        @keyframes codeInAnimation {
          0% {
            opacity: 0; /*设置不透明度*/
            bottom: 250px;
            width: 0px;
          }
          100% {
            opacity: 1;
            bottom: 0;
            width: 200px;
          }
        }
        .toast {
          position: absolute;
          right: 100px;
          animation: fadeInAnimation ease 0.5s;
          animation-iteration-count: 1; /*设置动画播放次数*/
          animation-fill-mode: forwards;
        }
        @keyframes fadeInAnimation {
          0% {
            opacity: 0; /*设置不透明度*/
            bottom: 150px;
            width: 0px;
          }
          100% {
            opacity: 1;
            bottom: 0;
            width: 1000px;
          }
        }
        .codeHide {
          position: absolute;
          right: 100px;
          animation: codeOutAnimation ease 0.5s;
          animation-iteration-count: 1; /*设置动画播放次数*/
          animation-fill-mode: forwards;
        }
        @keyframes codeOutAnimation {
          0% {
            opacity: 1;
            bottom: 0;
            width: 200px;
          }
          100% {
            opacity: 0; /*设置不透明度*/
            bottom: 150px;
            width: 0px;
          }
        }
        .toastHide {
          position: absolute;
          right: 100px;
          animation: fadeOutAnimation ease 0.5s;
          animation-iteration-count: 1; /*设置动画播放次数*/
          animation-fill-mode: forwards;
        }
        @keyframes fadeOutAnimation {
          0% {
            opacity: 1;
            bottom: 0;
            width: 1000px;
          }
          100% {
            opacity: 0; /*设置不透明度*/
            bottom: 150px;
            width: 0px;
          }
        }
      }
    }
    .startUse {
      width: 100vw;
      height: 53vw;
      font-size: 31px;
      padding-top: 15vh;
      font-weight: bold;
      letter-spacing: 3px;
    }
    .swiper-wrapper {
      height: 60vh !important;
    }
    .product {
      height: 820px;
      position: relative;
      .proName {
        width: auto;
        text-align: center;
        font-size: 15px;
      }
      .textBox {
        .proTit {
          font-weight: bold;
          text-align: center;
          font-size: 40px;
          color: rgb(0, 0, 0);
          text-align: center;
        }
      }
    }
    .description {
      font-family: auto;
      text-align: center;
      color: #000;
      font-size: 15px;
      line-height: 25px;
      padding-top: 10px;
    }
    .indexVideo {
      width: 44vw;
      height: 25vw;
      margin: 0;
    }
    .videoBox {
      width: 44vw;
      height: 25vw;
      margin-left: 28vw;
      margin-top: 34px;
    }
    .videoBottom {
      width: 46vw;
      height: auto;
      position: absolute;
      bottom: -13%;
      left: 13vw;
    }
    .videoBottom img {
      height: auto;
    }
    #videoAutoPlay {
      width: 12vw;
      height: 12vw;
      position: absolute;
      left: 34%;
      top: 32%;
    }
    #produts {
      width: 100%;
      background-color: #000;
      height: 63vw;
      .k6 {
        height: 32vw;
        width: 100%;
        background-size: contain;
        background-position: center center;
        cursor: pointer;
      }
      .kBox {
        height: 31vw;
        width: 100%;
        li {
          width: 49.9%;
          height: 100%;
          box-sizing: border-box;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: top;
          float: left;
          cursor: pointer;
          &:nth-child(2) {
            margin-left: 0.2%;
          }
          .KTitle_1 {
            font-size: 21px;
            letter-spacing: 2px;
            text-align: center;
            padding-top: 10px;
            b {
              font-size: 26px;
            }
          }
          .KTitle_2 {
            text-align: center;
            padding-top: 7px;
            font-size: 14px;
          }
          .KTitle_3 {
            font-size: 12px;
            text-align: center;
            padding-top: 7px;
          }
          .ts {
            text-align: center;
            font-size: 12px;
            color: #42d7c8;
            padding-top: 7px;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .PCIndex #produts .kBox {
    height: 31vw;
    width: 100%;
  }
  .PCIndex #produts .kBox li {
    width: 49.9%;
    height: 100%;
    box-sizing: border-box;

    background-size: contain;
  }
  .PCIndex #produts .k6 {
    height: 32vw;
    background-repeat: no-repeat;
  }
  .PCIndex .product .textBox {
    position: static;
    .proTit {
      font-weight: bold;
      text-align: left;
      font-size: 40px;
      color: rgb(0, 0, 0);
      text-align: center;
    }
    .proName {
      text-align: center;
    }
  }
  .PCIndex .videoBox {
    position: static;
    width: 70vw;
    height: 40vw;
    margin-left: 15vw;
    .indexVideo {
      width: 70vw;
      height: 40vw;
    }
  }
  .PCIndex .startUse {
    height: 100vh;
  }
}
@media screen and (min-width: 1025px) and (max-width: 1550px) {
  .nav {
    width: 89px;
    border-radius: 14px;
    position: fixed;
    background-color: #fff;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.2);
    bottom: 100px;
    right: 40px;
    z-index: 999;
    box-sizing: border-box;
    padding: 7px;
    .app,
    .yiqu {
      width: 100%;
      height: 98px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      cursor: pointer;
      align-items: center;
      .icon {
        width: 30px;
        height: 30px;
      }
      .text {
        font-family: DFPGB_y5;
        font-size: 12px;
        margin-top: 10px;
      }
    }
    .app {
      border-bottom: 1px solid #d2d1d1;
      .toast {
        position: absolute;
        right: 100px;
        animation: fadeInAnimation ease 0.5s;
        animation-iteration-count: 1; /*设置动画播放次数*/
        animation-fill-mode: forwards;
      }
      @keyframes fadeInAnimation {
        0% {
          opacity: 0; /*设置不透明度*/
          bottom: 150px;
          width: 0px;
        }
        100% {
          opacity: 1;
          bottom: 0;
          width: 800px;
        }
      }
      .toastHide {
        position: absolute;
        right: 100px;
        animation: fadeOutAnimation ease 0.5s;
        animation-iteration-count: 1; /*设置动画播放次数*/
        animation-fill-mode: forwards;
      }
      @keyframes fadeOutAnimation {
        0% {
          opacity: 1;
          bottom: 0;
          width: 800px;
        }
        100% {
          opacity: 0; /*设置不透明度*/
          bottom: 150px;
          width: 0px;
        }
      }
    }
  }
  .PCIndex .product .textBox {
    .proTit {
      font-weight: bold;
      text-align: left;
      font-size: 40px;
      color: rgb(0, 0, 0);
      text-align: center;
    }
  }
}
.PCIndex .startUse {
  height: 100vh;
}
.PCIndex .product {
  height: 700px;
}

@media screen and (min-width: 1551px) and (min-width: 1920px) {
  .PCIndex .product .textBox {
    .proTit {
      font-weight: bold;
      text-align: left;
      font-size: 40px;
      color: rgb(0, 0, 0);
      text-align: center;
    }
  }
  .PCIndex .startUse {
    height: 53vw;
  }
  .PCIndex .product {
    height: 820px;
  }
}
</style>

