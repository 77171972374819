<template>
  <div class="proDetail">
    <skgMenu />
    <div class="ads">
      <div class="photo" v-for="(item,index) in proKind" :key="index">
        <img :src="item" alt="">
      </div>
    </div>
    <Foot />
  </div>
</template>

<script>
import skgMenu from "@/components/Modules/menu/menu.vue"
import Foot from "@/components/Modules/footer/footer.vue"
export default {
	name: "Store",
	components: {
			skgMenu,
			Foot
  },
  watch:{
    "$store.state.productFlag":function(){
      window.scrollTo(0,0)
      // this.$route.query.index = this.$store.state.productParams;
      this.getDetail();
      // console.log(this.$route.query)
      let productInfo = this.productDetail[this.$route.params.id][this.$route.query && this.$route.query.index]
      document.title= productInfo.title
      document.querySelector('meta[name="Keywords"]').setAttribute('content', productInfo.description)
      document.querySelector('meta[name="description"]').setAttribute('content', productInfo.keywords)
    }
  },
  data(){
    return {
      proKind:  [],
      k6: [
        "https://files.skg.com/skg/2023-03-24/62264f0d-47b5-4122-8134-fdce549bee5b.jpg",
        "https://files.skg.com/skg/2023-03-24/95c5463a-a72d-40cc-9326-cfaca768c268.jpg",
        "https://files.skg.com/skg/2023-03-24/ea9e2182-6ff2-4ed9-86df-9acdfd078cd1.jpg",
        "https://files.skg.com/skg/2023-03-24/6d8bd342-c4b9-4251-a5be-a519c5292d71.jpg",
        "https://files.skg.com/skg/2023-03-24/5c493054-cb9f-470d-b3f5-b3f4c2564da3.jpg",
        "https://files.skg.com/skg/2023-03-24/a2edfa79-b77b-4197-931c-bd5e55e0002f.jpg",
        "https://files.skg.com/skg/2023-03-24/4c4b588c-2886-4529-b567-b66e9b3521dd.jpg",
        "https://files.skg.com/skg/2023-03-24/5bcae66f-0b7d-4dc0-a767-7f6481cc42ff.jpg",
        "https://files.skg.com/skg/2023-03-24/e273bdc9-0737-467d-b177-34e7e93aab9a.jpg",
        "https://files.skg.com/skg/2023-03-24/dba126e3-ee6a-408e-9668-281e095c710c.jpg",
        "https://files.skg.com/skg/2023-03-24/7b983d00-8d2a-4227-ac69-cfc400ade19c.jpg",
        "https://files.skg.com/skg/2023-03-24/cd9fc836-b807-415b-a940-875d1acfbc70.jpg",
        "https://files.skg.com/skg/2023-03-24/c6047587-3119-4194-8d5c-51d57b9c8f31.jpg",
        "https://files.skg.com/skg/2023-03-24/43e234b8-3713-4dae-913b-dac0d39dfdfd.jpg",
        "https://files.skg.com/skg/2023-03-24/7c22cb45-e59b-45c5-9c7c-dce8d2a9a738.jpg",
        "https://files.skg.com/skg/2023-03-24/1d8a389f-6e65-4d76-ad9f-2c7713e4232d.jpg",
        "https://files.skg.com/skg/2023-03-24/b173f7e2-4595-41ba-9e71-1449d09e900f.jpg"
    ],
      k5JD:[
        "https://files.skg.com/skg/2022-06-13/9a139076-77b2-468b-ac28-2f8cc694c3e7.jpg",
        "https://files.skg.com/skg/2022-06-13/526fa494-8d5e-42b6-a863-3640abd38e30.jpg",
        "https://files.skg.com/skg/2022-06-13/31b33572-5df2-4771-be6e-b41480c1f9a8.jpg",
        "https://files.skg.com/skg/2022-06-13/94de8a35-8a0a-4b58-a0ef-d72a4f12d9f8.jpg",
        "https://files.skg.com/skg/2022-06-13/8b26a6ee-8428-4381-8466-a1c36e11fdd1.jpg",
        "https://files.skg.com/skg/2022-06-13/7f384e4b-90ed-4114-9171-8fca2472db12.jpg",
        "https://files.skg.com/skg/2022-06-13/3a9ece82-4d76-40d7-8ec8-a3f2cb5dbb9a.jpg",
        "https://files.skg.com/skg/2022-06-13/cbd4b7fe-a167-4aa9-b1bf-3803b7128e91.jpg",
        "https://files.skg.com/skg/2022-06-13/ad03a841-cf5a-4c59-99fd-2d3b9e6cbbfe.jpg",
        "https://files.skg.com/skg/2022-06-13/59814ab3-da4f-4d44-9341-b3f3d780776d.jpg",
        "https://files.skg.com/skg/2022-06-13/e17a2cbe-e48d-4f31-9940-e7a5c43dc157.jpg",
        "https://files.skg.com/skg/2022-06-13/e33ddc51-d618-44bb-b7d7-052b397cd380.jpg",
        "https://files.skg.com/skg/2022-06-13/ec352942-af18-4545-85cb-e89bdb097b9d.jpg",
        "https://files.skg.com/skg/2022-06-13/0f126483-37fd-470e-9798-544b25f74223.jpg",
        "https://files.skg.com/skg/2022-06-13/a8221cfe-4132-4861-ad04-6a42efa718f5.jpg",
        "https://files.skg.com/skg/2022-06-13/1057d604-910e-4ce1-85fe-592c1ea1552d.jpg",
        "https://files.skg.com/skg/2022-06-13/8ed69c60-d8a2-4afd-ae58-98e03f015a35.jpg",
        "https://files.skg.com/skg/2022-06-13/b43f863d-69c6-48f9-9362-b384fc3f58b6.jpg",
        "https://files.skg.com/skg/2022-06-13/4ceef1aa-e474-4367-987c-76fcc6050f03.jpg",
        "https://files.skg.com/skg/2022-06-13/919b4822-8d2b-4c37-b2ad-bbfb191c0366.jpg",
        "https://files.skg.com/skg/2022-06-13/da95d41f-9257-483a-b715-c9f415c9b3be.jpg"
    ],
      k5ZG: [
        "https://files.skg.com/skg/2022-09-06/ce5851ec-2432-4e62-bed1-43b34514d87d.jpg",
        "https://files.skg.com/skg/2022-09-06/5a7b8863-12a2-4f37-809b-5b369fb1ecdc.jpg",
        "https://files.skg.com/skg/2022-09-06/3864f64b-9a8f-485f-bb20-2ac04655be49.jpg",
        "https://files.skg.com/skg/2022-09-06/f87b83ee-fb69-4aba-bf3d-c9998a28f9ac.jpg",
        "https://files.skg.com/skg/2022-09-06/03ed46c9-ccd5-434f-b22c-35e5787f3414.jpg",
        "https://files.skg.com/skg/2022-09-06/e6a2322c-869d-476a-9128-8c8bd454e5af.jpg",
        "https://files.skg.com/skg/2022-09-06/16e168b1-e894-435f-9688-a68281627d49.jpg",
        "https://files.skg.com/skg/2022-09-06/5f0cd550-6a5a-47b1-af21-035e281c6ab0.jpg",
        "https://files.skg.com/skg/2022-09-06/146d0c18-016f-4632-b25f-68b297dac5f4.jpg",
        "https://files.skg.com/skg/2022-09-06/db0c2f69-a379-4335-93a7-382343028a71.jpg",
        "https://files.skg.com/skg/2022-09-06/d1b81c64-c6f7-4c13-a2c7-098e40fb6420.jpg",
        "https://files.skg.com/skg/2022-09-06/7f3dc152-9761-4ed6-8fdd-1f1d644188ee.jpg",
        "https://files.skg.com/skg/2022-09-06/40d113db-8cac-4580-9feb-722d1ee2a064.jpg",
        "https://files.skg.com/skg/2022-09-06/de563ccd-0d88-4b45-af2b-67a34e49d76f.jpg"
    ],
      k5BL: [
        "https://files.skg.com/skg/2023-03-24/3646a224-9284-4faa-9287-bce7c1d90e6a.jpg",
        "https://files.skg.com/skg/2023-03-24/d1785a80-7de7-4d1f-b297-71f99dbfc3d2.jpg",
        "https://files.skg.com/skg/2023-03-24/37e74f69-5eaf-4407-aff1-616ef6742b86.jpg",
        "https://files.skg.com/skg/2023-03-24/ed428189-7a73-4a47-971a-4f1504378b30.jpg",
        "https://files.skg.com/skg/2023-03-24/4e8995de-bd35-4998-858e-e9548fa4a53f.jpg",
        "https://files.skg.com/skg/2023-03-24/ae2fd844-efdf-4dad-9a63-ca1d09c7ea67.jpg",
        "https://files.skg.com/skg/2023-03-24/7bbaec86-ff4c-46b8-985e-f93d8d8c673d.jpg",
        "https://files.skg.com/skg/2023-03-24/7a2a633e-75f2-4b17-a963-1ddabf4e733a.jpg",
        "https://files.skg.com/skg/2023-03-24/6ce14dda-f493-4334-990d-0d5f6d84af10.gif",
        "https://files.skg.com/skg/2023-03-24/229cba30-e36f-4693-8ba9-b51e16258431.jpg",
        "https://files.skg.com/skg/2023-03-24/c841f393-3e07-4ea0-8b0d-86f98e41e33e.gif",
        "https://files.skg.com/skg/2023-03-24/d3b12eb1-7f8d-40fe-a263-5be4677c2f37.jpg",
        "https://files.skg.com/skg/2023-03-24/fc5544a5-581f-41b8-8ba7-36c5b5a85c0e.jpg",
        "https://files.skg.com/skg/2023-03-24/a44ad3c5-19b3-46a5-a4a9-5a8c45bbe82f.jpg",
        "https://files.skg.com/skg/2023-03-24/deb6b476-9c0a-4909-949b-c804d551176c.jpg",
        "https://files.skg.com/skg/2023-03-24/1072a431-4088-4548-b339-56bf71ac318e.jpg",
        "https://files.skg.com/skg/2023-03-24/f8035f90-b159-4fe2-ba91-5e893101d3c1.jpg",
        "https://files.skg.com/skg/2023-03-24/c540efb4-a6c8-4e21-beab-dd9faf939959.jpg"
    ],
      newk5: [
        "https://files.skg.com/skg/2023-03-24/cd27fd1f-3e6b-45ce-8bba-dce3c1a12912.jpg",
        "https://files.skg.com/skg/2023-03-24/1e299849-31b8-4e2c-bc26-286ff45fe56c.jpg",
        "https://files.skg.com/skg/2023-03-24/46421c65-02c3-444e-bea9-dfb74b3e934d.jpg",
        "https://files.skg.com/skg/2023-03-24/d01aa5d5-f4f8-471b-9f8e-4cf6689f1739.jpg",
        "https://files.skg.com/skg/2023-03-24/952f2196-e948-44ef-9f65-962ca6d3bf3f.jpg",
        "https://files.skg.com/skg/2023-03-24/15ef7a34-a4b1-474c-91c5-cb79c5ef81c9.jpg",
        "https://files.skg.com/skg/2023-03-24/71290ece-c842-4699-b87e-5745ec93f49b.jpg",
        "https://files.skg.com/skg/2023-03-24/bef997cf-699f-4ea5-bea2-6d092f60b491.jpg",
        "https://files.skg.com/skg/2023-03-24/1fd607a6-64ce-4427-b0fb-1ebf804e6ea0.jpg",
        "https://files.skg.com/skg/2023-03-24/7afa36a6-6765-4db8-92d5-c5e5c1680b25.jpg",
        "https://files.skg.com/skg/2023-03-24/988de8cf-f319-4260-9cf3-79a983d0b641.jpg",
        "https://files.skg.com/skg/2023-03-24/fc72e03c-7c97-4497-a5d6-39fb358fabe1.jpg",
        "https://files.skg.com/skg/2023-03-24/324940ab-9149-43b1-9ee4-be781a02eefa.jpg",
        "https://files.skg.com/skg/2023-03-24/0d4cad3c-892a-4224-868b-79ac97d58293.jpg",
        "https://files.skg.com/skg/2023-03-24/0a7fd559-40f0-4e50-80c4-a76f930a2fcb.jpg",
        "https://files.skg.com/skg/2023-03-24/358a1d2e-525e-49fa-8ef8-eea3522ec067.jpg"
    ],
      k6SLH: [
        "https://files.skg.com/skg/2022-06-13/1433acd0-4597-4bbd-844f-ceb6b4725200.jpg",
        "https://files.skg.com/skg/2022-06-13/9f2aea6d-27f3-4422-b2f6-2e43f3cae645.jpg",
        "https://files.skg.com/skg/2022-06-13/9967d282-b6de-4d2e-bc64-d4b95ab7548c.jpg",
        "https://files.skg.com/skg/2022-06-13/7c707a95-35ac-412a-b685-c8d709874e02.jpg",
        "https://files.skg.com/skg/2022-06-13/35557904-0994-40e1-8507-1c6cd9390bb9.jpg",
        "https://files.skg.com/skg/2022-06-13/a7e36a49-b1bc-4394-9ed2-6e5cff534c57.jpg",
        "https://files.skg.com/skg/2022-06-13/47e533a1-2e68-4811-b2ab-e44dc37b274c.jpg",
        "https://files.skg.com/skg/2022-06-13/70842d17-38de-42e0-948b-9380180a143c.jpg",
        "https://files.skg.com/skg/2022-06-13/0c044c40-a833-42b1-a49f-5227ed5e4916.jpg",
        "https://files.skg.com/skg/2022-06-13/a950272f-3f17-4ccb-8da0-46874e05ccc7.jpg",
        "https://files.skg.com/skg/2022-06-13/3d562755-f87d-4cf0-bfdd-912e91661347.jpg",
        "https://files.skg.com/skg/2022-06-13/4aa675a4-7edd-401b-a93c-353c76c54b6f.jpg",
        "https://files.skg.com/skg/2022-06-13/f6edf111-9a23-42d3-9b24-62086a305fc1.jpg",
        "https://files.skg.com/skg/2022-06-13/bf1b0cac-95d9-4469-991b-b0f026626ab0.jpg",
        "https://files.skg.com/skg/2022-06-13/ad4667b9-4817-4d6a-b1ca-d936803d8717.jpg",
        "https://files.skg.com/skg/2022-06-13/e7cfafee-18bf-4430-96a5-d604418c303f.jpg",
        "https://files.skg.com/skg/2022-06-13/43f9ba64-fecd-4bd7-8859-bae897891402.jpg",
        "https://files.skg.com/skg/2022-06-13/7eb3a617-5f2c-4a4a-ae26-d1752863d27b.jpg",
        "https://files.skg.com/skg/2022-06-13/c0ea1722-5c16-42f7-ba5c-46e2cd6781d6.jpg",
        "https://files.skg.com/skg/2022-06-13/dabf3b10-debe-422c-af3e-892a0be40410.jpg",
        "https://files.skg.com/skg/2022-06-13/54d0f74f-d426-47d9-be5e-ef3883be0cb7.jpg",
        "https://files.skg.com/skg/2022-06-13/d601b0c1-5e76-42e4-bdff-0de699e7e6b4.jpg",
        "https://files.skg.com/skg/2022-06-13/35f6d1ce-bdb4-4253-9f31-b84169272149.jpg",
        "https://files.skg.com/skg/2022-06-13/f206384f-ee1b-4b0e-b8b2-ae4c86f29897.jpg",
        "https://files.skg.com/skg/2022-06-13/9c28aea9-5eb8-4405-8352-55562d5b75d5.jpg",
        "https://files.skg.com/skg/2022-06-13/35b7707f-03b1-4438-8797-59c86b6034ec.jpg"
    ],
      k4: [
        "https://files.skg.com/skg/2023-03-24/3da6d845-331a-4d3d-b58c-9727efcda63f.jpg",
        "https://files.skg.com/skg/2023-03-24/af7b473f-160d-439b-9317-86528c5678f9.jpg",
        "https://files.skg.com/skg/2023-03-24/75259c21-740f-40d0-bc9f-47d6169f8e36.jpg",
        "https://files.skg.com/skg/2023-03-24/3aa1010f-f0ca-407f-9b08-6688fe63710b.jpg",
        "https://files.skg.com/skg/2023-03-24/dde24ef8-103f-4e0d-89a7-3480db9a0dbd.jpg",
        "https://files.skg.com/skg/2023-03-24/588f0a39-1312-44f4-bc59-1a0377b192df.jpg",
        "https://files.skg.com/skg/2023-03-24/ee1add3a-7c07-4778-96b6-e3db529588a2.jpg",
        "https://files.skg.com/skg/2023-03-24/aacd3883-1a0b-441a-97d9-fc72ad58c4d5.jpg",
        "https://files.skg.com/skg/2023-03-24/4a57a58b-1c77-41d3-9f9b-77bbb0291925.jpg",
        "https://files.skg.com/skg/2023-03-24/e175297e-c17a-430d-9cf3-f053064cb5da.jpg",
        "https://files.skg.com/skg/2023-03-24/b7794477-ba9b-4a2a-9cfc-60c454d5444b.jpg",
        "https://files.skg.com/skg/2023-03-24/034f57c8-c9c2-4644-9d37-0e1795087787.jpg",
        "https://files.skg.com/skg/2023-03-24/958c3dfc-5b11-41f0-95a8-eb2bd7130567.jpg",
        "https://files.skg.com/skg/2023-03-24/26abcb49-d30a-44c8-9665-bf7236095239.jpg",
        "https://files.skg.com/skg/2023-03-24/b6b1d987-22e1-48a5-9c03-adc6f416e5b8.jpg"
    ],
      k3_2: [
        "https://files.skg.com/skg/2022-09-06/d90cde16-0d9a-4359-b0fe-e0692b874fbc.jpg",
        "https://files.skg.com/skg/2022-09-06/14f8cc48-49ad-4e0e-9928-a0b998fda1eb.jpg",
        "https://files.skg.com/skg/2022-09-06/bc87778f-4dff-4989-b1aa-9111cb43ae4d.jpg",
        "https://files.skg.com/skg/2022-09-06/f27619c6-1608-4798-aa22-5eec3b53265f.jpg",
        "https://files.skg.com/skg/2022-09-06/d9653ae4-4158-4a87-944a-e2db3f029cca.jpg",
        "https://files.skg.com/skg/2022-09-06/fd5e7ff1-a16d-4b66-bec1-285a8e3a6850.jpg",
        "https://files.skg.com/skg/2022-09-06/8929a549-5dfe-4ecc-8f19-43fc66c582b1.jpg",
        "https://files.skg.com/skg/2022-09-06/c018fe5c-db73-4862-b969-8f496a25cfed.jpg",
        "https://files.skg.com/skg/2022-09-06/aad506b6-0ce2-4630-b43c-2964e48f3aad.jpg",
        "https://files.skg.com/skg/2022-09-06/7328fba8-75f9-455b-9d4a-08bfeb3ec636.jpg",
        "https://files.skg.com/skg/2022-09-06/7040933b-5b21-4d47-b9f3-6bbb5349b25d.jpg",
        "https://files.skg.com/skg/2022-09-06/672d9718-3a2f-43d5-a916-6a548e258037.jpg",
        "https://files.skg.com/skg/2022-09-06/c1e69587-1098-4033-b17f-74eea64d2cee.jpg",
        "https://files.skg.com/skg/2022-09-06/954f1955-d1fa-4d32-9eca-735d982062dd.jpg",
        "https://files.skg.com/skg/2022-09-06/6d34a66d-057b-4d1c-923b-dbfa39d531a5.jpg",
        "https://files.skg.com/skg/2022-09-06/0f70c860-74ce-45ac-b964-f81f676daf04.jpg",
        "https://files.skg.com/skg/2022-09-06/36e27e85-66e0-44e6-a692-ac4ab7708813.jpg",
        "https://files.skg.com/skg/2022-09-06/7fbac064-3b6d-43c8-bca9-25c58d00c9ce.jpg",
        "https://files.skg.com/skg/2022-09-06/a4ce9a5b-fcdb-4daf-9a7e-74ec12cc8e13.jpg",
        "https://files.skg.com/skg/2022-09-06/5394e84f-93e9-48bb-93be-2529ab1c4df3.jpg",
        "https://files.skg.com/skg/2022-09-06/1c4bf4e0-c15b-40d6-a7d0-fae3e65123e3.jpg"
    ],
      k3_3: [
        "https://files.skg.com/skg/2023-03-24/41cb1baf-2941-4b1f-9283-400ee7221556.jpg",
        "https://files.skg.com/skg/2023-03-24/7712e9a0-9bf2-41db-b037-22950b99a09a.jpg",
        "https://files.skg.com/skg/2023-03-24/b351169e-fb78-457e-b753-dbc37eb1d73d.jpg",
        "https://files.skg.com/skg/2023-03-24/64c0a6f2-790a-468b-915b-978628738017.jpg",
        "https://files.skg.com/skg/2023-03-24/7d6fbccf-aa7b-4030-869b-a6f53cc09809.jpg",
        "https://files.skg.com/skg/2023-03-24/03a293b4-3fb5-4c5a-894d-07565c2e5217.jpg",
        "https://files.skg.com/skg/2023-03-24/5632ec00-d439-48e6-9f2a-b414c9dae9c2.jpg",
        "https://files.skg.com/skg/2023-03-24/5a057ea9-93da-4768-b1b7-3b4dedfdfb25.jpg",
        "https://files.skg.com/skg/2023-03-24/f296c19f-f3b1-4c5c-ae24-8c58f3b4da9e.jpg",
        "https://files.skg.com/skg/2023-03-24/7142cd4e-534a-49db-8ddc-79d77319c839.jpg",
        "https://files.skg.com/skg/2023-03-24/4ea6549e-883a-41bf-9861-3e0a6c5d143e.jpg",
        "https://files.skg.com/skg/2023-03-24/f8457dc8-2b47-4e5f-9f01-bbd778391029.jpg",
        "https://files.skg.com/skg/2023-03-24/86a07ef5-a8e5-4cdc-ae1b-34e7e5c2592b.jpg",
        "https://files.skg.com/skg/2023-03-24/15df2419-9030-4a77-b2d4-fadd390bdc03.jpg",
        "https://files.skg.com/skg/2023-03-24/7135a9f5-4646-4fa5-8318-e34e1f54d902.jpg"
    ],
      dz_1: [
        "https://files.skg.com/skg/2022-06-21/8b71fd2c-f891-43bb-b2b5-4d6d11dc822c.jpg",
        "https://files.skg.com/skg/2022-06-21/f5a1d729-d35f-458b-8b2e-65f60787e0c1.jpg",
        "https://files.skg.com/skg/2022-06-21/8768360e-c909-44c9-92f8-75b93aadeb61.jpg",
        "https://files.skg.com/skg/2022-06-21/dd8ced03-ccd4-41b4-86a9-0a0cce54a5ca.jpg",
        "https://files.skg.com/skg/2022-06-21/d78e884a-a94d-41c1-a44a-53784039efab.jpg",
        "https://files.skg.com/skg/2022-06-21/4943862f-fa3b-420c-8db5-d1b7627b5dfd.jpg",
        "https://files.skg.com/skg/2022-06-21/485b42a5-42bd-4c5a-afe7-bbb91032bb7a.jpg",
        "https://files.skg.com/skg/2022-06-21/e1080d6d-3be2-48f1-ba98-06374dd1a8d2.jpg",
        "https://files.skg.com/skg/2022-06-21/70211ed2-3e99-4998-a78a-574ff9693206.jpg",
        "https://files.skg.com/skg/2022-06-21/6b0bfca1-9c29-446e-a813-5baad84fbcea.jpg",
        "https://files.skg.com/skg/2022-06-21/8b6aca43-9250-4041-81da-150ab8c24e65.jpg",
        "https://files.skg.com/skg/2022-06-21/919333dc-4684-40fd-8623-cb3afa88e33e.jpg",
        "https://files.skg.com/skg/2022-06-21/3230bc32-0633-4e07-ad6d-5be60d8fa7cf.jpg",
        "https://files.skg.com/skg/2022-06-21/3046f40a-8978-4008-a99b-3138f86b2c1a.jpg",
        "https://files.skg.com/skg/2022-06-21/5db7e01f-2965-4c41-81f9-edb7a81f973a.jpg",
        "https://files.skg.com/skg/2022-06-21/1f31d5a5-125e-4386-85b0-ec2f505f291c.jpg",
        "https://files.skg.com/skg/2022-06-21/5fe4f745-2aef-4ff2-8769-d0222976f09c.jpg",
        "https://files.skg.com/skg/2022-06-21/1402d4f3-df16-40b2-b8dc-2b4e1e8df006.jpg",
        "https://files.skg.com/skg/2022-06-21/70b9b7fb-a624-4af6-930f-d4887d37a9b5.jpg",
        "https://files.skg.com/skg/2022-06-21/c2b40936-33db-4a1a-9b28-5940bbb253f2.jpg",
        "https://files.skg.com/skg/2022-06-21/acf7a734-1d65-4d3b-ac28-71625e8ec685.jpg",
        "https://files.skg.com/skg/2022-06-21/950f5344-df6f-445e-9096-7ec23b0f031d.jpg",
        "https://files.skg.com/skg/2022-06-21/255d90e4-dbc5-4707-9707-d90f1073c354.jpg",
        "https://files.skg.com/skg/2022-06-21/5d03ba46-16de-493e-a457-483064b0faa0.jpg"
    ],
    eyes_4301: [
      'https://files.skg.com/skg/2023-03-24/62dd2f9b-eb46-4ca9-aade-df3ab8de1855.jpg',
      'https://files.skg.com/skg/2023-03-24/40ac9623-e316-4ebd-8c65-e5e386d5cc1e.jpg',
      'https://files.skg.com/skg/2023-03-24/30791ef3-33b9-4774-97b7-6b7e5bd7b5e4.jpg',
      'https://files.skg.com/skg/2023-03-24/832974e7-28c5-454d-910c-6abec9454843.jpg',
      'https://files.skg.com/skg/2023-03-24/a434eb6d-e908-416a-adac-5f873c609626.jpg',
      'https://files.skg.com/skg/2023-03-24/27b63e95-3ead-4d55-9662-0a57eb2efb5c.jpg',
      'https://files.skg.com/skg/2023-03-24/265d959b-15bd-4e6c-b032-879044d8b6f4.jpg',
      'https://files.skg.com/skg/2023-03-24/b5e09a72-7454-4c07-996e-8efdd04903e0.jpg',
      'https://files.skg.com/skg/2023-03-24/d5c5c9c8-4f24-4855-962a-9e9ea7dc76b2.jpg',
      'https://files.skg.com/skg/2023-03-24/34ecce7b-9a2a-48b3-bc8e-cf37b5c544be.jpg',
      'https://files.skg.com/skg/2023-03-24/253a1ded-1e66-4864-9a8b-72af0239a67f.jpg',
      'https://files.skg.com/skg/2023-03-24/fb958d91-13b9-406f-8741-19cd59a49209.jpg',
      'https://files.skg.com/skg/2023-03-24/173e0552-b8a8-4288-8d54-a6cd62e119da.jpg',
      'https://files.skg.com/skg/2023-03-24/0106affb-c67c-4b0e-bbde-348dd062ef30.jpg',
      'https://files.skg.com/skg/2023-03-24/e179d12c-b72c-47b3-8e40-837c9b4644b6.jpg',
      'https://files.skg.com/skg/2023-03-24/8c10d6b0-0d53-4073-84d4-0ced1b3977bc.jpg',
      'https://files.skg.com/skg/2023-03-24/4d3cd1bc-80b2-46e6-8794-34f4711b49a4.jpg',
      'https://files.skg.com/skg/2023-03-24/1ad3db33-9874-443b-87cc-24983b62db9c.jpg'
    ],
      E3:  [
        "https://files.skg.com/skg/2023-03-24/40026031-1842-40c1-9574-34d9b71d288f.jpg",
        "https://files.skg.com/skg/2023-03-24/c9e3382c-2b02-4b90-a7eb-656a201571aa.jpg",
        "https://files.skg.com/skg/2023-03-24/63136a55-f048-459d-ac73-b7de6b635f17.jpg",
        "https://files.skg.com/skg/2023-03-24/1a5305ce-b149-4b0f-bb3e-4f26dd476cb9.jpg",
        "https://files.skg.com/skg/2023-03-24/921d6f17-16e2-47f3-9d23-6c30c46729f6.jpg",
        "https://files.skg.com/skg/2023-03-24/e5b4b0b8-de08-4a03-82ee-76a71d4eea40.jpg",
        "https://files.skg.com/skg/2023-03-24/b2868c7c-a963-4d82-a382-83da922b381e.jpg",
        "https://files.skg.com/skg/2023-03-24/087cedc5-b60c-49b5-85c5-0a573b1b4d55.jpg",
        "https://files.skg.com/skg/2023-03-24/3881f452-471b-432a-9bb2-186c4fa9fc86.jpg",
        "https://files.skg.com/skg/2023-03-24/5f62d008-b767-48ca-843d-e19572b2f5b3.jpg",
        "https://files.skg.com/skg/2023-03-24/ff68dbd8-5387-4dc0-ae4e-74b834194cf5.jpg",
        "https://files.skg.com/skg/2023-03-24/c9891110-14ae-4f7a-9b66-4c7a7675fb1b.jpg",
        "https://files.skg.com/skg/2023-03-24/853a9911-f872-463b-8d2b-99cfbe2d4e94.jpg",
        "https://files.skg.com/skg/2023-03-24/e42e5e05-4a43-408a-aa3d-38f1b6941e8d.jpg",
        "https://files.skg.com/skg/2023-03-24/178f3bde-d11d-40a6-9ac2-9439a6c1ffa5.jpg",
        "https://files.skg.com/skg/2023-03-24/4cf05571-7c72-42ed-ad7d-79b426e51851.jpg",
        "https://files.skg.com/skg/2023-03-24/35173fb9-2de7-4cbb-84f7-f596f83e8f06.jpg",
        "https://files.skg.com/skg/2023-03-24/8898c1c2-b114-4ec4-89cb-2718300a2f22.jpg",
        "https://files.skg.com/skg/2023-03-24/8da29c4a-33d8-45be-8eb8-e54ed4c7139e.jpg",
        "https://files.skg.com/skg/2023-03-24/095d9b14-0fe6-4392-a88d-9b1a7154c0f9.jpg",
        "https://files.skg.com/skg/2023-03-24/ff599668-f460-43d5-bc0a-68a45e46f651.jpg",
        "https://files.skg.com/skg/2023-03-24/b01c5bce-6c33-4c9e-9e2c-b13046da59ab.jpg"
    ],
      newK4: [
        "https://files.skg.com/skg/2022-09-06/70ccf791-34c0-4cf2-9db7-839ef4b9d968.jpg",
        "https://files.skg.com/skg/2022-09-06/f6c9205d-c606-4dc5-8646-bff08428f1ca.jpg",
        "https://files.skg.com/skg/2022-09-06/f4537fd8-9fe6-45b6-aa6e-b283250479e8.jpg",
        "https://files.skg.com/skg/2022-09-06/d184eefd-24cc-4bd2-a1d3-8af20d04e6e5.jpg",
        "https://files.skg.com/skg/2022-09-06/65e6833f-e7da-4529-acd8-15b4648ac025.jpg",
        "https://files.skg.com/skg/2022-09-06/f120ac5e-1b54-4c42-bb9b-473d51a5233e.jpg",
        "https://files.skg.com/skg/2022-09-06/cf4cc3c2-cb07-444d-96ab-37d5321441ca.jpg",
        "https://files.skg.com/skg/2022-09-06/93a4f652-09e6-47ca-abdd-e8053ced60ae.jpg",
        "https://files.skg.com/skg/2022-09-06/39761ed2-c4af-4e08-9997-c88e326b3030.jpg",
        "https://files.skg.com/skg/2022-09-06/146d7261-05cc-4004-932c-956c0224d5a5.jpg",
        "https://files.skg.com/skg/2022-09-06/58dd655f-35c2-434c-92e3-7a352baac778.jpg",
        "https://files.skg.com/skg/2022-09-06/4b8b1814-ac0c-4789-b9b4-87f34eaf9242.jpg",
        "https://files.skg.com/skg/2022-09-06/c66c1fe6-5f28-4135-9642-35843f62a563.jpg",
        "https://files.skg.com/skg/2022-09-06/355fcca7-c031-449b-a876-5b5debc224f9.jpg",
        "https://files.skg.com/skg/2022-09-06/1c1db801-8814-4c5b-af43-d00ed86a964e.jpg",
        "https://files.skg.com/skg/2022-09-06/1ec38ae4-0fa0-4eab-a9c9-909416a82f47.jpg",
        "https://files.skg.com/skg/2022-09-06/e0da194e-f86d-4faf-aaa9-40cfb5c1cda8.jpg",
        "https://files.skg.com/skg/2022-09-06/642b25ae-c91b-41a8-92bb-33bfcf3a9010.jpg"
    ],
      F3:  [
        "https://files.skg.com/skg/2023-03-24/f4466f9c-de58-4595-a68f-17efd1cafc26.jpg",
        "https://files.skg.com/skg/2023-03-24/65fb342b-28ef-469f-a147-6fcd8cdb804d.jpg",
        "https://files.skg.com/skg/2023-03-24/6cd380fe-f206-4cd9-8ada-de238a4a00c3.jpg",
        "https://files.skg.com/skg/2023-03-24/4f720041-c72a-47b2-939b-583aa8ee32a3.jpg",
        "https://files.skg.com/skg/2023-03-24/5c80ed35-1112-4c0d-b0cc-f131f740a97a.jpg",
        "https://files.skg.com/skg/2023-03-24/7e491dc0-f632-46de-8fb3-606f2370bf0c.jpg",
        "https://files.skg.com/skg/2023-03-24/96a1d879-fd76-4562-87a8-afd9ab63265f.jpg",
        "https://files.skg.com/skg/2023-03-24/d956bac5-1b0c-4393-9015-e8c1580cf499.jpg",
        "https://files.skg.com/skg/2023-03-24/46a5a8a1-edd3-478a-9d9f-0c2435491b95.jpg",
        "https://files.skg.com/skg/2023-03-24/2cbd71c2-9474-429d-baa6-57231e314ff5.jpg",
        "https://files.skg.com/skg/2023-03-24/e19b5598-479a-4cdc-943b-973777db5bcd.jpg",
        "https://files.skg.com/skg/2023-03-24/d8c6f521-01de-4ff6-85bf-d25908ab7a0a.jpg",
        "https://files.skg.com/skg/2023-03-24/d3955295-65aa-4a70-9fb2-8e89f79dc092.jpg"
    ],
      G7: [
        "https://files.skg.com/skg/2023-03-24/1b73380b-d675-44ce-bbee-3020e7a6b82d.jpg",
        "https://files.skg.com/skg/2023-03-24/37c9bd23-1377-4bc5-bd95-32ce03cc67e1.jpg",
        "https://files.skg.com/skg/2023-03-24/40c023cf-7d82-409b-85bb-f7a407b0ba2a.jpg",
        "https://files.skg.com/skg/2023-03-24/56fe25a8-1443-40d0-814e-56aae8a9ea38.gif",
        "https://files.skg.com/skg/2023-03-24/b569ae31-9c12-4b5e-8b3c-93d97b78de22.jpg",
        "https://files.skg.com/skg/2023-03-24/8ab1621f-2f54-4bca-9a40-5132c5853899.jpg",
        "https://files.skg.com/skg/2023-03-24/161b04bf-d282-4c9f-badf-972e03a3b14e.gif",
        "https://files.skg.com/skg/2023-03-24/f307b356-efb8-420d-821a-30fa70ff5b78.jpg",
        "https://files.skg.com/skg/2023-03-24/53f949c2-8a2e-4c6a-b3be-95e6470be4a6.gif",
        "https://files.skg.com/skg/2023-03-24/e6139651-6b21-482c-92cc-891b9f2d35e6.jpg",
        "https://files.skg.com/skg/2023-03-24/30758400-f175-4e3a-b373-c8ca54c2aeb0.jpg",
        "https://files.skg.com/skg/2023-03-24/789d0de7-5e89-406b-bd6d-4cb5ce1182d2.gif",
        "https://files.skg.com/skg/2023-03-24/8c141117-4f5a-42ad-bb6d-16b52fe39c20.jpg",
        "https://files.skg.com/skg/2023-03-24/64e73d30-40a6-4428-9d67-86ca9f275586.jpg",
        "https://files.skg.com/skg/2023-03-24/d9843d2f-3d58-4471-85ba-ae270b0f6aec.jpg",
        "https://files.skg.com/skg/2023-03-24/ad47df5a-0353-4746-9b84-87270b2af972.jpg",
        "https://files.skg.com/skg/2023-03-24/7b0742bd-edd8-42ee-a413-497438b068e0.jpg",
        "https://files.skg.com/skg/2023-03-24/0a6c8040-fb8f-4818-8e87-0b5c8bf775ed.jpg",
        "https://files.skg.com/skg/2023-03-24/fe55982f-cd83-4e7f-87d2-a7ef00b2406e.jpg",
        "https://files.skg.com/skg/2023-03-24/86b83c8f-60d4-4c8f-aa75-8bdc8efc544d.jpg",
        "https://files.skg.com/skg/2023-03-24/9d8b8517-f60c-4887-80df-fc0908ea6690.jpg",
        "https://files.skg.com/skg/2023-03-24/cd0fd96c-b104-4eb2-8564-9d9352553b2c.jpg",
        "https://files.skg.com/skg/2023-03-24/9d332433-7473-4d09-ae91-55afb970d3e0.jpg"
    ],
      F5: [
        "https://files.skg.com/skg/2023-03-24/1e6d591f-4ba1-488d-9360-2bd92b5f495f.jpg",
        "https://files.skg.com/skg/2023-03-24/56e70017-0fb6-4f68-b5e3-4bd83f136fd1.jpg",
        "https://files.skg.com/skg/2023-03-24/e217b1f4-6df6-4465-befe-6caa33a4beb3.jpg",
        "https://files.skg.com/skg/2023-03-24/0e829e47-0dcd-475e-a75e-784d2b9ef8a9.jpg",
        "https://files.skg.com/skg/2023-03-24/7bbb997c-a02f-414f-9f1d-d3bf70b29a28.jpg",
        "https://files.skg.com/skg/2023-03-24/fc7d9327-70d3-4869-8584-4586358b503c.jpg",
        "https://files.skg.com/skg/2023-03-24/84ad9789-fb62-4c5e-8735-77f7465fdf91.jpg",
        "https://files.skg.com/skg/2023-03-24/3c47081a-8a1c-46b7-9951-b1473a6bb171.jpg",
        "https://files.skg.com/skg/2023-03-24/aca28823-0d30-4094-b8e1-02f54bad6398.jpg",
        "https://files.skg.com/skg/2023-03-24/2db946a0-200d-4bfa-8373-0edf87fdf826.jpg",
        "https://files.skg.com/skg/2023-03-24/d4fbb1e4-097d-43d8-96d3-cb58e136eac1.jpg",
        "https://files.skg.com/skg/2023-03-24/faff2505-efd7-4d43-b966-c915e25cdafe.jpg",
        "https://files.skg.com/skg/2023-03-24/2ce5a2f4-9565-460b-8dcc-26156f5bab68.jpg",
        "https://files.skg.com/skg/2023-03-24/9dea6aa3-8bb1-42dc-952d-26c6abcd7d79.jpg",
        "https://files.skg.com/skg/2023-03-24/a37b2c1e-756f-4073-9899-a9b4e07fa3b5.jpg",
        "https://files.skg.com/skg/2023-03-24/02154143-9069-4007-bb51-843017cbfe98.jpg",
        "https://files.skg.com/skg/2023-03-24/ab90ab1e-3e78-47dc-9554-b43f912455d6.jpg"
    ],
      E4: [
        "https://files.skg.com/skg/2023-03-24/ba74047f-5c95-4e52-8965-6b51b79cdc5c.jpg",
        "https://files.skg.com/skg/2023-03-24/30a18e18-b6e0-4a0e-bad2-d51d8cb694db.jpg",
        "https://files.skg.com/skg/2023-03-24/eb3e3c08-bce1-42f5-b993-13a01210529a.jpg",
        "https://files.skg.com/skg/2023-03-24/f7d29ff4-3235-43a5-a28d-2eb1bd383b1c.jpg",
        "https://files.skg.com/skg/2023-03-24/e49a787d-0960-4314-a4b1-99d07694fe1d.jpg",
        "https://files.skg.com/skg/2023-03-24/9bdedf2b-3d70-4fce-839d-822a05a1eff9.jpg",
        "https://files.skg.com/skg/2023-03-24/0c18c36e-2fdb-437f-9ff6-3a6d2a8a6243.jpg",
        "https://files.skg.com/skg/2023-03-24/901219c6-1d20-475a-9ae5-c9e68910cd35.jpg",
        "https://files.skg.com/skg/2023-03-24/12d63c7f-b3b2-4e8a-8f67-ba37153d367a.jpg",
        "https://files.skg.com/skg/2023-03-24/1b1ea539-d9ef-4a92-bd13-0cea20e1c6ee.jpg",
        "https://files.skg.com/skg/2023-03-24/20323c1f-1d42-410e-9f4b-33c312b13a16.jpg",
        "https://files.skg.com/skg/2023-03-24/05bc025e-3902-48d6-850a-62427448123a.jpg",
        "https://files.skg.com/skg/2023-03-24/c56f16db-7a5e-4946-bc43-8c0ac1155d5b.jpg",
        "https://files.skg.com/skg/2023-03-24/f9929d37-8cfb-4a23-934a-fa7999bee78b.jpg",
        "https://files.skg.com/skg/2023-03-24/c4cc44cf-0eec-47c7-b1e9-9a61b4648078.jpg",
        "https://files.skg.com/skg/2023-03-24/10cca280-025a-4091-9341-d5be425dda7d.jpg",
        "https://files.skg.com/skg/2023-03-24/ac869870-22e6-42aa-ab46-9d3e0fa09eb6.jpg",
        "https://files.skg.com/skg/2023-03-24/1b9d1136-0fc2-4bf8-9c30-6e52836a0c8e.jpg",
        "https://files.skg.com/skg/2023-03-24/f86ed652-3b05-4702-bb32-d47d878dadd7.jpg",
        "https://files.skg.com/skg/2023-03-24/f867a640-0ef5-417d-9d70-a39b30f5bfd4.jpg",
        "https://files.skg.com/skg/2023-03-24/ea39e4a1-c9b0-47da-b558-2f72a4152134.jpg",
        "https://files.skg.com/skg/2023-03-24/c5531731-3614-4474-b169-a24c1a1613b4.jpg"
    ],
      W5: [
        "https://files.skg.com/skg/2022-09-06/f355beb9-a3a4-44a9-80bf-a68cca16fadf.jpg",
        "https://files.skg.com/skg/2022-09-06/9d7b47e1-40e5-4612-9d92-ecb7acbc3cb5.jpg",
        "https://files.skg.com/skg/2022-09-06/80275b9b-d82e-4e2b-9cd4-d47aa1bc382e.jpg",
        "https://files.skg.com/skg/2022-09-06/20d33b18-2a97-4091-a384-a9a35c66f6cf.jpg",
        "https://files.skg.com/skg/2022-09-06/d7510d34-aa6b-4c84-bc44-cbde6e82e7c6.jpg",
        "https://files.skg.com/skg/2022-09-06/7a40f480-e5b1-48ea-a7e6-f74f756a4195.jpg",
        "https://files.skg.com/skg/2022-09-06/6ed8f2ad-9e70-465a-9d7b-5ac319a39cc5.jpg",
        "https://files.skg.com/skg/2022-09-06/38aa9d4c-779d-4dfe-acca-0354ad0d9896.jpg",
        "https://files.skg.com/skg/2022-09-06/a9caff03-dd17-402f-9d4b-dc91c1ab32e1.jpg",
        "https://files.skg.com/skg/2022-09-06/642aab3d-161c-4414-b067-5378072793fc.jpg",
        "https://files.skg.com/skg/2022-09-06/41ecc36d-363b-4d0b-90ac-e9eb65a03835.jpg",
        "https://files.skg.com/skg/2022-09-06/b8b73af0-d752-4fb5-87d0-5ac34629a2f1.jpg",
        "https://files.skg.com/skg/2022-09-06/05b549f4-181e-4dc8-9a60-84c177373796.jpg",
        "https://files.skg.com/skg/2022-09-06/d3821aa5-d57e-42d6-8fba-51d662f1d9c6.jpg",
        "https://files.skg.com/skg/2022-09-06/897de52b-4ce5-4441-a12e-a2049a1ffeec.jpg",
        "https://files.skg.com/skg/2022-09-06/e964f0f3-d464-455b-a278-799226dd1157.jpg",
        "https://files.skg.com/skg/2022-09-06/048fd6cb-444a-49a6-a87a-3b99943142ff.jpg",
        "https://files.skg.com/skg/2022-09-06/208a388e-18f6-44bb-9e67-caa99bf6fbf5.jpg",
        "https://files.skg.com/skg/2022-09-06/aee814d7-d5f7-4989-90c3-f2f632be020c.jpg",
        "https://files.skg.com/skg/2022-09-06/1ee9eafc-fc84-4515-8eda-56bcf9ce533a.jpg",
        "https://files.skg.com/skg/2022-09-06/2bb2d57f-c8f6-4a2f-bab2-87effb8757f8.jpg",
        "https://files.skg.com/skg/2022-09-06/c2dd7005-a24f-4f1a-9736-8aa21d899275.jpg"
    ],
    BP3:  [
        "https://files.skg.com/skg/2024-04-16/e5200a3e-9feb-4c0b-979f-cf42a41f5980.jpg",
        "https://files.skg.com/skg/2024-04-16/36c933a6-6644-4feb-a93d-e6cd66279ec9.jpg",
        "https://files.skg.com/skg/2024-04-16/7e33f780-34c5-4a3a-a720-865239926739.jpg",
        "https://files.skg.com/skg/2024-04-16/91c5bb29-9461-4ad6-ae63-206b86700406.jpg",
        "https://files.skg.com/skg/2024-04-16/d5ec4d3e-4887-40a7-86cb-405fbc3e28f5.jpg",
        "https://files.skg.com/skg/2024-04-16/540eb7cd-bae2-4f0c-af72-b916f9730820.jpg",
        "https://files.skg.com/skg/2024-04-16/ba76202b-4e30-439d-89db-6dbffd791de0.jpg",
        "https://files.skg.com/skg/2024-04-16/6be74373-07df-42e1-8f6e-0d4e098f72fa.jpg",
        "https://files.skg.com/skg/2024-04-16/e2c3ef78-b642-48d4-bff9-0b592ab71610.jpg",
        "https://files.skg.com/skg/2024-04-16/d6450521-1712-4013-8d28-7f511dfcba6b.jpg",
        "https://files.skg.com/skg/2024-04-16/e30c62d1-f304-4779-9dd7-d6a432969789.jpg",
        "https://files.skg.com/skg/2024-04-16/588d568f-b803-4e1e-8c27-973cdd4e47b4.jpg"
    ],

      K5mini: [
        "https://files.skg.com/skg/2023-03-24/2d9df41f-dc8e-47a5-b7dc-21b80a9b4b0f.jpg",
        "https://files.skg.com/skg/2023-03-24/9a198f66-ddc0-426f-9a27-5faa871b6f7b.jpg",
        "https://files.skg.com/skg/2023-03-24/8841b0e1-3679-4c5f-9f07-001e62bd672c.jpg",
        "https://files.skg.com/skg/2023-03-24/fbbdb9ac-a5a6-4e83-9686-d1212c76f04c.jpg",
        "https://files.skg.com/skg/2023-03-24/244e7b52-5405-429f-b099-fbc738a34825.jpg",
        "https://files.skg.com/skg/2023-03-24/3bc5c0c3-9358-4696-bab1-358e23d4da54.jpg",
        "https://files.skg.com/skg/2023-03-24/b9a60f17-71f0-423d-88d5-1c9bdab27f22.jpg",
        "https://files.skg.com/skg/2023-03-24/f0066207-514c-4463-afe5-12da61b46f86.jpg",
        "https://files.skg.com/skg/2023-03-24/608043af-ba7c-4fc2-b8d0-0a3edd837841.jpg",
        "https://files.skg.com/skg/2023-03-24/52be5a02-aadf-4d9e-bee1-7fb66f585c57.jpg",
        "https://files.skg.com/skg/2023-03-24/3598d1cd-b8ed-4117-b8e3-70b759333360.jpg",
        "https://files.skg.com/skg/2023-03-24/9c558d9e-53bc-45e5-8353-ad0c9825ddc6.jpg",
        "https://files.skg.com/skg/2023-03-24/746301bc-d781-4dfc-af07-19971639ef69.jpg",
        "https://files.skg.com/skg/2023-03-24/41152cf2-7f58-4593-ad86-c0d90752b58a.jpg",
        "https://files.skg.com/skg/2023-03-24/36f4b5c9-89f2-4976-904e-8fd3873663c9.jpg",
        "https://files.skg.com/skg/2023-03-24/393ce54e-4d7d-42d8-bfed-8e9329565e74.jpg",
        "https://files.skg.com/skg/2023-03-24/0b1db1ba-f949-40b3-b98a-3c4f83b53b00.jpg",
        "https://files.skg.com/skg/2023-03-24/46d552db-cfcc-4455-82b2-c94c9b51dd71.jpg",
        "https://files.skg.com/skg/2023-03-24/e42ce2d9-f7fd-47ad-9026-e243307eacfe.jpg"
    ],
      T5: [
        "https://files.skg.com/skg/2023-03-24/e848638c-1609-490a-8d64-9c607895b257.jpg",
        "https://files.skg.com/skg/2023-03-24/93f50386-ce74-4935-8663-4b474f505651.jpg",
        "https://files.skg.com/skg/2023-03-24/338098ff-9545-4996-8d86-beed2ef67af0.jpg",
        "https://files.skg.com/skg/2023-03-24/da233df4-52db-470d-9723-066fbcb4943f.jpg",
        "https://files.skg.com/skg/2023-03-24/6299082c-9cdb-4b28-a585-ba9e381f84ac.jpg",
        "https://files.skg.com/skg/2023-03-24/9b9a302a-1f82-4ab0-8978-e0c6bbbefdc2.jpg",
        "https://files.skg.com/skg/2023-03-24/5a59a584-482a-4688-8f3d-b02a9c727a17.jpg",
        "https://files.skg.com/skg/2023-03-24/5175f7bf-d695-4513-9e16-4cec1d0d0290.jpg",
        "https://files.skg.com/skg/2023-03-24/b59af577-21e5-4e4f-9422-16f1291e4b4d.jpg",
        "https://files.skg.com/skg/2023-03-24/38ca66b6-8213-45c7-8ce4-6a7fcab44459.jpg",
        "https://files.skg.com/skg/2023-03-24/2e49f51b-5436-44dd-96b7-ed72ae575798.jpg",
        "https://files.skg.com/skg/2023-03-24/76e66123-0382-4886-8a89-6de1d5e66ccb.jpg",
        "https://files.skg.com/skg/2023-03-24/287b7fb5-ca39-4d3e-8018-0b2d253eee25.jpg",
        "https://files.skg.com/skg/2023-03-24/3af013c5-14f4-4ab1-a3aa-687607f7196e.jpg",
        "https://files.skg.com/skg/2023-03-24/6bad35bf-34ca-4259-b164-a0909a3a7229.jpg",
        "https://files.skg.com/skg/2023-03-24/842f8091-02c9-4176-97e4-486d9be5c70f.jpg",
        "https://files.skg.com/skg/2023-03-24/7ccef9a3-fc89-43c9-a028-51b3362802d9.jpg",
        "https://files.skg.com/skg/2023-03-24/c3d74eb8-0ea5-4f6b-9703-65479c8d9fda.jpg"
    ],
      W5ST: [
        "https://files.skg.com/skg/2023-03-24/759a4db2-32b2-4600-8954-854ffc1a52d9.jpg",
        "https://files.skg.com/skg/2023-03-24/665cdc19-d7cf-4558-b0c0-e23254744eed.jpg",
        "https://files.skg.com/skg/2023-03-24/0ca5b31a-b114-48b1-b127-cf85b1035895.jpg",
        "https://files.skg.com/skg/2023-03-24/b13ef3f3-25f5-4b45-978b-05b2cd60b4f8.jpg",
        "https://files.skg.com/skg/2023-03-24/d667506e-b13b-4ec8-b829-1e7b9527585d.jpg",
        "https://files.skg.com/skg/2023-03-24/763e7958-25ef-4da7-95f4-797ec6f15e96.jpg",
        "https://files.skg.com/skg/2023-03-24/aab75d6a-315c-44fd-9f6d-42532e603c50.jpg",
        "https://files.skg.com/skg/2023-03-24/17d8b9c7-04ae-4fc6-aaf8-9103693c8851.jpg",
        "https://files.skg.com/skg/2023-03-24/fed3532f-d7c3-4f06-80f5-740180062d1a.jpg",
        "https://files.skg.com/skg/2023-03-24/40189e8d-f68c-4351-bc6c-3babb2b92d83.jpg",
        "https://files.skg.com/skg/2023-03-24/f14b668d-bad6-42ae-828b-a8482c8c94d2.jpg",
        "https://files.skg.com/skg/2023-03-24/bd3205ad-5415-4763-89e4-5a84025d2a08.jpg",
        "https://files.skg.com/skg/2023-03-24/b725b45c-2f1f-43c1-94bc-e5dc6f10433c.jpg",
        "https://files.skg.com/skg/2023-03-24/bb6c0075-8c50-4190-8fad-8da2ab2f41c6.jpg",
        "https://files.skg.com/skg/2023-03-24/c2c32c69-eb2f-4a54-bd6d-85c06c3647a3.jpg",
        "https://files.skg.com/skg/2023-03-24/b280898e-e067-41e1-a811-36d24adcbe07.jpg",
        "https://files.skg.com/skg/2023-03-24/afb9bb7f-b84d-4ceb-86b1-4396ce6fc029.jpg",
        "https://files.skg.com/skg/2023-03-24/ceb47470-ef17-447c-aa97-65ba3283e147.jpg",
        "https://files.skg.com/skg/2023-03-24/d7a1b2eb-9aec-4abb-836b-7ec9e5f88916.jpg",
        "https://files.skg.com/skg/2023-03-24/9fa316ad-8473-4fa0-b98d-0dbc76204e47.jpg",
        "https://files.skg.com/skg/2023-03-24/8a8c4fd4-9700-4c79-ae30-8299da37965a.jpg",
        "https://files.skg.com/skg/2023-03-24/e43ba0b1-29d0-4334-bff0-cb3b17152cde.jpg",
        "https://files.skg.com/skg/2023-03-24/5d52a1df-5143-461b-9f43-5ffe79327f6f.jpg"
    ],
      K5:[
        "https://files.skg.com/skg/2024-04-15/22fbbb3f-36b7-4474-8e36-c8d61ac572aa.jpg",
        "https://files.skg.com/skg/2024-04-15/8fe8d0b1-225d-462b-8e26-716f711d99e5.jpg",
        "https://files.skg.com/skg/2024-04-15/24486756-518d-4180-8213-465fd406d6d4.gif",
        "https://files.skg.com/skg/2024-04-15/1933b3e2-1f3e-48d8-b67f-5fd1945ec5d1.gif",
        "https://files.skg.com/skg/2024-04-15/eed85888-1d8e-443f-bf1a-67ef24b92605.jpg",
        "https://files.skg.com/skg/2024-04-15/b6acba28-9f03-4e6e-bf94-c12829e274ce.jpg",
        "https://files.skg.com/skg/2024-04-15/2fd274ca-dfd2-4e1d-8d99-a9d0bc1b3568.gif",
        "https://files.skg.com/skg/2024-04-15/70f8916d-dfa7-4dc9-8f8e-1c93bf82a62d.jpg",
        "https://files.skg.com/skg/2024-04-15/03faef90-d4d2-49ee-b50b-dd802c266df4.gif",
        "https://files.skg.com/skg/2024-04-15/575f971c-683d-4492-95e9-88139b1818cd.jpg",
        "https://files.skg.com/skg/2024-04-15/a05a4a38-a4ac-4641-8028-cac278a44f2e.gif",
        "https://files.skg.com/skg/2024-04-15/1bb1bc92-535f-4c69-9976-b5e528aeac96.gif",
        "https://files.skg.com/skg/2024-04-15/7c548388-2b25-4261-928b-7a6f3761d13c.jpg",
        "https://files.skg.com/skg/2024-04-15/9e9745e3-6f2f-4791-9c45-06ff2f138e8d.jpg",
        "https://files.skg.com/skg/2024-04-15/35b22fe9-37ab-48e1-8f0a-cc6a44d95775.gif",
        "https://files.skg.com/skg/2024-04-15/f8fbd859-1f21-483e-b73b-948141f096ad.gif",
        "https://files.skg.com/skg/2024-04-15/2d078c99-4a22-42b7-bede-5a9eee1ec587.jpg",
        "https://files.skg.com/skg/2024-04-15/40b60717-534b-4a75-b0ca-e4467c8b1664.jpg",
        "https://files.skg.com/skg/2024-04-15/18a5dacd-dd41-4f97-a088-7c4199aaa330.gif",
        "https://files.skg.com/skg/2024-04-15/b7acce25-00f0-44c3-b1e1-a9cf93bc9aec.jpg",
        "https://files.skg.com/skg/2024-04-15/993eacef-4444-4901-953d-632b8fe11835.gif",
        "https://files.skg.com/skg/2024-04-15/3b41d226-dc3e-4c41-b557-e62deb701b7a.jpg",
        "https://files.skg.com/skg/2024-04-15/6f9db6ae-2170-4156-8b1f-4443f0f9f6e2.jpg",
        "https://files.skg.com/skg/2024-04-15/b2f3797f-85e0-4ca6-a61e-dd42308b263a.jpg",
        "https://files.skg.com/skg/2024-04-15/abbc2dcd-80f4-4dcc-8cae-614f7e07cb40.jpg",
        "https://files.skg.com/skg/2024-04-15/86b6d4f3-db59-4a5d-9cd8-0487e7e023a7.jpg",
        "https://files.skg.com/skg/2024-04-15/9b6b8862-d80c-4be0-b914-a49a88f0d297.jpg",
        "https://files.skg.com/skg/2024-04-15/c23beb9e-de3b-434c-a28d-f0e071320066.jpg",
        "https://files.skg.com/skg/2024-04-15/4f81ea05-bf68-4f0d-8c09-a595c1f0df8b.jpg"
    ],
      F7: [
        "https://files.skg.com/skg/2023-03-24/644275ee-05f8-4404-b8ac-055e8286b630.jpg",
        "https://files.skg.com/skg/2023-03-24/bc0fc150-37a9-4e0c-a50b-5ef4cdb97f02.jpg",
        "https://files.skg.com/skg/2023-03-24/8c28a198-e03e-4100-9ccd-07a7fb91411d.jpg",
        "https://files.skg.com/skg/2023-03-24/d6ab70b7-9088-4019-a65b-e2959063f3e3.jpg",
        "https://files.skg.com/skg/2023-03-24/9882bc4e-8892-45d1-9a45-0e966de427f6.jpg",
        "https://files.skg.com/skg/2023-03-24/249bfdea-ca47-458c-8383-e1114ac29559.gif",
        "https://files.skg.com/skg/2023-03-24/f50e2044-b98e-44c0-96a4-9a01ab702f78.jpg",
        "https://files.skg.com/skg/2023-03-24/32b3fac5-f3c1-401e-a0ca-c3d75bbfe0c3.gif",
        "https://files.skg.com/skg/2023-03-24/56d5530e-956f-44c3-ba0c-cbaae331b269.jpg",
        "https://files.skg.com/skg/2023-03-24/0f238d2c-8aa2-4a4f-8eca-a9ff479fbe05.gif",
        "https://files.skg.com/skg/2023-03-24/614c35c2-6557-4afd-a984-60fd8abff39f.jpg",
        "https://files.skg.com/skg/2023-03-24/9b92d51a-41ca-49bd-bd46-b07864048de5.jpg",
        "https://files.skg.com/skg/2023-03-24/d5a2d752-180c-4876-b00a-ce26774ccb04.jpg",
        "https://files.skg.com/skg/2023-03-24/418d7560-634b-491f-848d-b6a6651fcbe9.jpg",
        "https://files.skg.com/skg/2023-03-24/63c4f1a3-7726-491b-ac8e-09ece2059e54.jpg",
        "https://files.skg.com/skg/2023-03-24/e4de8170-57e7-455e-8af2-cb27cf102bab.gif",
        "https://files.skg.com/skg/2023-03-24/4ebdf750-1182-4f75-811a-d43419a93fb0.jpg",
        "https://files.skg.com/skg/2023-03-24/056d09ec-f6ab-49aa-9f55-4f44cb85026f.jpg",
        "https://files.skg.com/skg/2023-03-24/262c7f8d-ce03-4d1b-8ce8-82318ea12e85.jpg",
        "https://files.skg.com/skg/2023-03-24/279579db-4d1e-412d-a2f5-21ed54cb3cb5.jpg",
        "https://files.skg.com/skg/2023-03-24/c51182f0-53cf-4452-97a4-28d02abe1acf.jpg",
        "https://files.skg.com/skg/2023-03-24/6e661b56-4915-4ccc-8058-187b9739c17e.jpg",
        "https://files.skg.com/skg/2023-03-24/0ec9c7a8-8dd7-4a5c-82cb-579448c5d394.jpg",
        "https://files.skg.com/skg/2023-03-24/d3439f96-01b1-4850-b9e4-3e4d8266711b.jpg"
    ],
      G7PRO: [
        "https://files.skg.com/skg/2023-03-24/9f444a3c-3a8f-46f9-ad1b-3cc3287a693e.jpg",
        "https://files.skg.com/skg/2023-03-24/2cc830dd-3924-4169-a86e-e4b7463514b4.jpg",
        "https://files.skg.com/skg/2023-03-24/1d0954b0-2c89-4a3c-b1b0-5dc0a3a0f688.jpg",
        "https://files.skg.com/skg/2023-03-24/f9b1c382-c867-4573-84aa-7a08ae286e1d.gif",
        "https://files.skg.com/skg/2023-03-24/a2382ae1-6f91-4489-a684-c1832efd3d45.gif",
        "https://files.skg.com/skg/2023-03-24/c61a8061-8ab1-412b-b89d-099a94141508.jpg",
        "https://files.skg.com/skg/2023-03-24/28f84093-4d86-4e0e-aeb2-0e7f9082e2c5.gif",
        "https://files.skg.com/skg/2023-03-24/f5666c21-fee3-4a9d-a786-3f7b87d96d05.jpg",
        "https://files.skg.com/skg/2023-03-24/313aec5a-b021-4944-8d58-e67bae073ddd.gif",
        "https://files.skg.com/skg/2023-03-24/699350b6-be85-4dfe-97e9-3f6e9f1e5c42.jpg",
        "https://files.skg.com/skg/2023-03-24/17a8953a-d878-4c33-be76-eb737171534f.gif",
        "https://files.skg.com/skg/2023-03-24/42a3b356-32c7-4cf8-af83-0e12541709eb.gif",
        "https://files.skg.com/skg/2023-03-24/92c59983-e954-4b70-a9d9-708d40b5e33e.gif",
        "https://files.skg.com/skg/2023-03-24/6c07c7ce-fc91-4bf4-bc1c-1d5e33f55eea.gif",
        "https://files.skg.com/skg/2023-03-24/898c1fdb-9a85-4be4-ab4b-5afc9191b9a9.jpg",
        "https://files.skg.com/skg/2023-03-24/f8b1c165-a709-459d-8ae3-660f1422558f.jpg",
        "https://files.skg.com/skg/2023-03-24/64a9f610-d1ef-4f0a-aaeb-21604e262321.gif",
        "https://files.skg.com/skg/2023-03-24/78c55a74-892e-4f8d-9e66-18b9af3ee7aa.jpg",
        "https://files.skg.com/skg/2023-03-24/64073508-eb68-48b9-a7cf-cc5c2734e834.gif",
        "https://files.skg.com/skg/2023-03-24/ff24a4df-5b74-431e-b621-7f8bfcd3c345.gif",
        "https://files.skg.com/skg/2023-03-24/8a3cfc1c-14b7-41bf-b563-ad43471ab614.jpg",
        "https://files.skg.com/skg/2023-03-24/51365b0a-a0eb-42f5-ac4f-4448fb3c4b0e.jpg",
        "https://files.skg.com/skg/2023-03-24/1fae076d-5967-4f41-9e73-69715406c7f5.jpg",
        "https://files.skg.com/skg/2023-03-24/b4c11635-2035-4df5-b5ca-2cc82efd7071.jpg",
        "https://files.skg.com/skg/2023-03-24/7c440ba8-db90-4285-b2a1-1acbfb49ac30.jpg"
    ],
      G72: [
        "https://files.skg.com/skg/2024-04-15/81428c7c-adbf-497e-a125-85834ad0059d.jpg",
        "https://files.skg.com/skg/2024-04-15/8be17c78-1cf2-4009-98f9-80c4e1b7867d.png",
        "https://files.skg.com/skg/2024-04-15/2e9952e8-1d20-422e-bb66-4a73bd5566c3.jpg",
        "https://files.skg.com/skg/2024-04-15/7c40b5d2-90dc-40fb-800b-9d4ed9af695d.jpg",
        "https://files.skg.com/skg/2024-04-15/7dca6329-3180-4b37-94bf-1533b368cfb2.jpg",
        "https://files.skg.com/skg/2024-04-15/18ddf6ba-2045-4067-a124-24e478a4439d.gif",
        "https://files.skg.com/skg/2024-04-15/c59f970e-7791-4355-a728-8850b7b430ea.gif",
        "https://files.skg.com/skg/2024-04-15/824e4e2d-7a7f-4d3d-bf50-1367560e7bf7.gif",
        "https://files.skg.com/skg/2024-04-15/e5b2b518-7e92-41ca-8e6f-6a5da682193e.gif",
        "https://files.skg.com/skg/2024-04-15/3af2f42f-081a-4a43-b9c8-7b2e13204a75.gif",
        "https://files.skg.com/skg/2024-04-15/a5d3cea6-410a-4296-a34b-e962a3ab1f86.gif",
        "https://files.skg.com/skg/2024-04-15/4711edcb-578e-4d68-97df-c9cbbada0510.jpg",
        "https://files.skg.com/skg/2024-04-15/79a6f750-6fea-4c31-80f5-b5c2b6c9ea5a.gif",
        "https://files.skg.com/skg/2024-04-15/99120ad6-9819-434d-96fa-620f9f88401e.jpg",
        "https://files.skg.com/skg/2024-04-15/3ae88960-3de5-42c2-bccf-bbad71a3e1b7.gif",
        "https://files.skg.com/skg/2024-04-15/e3ef2ead-f22c-4047-969e-45e42aa400c6.jpg",
        "https://files.skg.com/skg/2024-04-15/d4edbfe7-bdde-430c-b1d7-eee5372b71a0.jpg",
        "https://files.skg.com/skg/2024-04-15/d8b64eda-5531-476c-8e1c-51455a6091b2.jpg",
        "https://files.skg.com/skg/2024-04-15/9ab7b9b5-0ebc-42dd-9032-7afe67f05c10.jpg",
        "https://files.skg.com/skg/2024-04-15/7449a1f3-d55e-41fe-b1f5-c5f6d5807791.jpg",
        "https://files.skg.com/skg/2024-04-15/3fb0bd26-8564-4415-87e8-86f4636c47a7.jpg"
    ],
      W5TE:[
        "https://files.skg.com/skg/2023-03-24/53f54a1b-77a9-47f2-ba19-a5a52c742799.jpg",
        "https://files.skg.com/skg/2023-03-24/f4728442-85f3-45e8-b197-e19aa145fa52.jpg",
        "https://files.skg.com/skg/2023-03-24/57b35f77-5113-4c60-943b-474832e2eacc.jpg",
        "https://files.skg.com/skg/2023-03-24/8480b7c4-985d-4f56-be3e-8766be84a878.jpg",
        "https://files.skg.com/skg/2023-03-24/39bd1858-cf49-4887-83cf-8dfedbeb43e8.jpg",
        "https://files.skg.com/skg/2023-03-24/0a565b9a-f015-43c1-b7da-8b2864c97649.jpg",
        "https://files.skg.com/skg/2023-03-24/37d0eea5-6f3d-4f93-8ab1-5ec6a30656ea.jpg",
        "https://files.skg.com/skg/2023-03-24/8f922b38-dfef-4452-8785-0eeefe1bd81b.jpg",
        "https://files.skg.com/skg/2023-03-24/81b87a84-2958-4cdc-8b0a-1c7e007ca78f.jpg",
        "https://files.skg.com/skg/2023-03-24/9501b1a7-909a-406c-a0c6-0fa56518a49c.jpg",
        "https://files.skg.com/skg/2023-03-24/01e7b26a-8ee3-4845-88cb-14a09430f4c7.jpg",
        "https://files.skg.com/skg/2023-03-24/52b89cb5-944e-481d-b376-44e4c7005369.jpg",
        "https://files.skg.com/skg/2023-03-24/969efa41-24b5-42a9-a20e-63cce2fcaf4f.jpg",
        "https://files.skg.com/skg/2023-03-24/8a81e672-cd07-41d2-a24a-d6ca5e470262.jpg",
        "https://files.skg.com/skg/2023-03-24/ce899476-766f-4be3-ac1f-0fa6a0ff4c12.jpg",
        "https://files.skg.com/skg/2023-03-24/aa899bac-2211-4fff-b73b-b83fba2e886c.jpg",
        "https://files.skg.com/skg/2023-03-24/0900af74-2ded-491d-b346-34526425ef2d.jpg",
        "https://files.skg.com/skg/2023-03-24/3973b773-13ca-4c79-b7a1-b286308509d9.jpg",
        "https://files.skg.com/skg/2023-03-24/de6e46ba-df48-4193-b4e0-2c46924cebdb.jpg"
    ],
    E5: [
        "https://files.skg.com/skg/2023-03-24/efdf55b0-1e3c-47f8-9de0-dadd3d55c712.jpg",
        "https://files.skg.com/skg/2023-03-24/4007627f-bb06-4645-83d4-535d8a9dc772.jpg",
        "https://files.skg.com/skg/2023-03-24/01375a48-3f5c-40dd-8aa6-0830429cbee0.jpg",
        "https://files.skg.com/skg/2023-03-24/178b36c7-efe3-4a7f-898b-e35a81003e19.jpg",
        "https://files.skg.com/skg/2023-03-24/8cdc1c20-4aa6-4f54-8173-a0fbbb642bc9.jpg",
        "https://files.skg.com/skg/2023-03-24/a10acb94-482e-47da-8c3b-8392841bf742.jpg",
        "https://files.skg.com/skg/2023-03-24/e88ea33d-a693-49ef-9049-a613d4d3abec.jpg",
        "https://files.skg.com/skg/2023-03-24/0bb76dec-cdd4-4568-8e6c-3789c71be59c.jpg",
        "https://files.skg.com/skg/2023-03-24/c9ce5cd6-20ec-4b04-8237-e8f91960bf58.jpg",
        "https://files.skg.com/skg/2023-03-24/7eda48f1-bc15-4947-b60a-c205be0955ad.jpg",
        "https://files.skg.com/skg/2023-03-24/1cde3381-ee56-4b4f-a8be-09423b55ef4e.jpg",
        "https://files.skg.com/skg/2023-03-24/f4086353-53a1-43f0-b7cb-aa5cb33a8bc4.jpg",
        "https://files.skg.com/skg/2023-03-24/ceb9be99-528a-44d9-ad86-594c55c62073.jpg",
        "https://files.skg.com/skg/2023-03-24/c87106aa-110b-45cb-99ef-f20525c7f552.jpg",
        "https://files.skg.com/skg/2023-03-24/caf8b1aa-5d5b-45a9-abd0-8d637be0ca07.jpg",
        "https://files.skg.com/skg/2023-03-24/9fdc7c9a-a736-49f1-b902-3b38058c2031.jpg"
    ],
      BW3: [
        "https://files.skg.com/skg/2023-03-24/7e2fd9e9-0d50-4866-93c8-1817ea5a5ac0.jpg",
        "https://files.skg.com/skg/2023-03-24/04bcf96e-dfec-456c-b5cc-bec4eea75312.jpg",
        "https://files.skg.com/skg/2023-03-24/c529d516-f182-475b-ab85-76b0393f002e.jpg",
        "https://files.skg.com/skg/2023-03-24/b4336b62-2ce2-4e6b-b9bb-7edae3fbc3b7.jpg",
        "https://files.skg.com/skg/2023-03-24/c929ea41-d7de-43ab-8a9b-fb29c8445aaf.jpg",
        "https://files.skg.com/skg/2023-03-24/ef965561-32c0-462a-9c8e-c373b176db27.jpg",
        "https://files.skg.com/skg/2023-03-24/b14b156a-811a-490c-bc7f-e89fcce72929.jpg",
        "https://files.skg.com/skg/2023-03-24/1facb841-aa6b-4277-80ed-28fda6ec7c71.jpg",
        "https://files.skg.com/skg/2023-03-24/4b105ff7-831a-46c2-af9e-df471d5c9bda.jpg",
        "https://files.skg.com/skg/2023-03-24/8d05ef93-9556-4c8c-9e3b-dcc6937cd5a0.jpg",
        "https://files.skg.com/skg/2023-03-24/9718d340-a2ba-4eaf-b5cf-76ab6e45852b.jpg",
        "https://files.skg.com/skg/2023-03-24/c9e15c51-c2d6-4501-b1b0-b5a1a870ff8d.jpg",
        "https://files.skg.com/skg/2023-03-24/bb89a2e1-97b9-4317-8cc2-64b209f7b914.jpg",
        "https://files.skg.com/skg/2023-03-24/488b5728-0e0f-4b84-a5fc-ba392442f344.jpg",
        "https://files.skg.com/skg/2023-03-24/eb832e77-2b98-4d20-91f1-3341f5a21ad2.jpg",
        "https://files.skg.com/skg/2023-03-24/07fa0df5-8172-4dda-a0f1-08efed13d2a3.jpg",
        "https://files.skg.com/skg/2023-03-24/bdefaa0b-e872-44a9-8e22-aecb97b7f5fa.jpg",
        "https://files.skg.com/skg/2023-03-24/0d17d9ff-5d3d-49ae-999d-f5ea5d83e809.jpg",
        "https://files.skg.com/skg/2023-03-24/8d90d6cd-4c1a-4eb1-8527-6980947985a6.jpg",
        "https://files.skg.com/skg/2023-03-24/23368f97-c057-42cc-8c98-a0676ca3c425.jpg"
    ],
      T5YIBO:[
        "https://files.skg.com/skg/2022-09-06/729bd7b0-e0cc-487f-a742-01f2a00c122b.jpg",
        "https://files.skg.com/skg/2022-09-06/8a2af43e-3397-46ea-bc39-8afaf2d8b719.jpg",
        "https://files.skg.com/skg/2022-09-06/f8763a97-6f3a-4c9d-a887-c5a847c2484f.jpg",
        "https://files.skg.com/skg/2022-09-06/c690d065-001b-4178-b15f-4aecd334b753.jpg",
        "https://files.skg.com/skg/2022-09-06/028bccd2-f34c-42aa-967e-08480cb696f1.jpg",
        "https://files.skg.com/skg/2022-09-06/cdfcb901-abe3-4574-8e5a-7b049fd6b350.jpg",
        "https://files.skg.com/skg/2022-09-06/2f80217e-dcd0-404e-91e4-cd222a8a73bf.jpg",
        "https://files.skg.com/skg/2022-09-06/14427cfa-3ee8-4914-83f8-7fbae67f795a.jpg",
        "https://files.skg.com/skg/2022-09-06/96dcad08-4b50-4b36-8083-3521b303deb9.jpg",
        "https://files.skg.com/skg/2022-09-06/593556d8-1a65-41f3-9b43-5f725596c693.jpg",
        "https://files.skg.com/skg/2022-09-06/2e17524b-3451-4604-b6d3-9e730ebb9cf2.jpg",
        "https://files.skg.com/skg/2022-09-06/c70221c5-cdd8-4c19-a750-ae09e2d7a278.jpg",
        "https://files.skg.com/skg/2022-09-06/cf7c2588-9fbe-46e4-9e07-44b3721dc256.jpg",
        "https://files.skg.com/skg/2022-09-06/a62b3944-bac6-4255-b525-515a4e2640e0.jpg",
        "https://files.skg.com/skg/2022-09-06/08cec7ba-7b30-4a90-91aa-7cc78baeaf56.jpg",
        "https://files.skg.com/skg/2022-09-06/dd1ec98d-54f4-4f8e-8e73-d18e73ea59f7.jpg",
        "https://files.skg.com/skg/2022-09-06/bedd2012-f4d1-4de4-bd0c-19a204ff940f.jpg",
        "https://files.skg.com/skg/2022-09-06/c7118468-fbdf-4113-a5d6-e11143e48296.jpg",
        "https://files.skg.com/skg/2022-09-06/683b19f6-3f4f-48a2-bd1b-3703b9883fd3.jpg"
    ],
      N5: [
        "https://files.skg.com/skg/2023-03-24/deefe4ce-ba74-4b91-baee-051cc83a6714.jpg",
        "https://files.skg.com/skg/2023-03-24/07a710d5-e07e-4e91-852b-96dc2ba0cc47.jpg",
        "https://files.skg.com/skg/2023-03-24/f5245240-a19f-452e-a784-a05c298c3c57.jpg",
        "https://files.skg.com/skg/2023-03-24/17bc2545-f54c-44b4-a19f-247a54fd1792.jpg",
        "https://files.skg.com/skg/2023-03-24/2f85f98e-98a5-4a49-a99c-4f73b7832e8f.jpg",
        "https://files.skg.com/skg/2023-03-24/4ffdec8e-e4e3-4d88-817c-061c29b68da2.gif",
        "https://files.skg.com/skg/2023-03-24/671528a0-6e83-48dc-96ba-0e96c53ae072.jpg",
        "https://files.skg.com/skg/2023-03-24/23b28702-2faf-46cf-8e40-fbdb60ae6712.jpg",
        "https://files.skg.com/skg/2023-03-24/2a748894-1165-4c19-b0d6-bc9a314bea8d.gif",
        "https://files.skg.com/skg/2023-03-24/41f0215d-da3b-4d02-902b-900467b61eaa.jpg",
        "https://files.skg.com/skg/2023-03-24/33a61346-afa2-4cc3-b643-3e0a663a3a5f.jpg",
        "https://files.skg.com/skg/2023-03-24/661fcbfd-b359-401b-a032-40d1ea9b60b5.jpg",
        "https://files.skg.com/skg/2023-03-24/91343caf-eb74-4b1f-b58a-5a2cb6c94a9e.jpg",
        "https://files.skg.com/skg/2023-03-24/16e1220d-6e80-4fa7-8664-a57e541040fa.jpg",
        "https://files.skg.com/skg/2023-03-24/3319a62e-ae01-44a7-82c8-5574ad379178.jpg",
        "https://files.skg.com/skg/2023-03-24/52d2eaff-0266-4bb9-9226-dbc2fa3cff44.jpg",
        "https://files.skg.com/skg/2023-03-24/c6797f8e-fd7d-4f1e-9ef8-b0636ce808ac.gif",
        "https://files.skg.com/skg/2023-03-24/2f0350a2-c936-4145-9543-eddfe8afc1df.jpg",
        "https://files.skg.com/skg/2023-03-24/e09e2841-562f-4031-ac63-e73546340050.jpg",
        "https://files.skg.com/skg/2023-03-24/13342861-e0a5-436a-8a9e-f7f6788f8f66.jpg",
        "https://files.skg.com/skg/2023-03-24/2c5d677a-ebd9-4ffb-a411-b80bf5f9ef2f.jpg"
    ],
      P7:[
        "https://files.skg.com/skg/2023-03-24/3ff471b2-7140-4252-9eca-c4285f63a458.jpg",
        "https://files.skg.com/skg/2023-03-24/6f324191-52d0-4faa-94d6-857ff4b5ca05.jpg",
        "https://files.skg.com/skg/2023-03-24/56169fc3-d23d-4138-93a2-070a45c3148c.jpg",
        "https://files.skg.com/skg/2023-03-24/76a8a80d-8033-49f3-8711-7fa6b1f537f2.jpg",
        "https://files.skg.com/skg/2023-03-24/16823169-e30c-49b9-a9ae-ed391b8493a7.jpg",
        "https://files.skg.com/skg/2023-03-24/c34f9ed4-9a28-4309-aff8-1c5285190e63.gif",
        "https://files.skg.com/skg/2023-03-24/265163dd-aab1-4554-a2fd-c5445fa2ba80.jpg",
        "https://files.skg.com/skg/2023-03-24/2e86f34c-3843-43ed-a53b-8c7dac22b8b1.gif",
        "https://files.skg.com/skg/2023-03-24/80dd9e36-386a-4911-b172-22cda37df835.jpg",
        "https://files.skg.com/skg/2023-03-24/27aa5df9-cb3b-4902-9522-f379f65d963c.gif",
        "https://files.skg.com/skg/2023-03-24/97f089c1-f7d3-479e-8e91-2ef2a66b9829.jpg",
        "https://files.skg.com/skg/2023-03-24/44a7e817-f440-4817-8cbb-8a118b809349.gif",
        "https://files.skg.com/skg/2023-03-24/730bc95e-15b8-42da-a139-9610b489dc14.jpg",
        "https://files.skg.com/skg/2023-03-24/0f4978ef-b377-423e-b165-4df6cd7833b5.jpg",
        "https://files.skg.com/skg/2023-03-24/ba5d6f5d-a3f8-44fa-819b-5bfdb04473b0.jpg",
        "https://files.skg.com/skg/2023-03-24/5bb0d44d-ae52-44fe-9161-a98576962632.jpg",
        "https://files.skg.com/skg/2023-03-24/478e54ae-6695-4557-9ce6-ba9de420e9bb.jpg",
        "https://files.skg.com/skg/2023-03-24/1e8e84c1-f44f-4ac1-b7d7-d521b4de53a5.jpg",
        "https://files.skg.com/skg/2023-03-24/34598762-2b80-4250-adba-2b34896b2600.jpg",
        "https://files.skg.com/skg/2023-03-24/d8b67b27-c36e-47db-8929-606d08920b9a.jpg"
    ],
      R6: [
        "https://files.skg.com/skg/2023-03-24/8d2208dd-b2fd-4e67-8148-de3ca6ef1f61.jpg",
        "https://files.skg.com/skg/2023-03-24/8cae5d15-57d1-4c88-a282-907800c61f0d.jpg",
        "https://files.skg.com/skg/2023-03-24/0a12e9b8-dc7e-48cc-bf42-5d74fb569853.jpg",
        "https://files.skg.com/skg/2023-03-24/700cdbd9-0b91-4071-825a-3fbfec3dbceb.jpg",
        "https://files.skg.com/skg/2023-03-24/3f7506b5-5be6-46fc-a83c-745ca5231cdd.jpg",
        "https://files.skg.com/skg/2023-03-24/cfea4fd9-0208-48cf-bdf7-037be6b3a8e4.jpg",
        "https://files.skg.com/skg/2023-03-24/7b6ab471-b6e1-482e-9f48-d8fd2befe693.jpg",
        "https://files.skg.com/skg/2023-03-24/40c83b7a-d4bf-4e2a-bd66-3326b3d0dc32.jpg",
        "https://files.skg.com/skg/2023-03-24/8bf9c844-bd59-4c76-99ac-41391d74ebdc.jpg",
        "https://files.skg.com/skg/2023-03-24/2239fb67-4325-4448-8434-35c5d19e920b.jpg",
        "https://files.skg.com/skg/2023-03-24/69c50f8a-1281-4aaa-a486-03cc100bbf3d.jpg",
        "https://files.skg.com/skg/2023-03-24/57fef547-dbcc-4886-9fa8-d285313257c2.jpg",
        "https://files.skg.com/skg/2023-03-24/46cf1f53-f29a-4146-848e-cb4f6f03b0b2.jpg",
        "https://files.skg.com/skg/2023-03-24/541eb2bb-0954-4702-b302-fd48d8b97442.jpg",
        "https://files.skg.com/skg/2023-03-24/e9585d3a-2cb6-4184-a03b-b6e9e2aa441e.jpg",
        "https://files.skg.com/skg/2023-03-24/e8b6d908-8f84-4d1d-a475-b06fe424ea06.jpg",
        "https://files.skg.com/skg/2023-03-24/34526486-7eb8-4e2c-9ab5-4a86539fb24a.jpg",
        "https://files.skg.com/skg/2023-03-24/878f4a5d-255c-47b6-957f-3cf51c00366b.jpg"
    ],
      BK3: [
        "https://files.skg.com/skg/2023-03-24/8ef8c9c6-42db-4716-af30-7a13473ed086.jpg",
        "https://files.skg.com/skg/2023-03-24/68e4f05e-ba75-493c-adb2-9feb60d91968.jpg",
        "https://files.skg.com/skg/2023-03-24/a2e07a31-4891-45fb-99f4-91b2aa05ed20.jpg",
        "https://files.skg.com/skg/2023-03-24/f01944bd-52f3-443a-8305-e1c137d69c0c.gif",
        "https://files.skg.com/skg/2023-03-24/cf246b7c-ee9a-4135-9d29-5fb4332b2dff.gif",
        "https://files.skg.com/skg/2023-03-24/c496309a-0c23-49b7-9814-d7abe6c2fe29.jpg",
        "https://files.skg.com/skg/2023-03-24/e27c7d87-c90a-43f1-a968-0487098f0904.gif",
        "https://files.skg.com/skg/2023-03-24/fa228f66-eb93-409a-bc6d-1962ea09dd68.jpg",
        "https://files.skg.com/skg/2023-03-24/17ca525c-b44c-4f79-a3ce-cf722b920337.gif",
        "https://files.skg.com/skg/2023-03-24/3a561485-265e-40f9-a3c8-2748f59d99a0.jpg",
        "https://files.skg.com/skg/2023-03-24/b28b196f-aeb6-49a4-88a3-b0083ddb2b82.gif",
        "https://files.skg.com/skg/2023-03-24/842ea76c-2608-4d57-91b4-7405e225e739.jpg",
        "https://files.skg.com/skg/2023-03-24/9e20dfd8-2919-417f-a3c1-eb8409ffdb98.gif",
        "https://files.skg.com/skg/2023-03-24/b015fbad-1bf1-47cc-882c-d335e94d3aa4.jpg",
        "https://files.skg.com/skg/2023-03-24/453de1ba-5b4a-4c50-bb7d-fa467206b403.jpg",
        "https://files.skg.com/skg/2023-03-24/81fb02fd-16e3-461a-86a4-15282b1e2a7b.gif",
        "https://files.skg.com/skg/2023-03-24/8570e3f6-968a-40a1-a41b-c7e9f980fa81.jpg",
        "https://files.skg.com/skg/2023-03-24/ce8d721b-efdc-4986-a2dd-afafd14e6843.jpg",
        "https://files.skg.com/skg/2023-03-24/fe2a8b68-4862-4e9c-a8b4-cb6b2602b25f.jpg",
        "https://files.skg.com/skg/2023-03-24/78c3eee6-63de-4839-9ea9-f0c1417aca86.jpg"
    ],
      BM3:[
        "https://files.skg.com/skg/2023-03-24/1d4b6740-6edc-4d63-bc7e-44fc6803056e.jpg",
        "https://files.skg.com/skg/2023-03-24/8e79da90-4f72-4173-9b2a-35252e8dae64.jpg",
        "https://files.skg.com/skg/2023-03-24/dc5be40a-dfcc-4f11-851c-18686f68b6b0.jpg",
        "https://files.skg.com/skg/2023-03-24/3a507935-7cec-4659-9ea8-ef5c3c834238.jpg",
        "https://files.skg.com/skg/2023-03-24/64054818-0f58-4206-aa79-6714e82f2494.jpg",
        "https://files.skg.com/skg/2023-03-24/b9586f39-8616-403d-87cd-0b4c3e62e44b.jpg",
        "https://files.skg.com/skg/2023-03-24/6ea38758-f485-41eb-84d6-97eabc33fed6.jpg",
        "https://files.skg.com/skg/2023-03-24/4efbecdd-c883-4068-9c6e-a674272edbe6.jpg",
        "https://files.skg.com/skg/2023-03-24/91bd32c9-3c8d-46dc-8aab-7e8675edb00b.jpg",
        "https://files.skg.com/skg/2023-03-24/65efc08b-dd16-4475-9292-ec5cbef45746.jpg",
        "https://files.skg.com/skg/2023-03-24/93d7424e-d506-4a15-bce9-23c363a0caed.jpg",
        "https://files.skg.com/skg/2023-03-24/7ffbb1e5-b46e-4ec8-a11c-203207193313.jpg",
        "https://files.skg.com/skg/2023-03-24/bdb70894-1f8c-48a8-b045-090e7309f834.jpg",
        "https://files.skg.com/skg/2023-03-24/96ef8bcd-bd4a-4594-bbd0-470984f77439.jpg",
        "https://files.skg.com/skg/2023-03-24/587dc94d-927a-493f-bdc0-a3ca48a4abbe.jpg",
        "https://files.skg.com/skg/2023-03-24/f8f06bb2-4229-4e6a-9bb2-2bfe43f3b6f4.jpg"
    ],
      BC3: [
        "https://files.skg.com/skg/2023-03-24/939c029b-f08c-409c-b532-38c23c1d36c5.jpg",
        "https://files.skg.com/skg/2023-03-24/5268ce24-621b-4c3c-9408-efb82ddbc4fb.jpg",
        "https://files.skg.com/skg/2023-03-24/524bad20-8d87-4f96-814a-89137fd4a564.jpg",
        "https://files.skg.com/skg/2023-03-24/7192f4d6-7747-470f-b91e-1647d8e720ff.jpg",
        "https://files.skg.com/skg/2023-03-24/918530bc-81e3-4110-ba22-fb164edb6e62.jpg",
        "https://files.skg.com/skg/2023-03-24/4b2b3220-fb0c-4d13-ae7f-6ddfb53f9dd5.jpg",
        "https://files.skg.com/skg/2023-03-24/20d6086e-9b2e-43c7-926b-2d2bee791374.jpg",
        "https://files.skg.com/skg/2023-03-24/6447155e-c38e-4b77-8f33-14c5c5b012b4.jpg",
        "https://files.skg.com/skg/2023-03-24/2b9236ec-e569-48f6-a8c8-f8e756d9b708.jpg",
        "https://files.skg.com/skg/2023-03-24/190f93bd-09cf-493a-b4c5-eab5900cb7b7.jpg",
        "https://files.skg.com/skg/2023-03-24/6524156a-f088-4108-bbfb-199446d8ed6f.jpg",
        "https://files.skg.com/skg/2023-03-24/ced2a24a-8c2b-4be1-952d-1971129a1195.jpg",
        "https://files.skg.com/skg/2023-03-24/82e59e13-c7ca-4212-8ccd-e59519776e4e.jpg",
        "https://files.skg.com/skg/2023-03-24/35585776-fd85-40b5-a307-85a9dd3eb0dd.jpg",
        "https://files.skg.com/skg/2023-03-24/e688a40c-ecfa-4160-b400-6b4cb1998c0f.jpg",
        "https://files.skg.com/skg/2023-03-24/aada82b8-2c3f-4fd0-96ff-9e187e318e20.jpg"
    ],
      E4Pro:[
        "https://files.skg.com/skg/2023-03-24/dc8081e1-6f08-4863-b72c-e145491e2b4a.jpg",
        "https://files.skg.com/skg/2023-03-24/c9bf6233-0907-46dc-8cd6-d90df433bc97.jpg",
        "https://files.skg.com/skg/2023-03-24/7815771b-8049-4dcf-afb3-b41789538801.jpg",
        "https://files.skg.com/skg/2023-03-24/189c1f22-82bd-470f-bfcf-4c8b7e646e79.jpg",
        "https://files.skg.com/skg/2023-03-24/3caced48-98e6-4d8f-942c-a45da4dfa005.jpg",
        "https://files.skg.com/skg/2023-03-24/8a4ec1aa-6d94-487e-ae65-5525acb8b68a.jpg",
        "https://files.skg.com/skg/2023-03-24/5588c973-2c55-4598-827e-9fb102fd1be9.jpg",
        "https://files.skg.com/skg/2023-03-24/f70b92e8-a573-41eb-9d82-31223d314eb9.jpg",
        "https://files.skg.com/skg/2023-03-24/c93c5a1c-7fbd-4b68-b475-592b990bee64.jpg",
        "https://files.skg.com/skg/2023-03-24/b0b862ae-1e22-4c93-9538-23de9a687f6b.jpg",
        "https://files.skg.com/skg/2023-03-24/bea36639-817f-4b71-b801-a89c786ead6f.jpg",
        "https://files.skg.com/skg/2023-03-24/e9720e9c-737c-4210-a760-b8c062b12ce1.jpg",
        "https://files.skg.com/skg/2023-03-24/12220541-036a-41b7-94e9-47ea3616e413.jpg",
        "https://files.skg.com/skg/2023-03-24/3629f31c-bf74-4a35-a359-a1ed32a00983.jpg",
        "https://files.skg.com/skg/2023-03-24/779d4191-31d3-46bb-9cfe-39660d25108b.jpg",
        "https://files.skg.com/skg/2023-03-24/ba112330-3d91-4598-9585-ef531f54520d.jpg"
    ],
      W7: [
        "https://files.skg.com/skg/2023-03-24/58658079-4410-4ff3-a040-03c872e113df.jpg",
        "https://files.skg.com/skg/2023-03-24/5bbe79dc-e485-4967-8eff-18dc039e8ca3.jpg",
        "https://files.skg.com/skg/2023-03-24/ef694fc4-d66a-4e88-825d-2e6db9884cfa.jpg",
        "https://files.skg.com/skg/2023-03-24/7109e470-ac70-4801-b895-a0947c755f3c.jpg",
        "https://files.skg.com/skg/2023-03-24/f0ff104e-bf7c-49b5-adf2-05006c9dcbcd.jpg",
        "https://files.skg.com/skg/2023-03-24/93927076-5dd2-4163-8c30-75fcbda3d72c.jpg",
        "https://files.skg.com/skg/2023-03-24/c9f1ccd9-982e-44d5-8ac3-a0c4d23af3e5.gif",
        "https://files.skg.com/skg/2023-03-24/c9aa72f4-29a7-40c7-8dbd-1c601c4f4a7b.jpg",
        "https://files.skg.com/skg/2023-03-24/693e57a6-b7c6-4e6b-8eff-c917dc3486b3.jpg",
        "https://files.skg.com/skg/2023-03-24/b6d3356f-df2c-4675-b8ec-a1aaafb87677.jpg",
        "https://files.skg.com/skg/2023-03-24/be20399b-32dd-46d2-b306-74292ebca562.gif",
        "https://files.skg.com/skg/2023-03-24/24170605-fd6f-43a9-be9b-504bb1f6b4d3.jpg",
        "https://files.skg.com/skg/2023-03-24/62a76bee-3658-48cc-91b5-74a1007b1ae9.gif",
        "https://files.skg.com/skg/2023-03-24/a4a5e64d-8cdf-45d7-9f0e-26ed460d36e8.jpg",
        "https://files.skg.com/skg/2023-03-24/f900cb71-2ba2-464c-98f7-edca1698a4b8.jpg",
        "https://files.skg.com/skg/2023-03-24/11743e73-4997-4cf5-9135-519ae137de88.jpg",
        "https://files.skg.com/skg/2023-03-24/2a08b69d-41ac-4c31-9b17-5147b6517128.jpg",
        "https://files.skg.com/skg/2023-03-24/4d9e3aca-36dc-437c-a632-3f6c926d54c0.jpg",
        'https://files.skg.com/skg/2023-12-06/4f563884-e5fd-44be-a8e9-487e907de518.jpg',
        'https://files.skg.com/skg/2023-12-06/5166fdd7-7829-414e-90b6-8df6a3cad5d9.jpg',
        "https://files.skg.com/skg/2023-03-24/93b3c9e2-8ff7-46a6-9556-39a111738d82.jpg",
        "https://files.skg.com/skg/2023-03-24/6e774b9b-7db7-49ba-aea0-137ea03e7f3f.jpg",
        "https://files.skg.com/skg/2023-03-24/de8e7cce-6ba0-419e-adcb-2d0301fd8df6.jpg",
        "https://files.skg.com/skg/2023-03-24/9416f9eb-77ac-4a23-9397-02b096915129.jpg"
    ],
    X7: [
        "https://files.skg.com/skg/2023-03-24/f89d3407-5f3e-4ebc-a6fd-d1be0990b9ec.jpg",
        "https://files.skg.com/skg/2023-03-24/ac430c03-bcbc-4540-9ed3-770e9c2e0b7f.png",
        "https://files.skg.com/skg/2023-03-24/72cbd08d-59b9-4c08-8bbf-8f88ed9b9e28.jpg",
        "https://files.skg.com/skg/2023-03-24/d7e70c48-8862-4b03-a76e-f6d6b7b5c5f1.jpg",
        "https://files.skg.com/skg/2023-03-24/35ef99fa-81b7-4bbf-953d-352240d8dd77.gif",
        "https://files.skg.com/skg/2023-03-24/f4bbe5b0-904b-41d8-9016-a8c05763feb7.jpg",
        "https://files.skg.com/skg/2023-03-24/5040f53b-8fd7-4fdd-94c5-0279e26931c7.gif",
        "https://files.skg.com/skg/2023-03-24/4111a0bf-c877-42ed-81d1-97f04c698893.jpg",
        "https://files.skg.com/skg/2023-03-24/08ce1763-4ccf-4231-8bf5-c6b4295ceba5.gif",
        "https://files.skg.com/skg/2023-03-24/6878668d-b1e9-4277-9c9b-c13682880b3d.jpg",
        "https://files.skg.com/skg/2023-03-24/c948721e-5bcc-4fe8-9da9-e6998fb3ad18.png",
        "https://files.skg.com/skg/2023-03-24/7f93e4d5-3d5f-48c1-b80e-d6e9e64fc6a0.jpg",
        "https://files.skg.com/skg/2023-03-24/4b06a745-f192-4d36-b672-27070d6af51a.jpg",
        "https://files.skg.com/skg/2023-03-24/ecbe91ff-cf55-4580-a36c-8971f5c459d3.gif",
        "https://files.skg.com/skg/2023-03-24/f4b622fd-e4c1-400e-a945-5037af08c6d8.jpg",
        "https://files.skg.com/skg/2023-03-24/7d4fc343-5a3f-4b63-a5e9-e9bbc6b4a87d.png",
        "https://files.skg.com/skg/2023-03-24/64411388-76cd-47fd-9c1b-54c4be041c96.jpg",
        "https://files.skg.com/skg/2023-03-24/eedcdfbc-b8df-4f62-849f-ff35669784d9.jpg",
        "https://files.skg.com/skg/2023-03-24/0882c34d-919c-4995-82cd-f81b86f5bbcc.jpg",
        "https://files.skg.com/skg/2023-03-24/bff35019-ab49-4b48-b0a5-b91697ffcb06.jpg",
        "https://files.skg.com/skg/2023-03-24/ed9887bf-eb40-4636-8feb-c3d488838718.jpg",
        "https://files.skg.com/skg/2023-03-24/187f0be6-98b9-451f-8a63-29c215c14e0f.jpg",
        "https://files.skg.com/skg/2023-03-24/6050dcba-7fef-413a-9eb3-cc1080427b3d.jpg",
        "https://files.skg.com/skg/2023-03-24/8f733f0b-4728-479f-b718-5d36ac185493.jpg",
        "https://files.skg.com/skg/2023-03-24/20600fa2-65d0-4d66-908f-04f6e4b62d93.jpg",
        "https://files.skg.com/skg/2023-03-24/7c520cb0-57f1-4603-8015-b1d39bf92d05.jpg"
    ],
    T5YK: [
        "https://files.skg.com/skg/2023-03-24/5d5b5d0f-f1cc-4006-a0ed-31cbcaae28a8.jpg",
        "https://files.skg.com/skg/2023-03-24/fea6355a-0fd3-47c0-9da3-9aba12a0f8fb.jpg",
        "https://files.skg.com/skg/2023-03-24/41af7778-eb8c-42c2-b754-6461835c03dc.jpg",
        "https://files.skg.com/skg/2023-03-24/cc9d9d5b-98e3-44e5-b65c-76eacf36b1f2.jpg",
        "https://files.skg.com/skg/2023-03-24/ebe36eae-3946-4f13-a4f2-1dac22511bf3.jpg",
        "https://files.skg.com/skg/2023-03-24/1226adf8-f193-4e55-8450-be235be651d8.gif",
        "https://files.skg.com/skg/2023-03-24/eb8b87bd-cdb9-4b81-878f-1b600cfe2526.jpg",
        "https://files.skg.com/skg/2023-03-24/0c7853a6-f6ce-4290-b70f-5ac3a1fe573a.gif",
        "https://files.skg.com/skg/2023-03-24/548546b8-56c1-46ee-8a56-347c5e46f3ad.jpg",
        "https://files.skg.com/skg/2023-03-24/db4ef38f-0383-4c0a-84a4-1acf3d7b1b2c.gif",
        "https://files.skg.com/skg/2023-03-24/6df46a26-0571-4e26-af90-66356f2fcd86.jpg",
        "https://files.skg.com/skg/2023-03-24/901fe677-c9d3-4080-b681-5c854ddd8ff6.gif",
        "https://files.skg.com/skg/2023-03-24/4ffddc47-15c8-426d-acb7-d18e59b61270.jpg",
        "https://files.skg.com/skg/2023-03-24/acac9bdf-b71e-40de-87b2-206001fbf930.jpg",
        "https://files.skg.com/skg/2023-03-24/f49414f5-1088-4f8f-98a6-146317b7f29b.jpg",
        "https://files.skg.com/skg/2023-03-24/5880b5b3-81e6-4fc0-a9ae-779354264198.jpg",
        "https://files.skg.com/skg/2023-03-24/75cc5928-d8c0-4192-9161-9a01423e4b35.jpg",
        "https://files.skg.com/skg/2023-03-24/6f05f2bd-2f70-4951-8b20-f8f9599423e4.jpg",
        "https://files.skg.com/skg/2023-03-24/96043024-90e2-40dd-b32c-146d315608a2.jpg",
        "https://files.skg.com/skg/2023-03-24/9017aeae-7cef-4d55-b169-9beeea264222.jpg",
        "https://files.skg.com/skg/2023-03-24/6d255d14-c254-43bd-9c96-bec54052ee01.jpg"
    ],
    W3: [
        "https://files.skg.com/skg/2023-04-17/0f06a93d-c478-4072-a726-843d47b6a661.jpg",
        "https://files.skg.com/skg/2023-04-17/e95deb4b-021d-44a7-8bdc-367021003a8b.jpg",
        "https://files.skg.com/skg/2023-04-17/ed48937b-9b59-4e62-8b8e-38b905d9e782.jpg",
        "https://files.skg.com/skg/2023-04-17/60ae2540-7996-4002-b298-59e017707aeb.jpg",
        "https://files.skg.com/skg/2023-04-17/4e484d89-655f-4848-9a72-1b3c428ec764.jpg",
        "https://files.skg.com/skg/2023-04-17/38dc668a-0d03-48b6-b36d-b56cbf3d8ac7.jpg",
        "https://files.skg.com/skg/2023-04-17/18fb8763-da8c-4c4d-8b85-366739d34ad9.jpg",
        "https://files.skg.com/skg/2023-04-17/56f460ee-7c5e-4758-ade8-446bf0ca40ca.jpg",
        "https://files.skg.com/skg/2023-04-17/6cf18a98-8984-417a-b10b-b2e4f54ef0b6.jpg",
        "https://files.skg.com/skg/2023-04-17/79925c9d-b38e-40ba-a153-b437f76efee2.jpg",
        "https://files.skg.com/skg/2023-04-17/7170a59b-5bef-4ae6-9c90-887142220ce0.jpg",
        "https://files.skg.com/skg/2023-04-17/fe2e44d0-d136-4aad-a3fb-21308d6c4ecc.jpg",
        "https://files.skg.com/skg/2023-04-17/1488b755-3481-41c9-8724-5d54ea1768ea.jpg",
        "https://files.skg.com/skg/2023-04-17/e65ffa92-8afb-467f-953c-9c73d8fd5609.jpg",
        "https://files.skg.com/skg/2023-04-17/01404b36-dc9d-438c-b2ce-2aec44692503.jpg",
        "https://files.skg.com/skg/2023-04-17/f6bb88f2-b67e-4376-9a88-6d5f3a204acb.jpg",
        "https://files.skg.com/skg/2023-04-17/4a86a801-65f9-4f52-8e80-b4b9d17ab39d.jpg"
    ],
    E3PRO: [
        "https://files.skg.com/skg/2023-03-24/8464bb1c-d5b6-440a-be9d-069e7823d603.jpg",
        "https://files.skg.com/skg/2023-03-24/0b891f71-84e5-485e-9a93-36cc38f167c5.jpg",
        "https://files.skg.com/skg/2023-03-24/f77745ed-9ce5-4a6b-b26e-558fc70900eb.jpg",
        "https://files.skg.com/skg/2023-03-24/492881ba-758f-43b8-9c10-5413bedadb60.jpg",
        "https://files.skg.com/skg/2023-03-24/c4e40bb2-f36b-4644-8670-7477708dbba6.jpg",
        "https://files.skg.com/skg/2023-03-24/3e6460ff-22c1-4bab-b8e8-c3552ea5027c.jpg",
        "https://files.skg.com/skg/2023-03-24/22f58ee3-0626-4cb9-9c3f-a471c14a93a4.jpg",
        "https://files.skg.com/skg/2023-03-24/2d3987d1-d346-4dea-8c1a-327770480200.jpg",
        "https://files.skg.com/skg/2023-03-24/19db98a1-2b28-4ac3-9c76-90d7f1f223b6.jpg",
        "https://files.skg.com/skg/2023-03-24/e63371c9-1b9e-4a36-8fd3-490f276aa4b3.jpg",
        "https://files.skg.com/skg/2023-03-24/70531bab-bd30-4aae-8faf-3307d3ad0a3d.jpg",
        "https://files.skg.com/skg/2023-03-24/873ca54f-70b7-4c40-b4e8-a6dd74778f5b.jpg",
        "https://files.skg.com/skg/2023-03-24/e88e63fe-0ec5-4e3b-b730-b475e7997f53.jpg",
        "https://files.skg.com/skg/2023-03-24/d85df4e1-2e71-400e-99fd-04936e0aa8f7.jpg",
        "https://files.skg.com/skg/2023-03-24/5a5634b8-7831-4161-9470-d2d58f40c6f8.jpg",
        "https://files.skg.com/skg/2023-03-24/5eb1c280-741d-413d-90b8-6a55a85a7479.jpg",
        "https://files.skg.com/skg/2023-03-24/8071a7d1-9172-412a-9a8b-994d8df36401.jpg"
    ],
    XMY: [
        "https://files.skg.com/skg/2023-03-24/7f38be07-2a15-4fcc-bd09-9fc4d93ad033.jpg",
        "https://files.skg.com/skg/2023-03-24/6348a558-38f6-4c2e-89c8-c353a607b3ea.jpg",
        "https://files.skg.com/skg/2023-03-24/b8c6b768-8c1f-400b-b49f-0c7d03784818.jpg",
        "https://files.skg.com/skg/2023-03-24/b0cc4da8-1fc6-4324-a647-9b8d1ef4acad.jpg",
        "https://files.skg.com/skg/2023-03-24/848b6513-3ddb-4a1d-90f9-bacbcd0da6b5.jpg",
        "https://files.skg.com/skg/2023-03-24/4a343be2-f6ba-4c55-b234-c0a7e5d729ce.jpg",
        "https://files.skg.com/skg/2023-03-24/138d8ac1-b2f4-4cf6-a90d-8c4c2bf2c23b.jpg",
        "https://files.skg.com/skg/2023-03-24/bd993c97-4529-4d3f-96d4-25690ced6830.jpg",
        "https://files.skg.com/skg/2023-03-24/c2d44066-123c-4bec-a3e9-b34b2df8d413.jpg",
        "https://files.skg.com/skg/2023-03-24/5c6c2262-7bff-4c3c-a732-747b33b14958.jpg",
        "https://files.skg.com/skg/2023-03-24/2070cfa5-8a0e-4b54-8af5-58daa6efe1fd.jpg",
        "https://files.skg.com/skg/2023-03-24/3dfc3eff-7e5f-4166-857a-57b06580e32b.jpg",
        "https://files.skg.com/skg/2023-03-24/763ee687-3201-4537-acb0-7e321194b89e.jpg",
        "https://files.skg.com/skg/2023-03-24/19656fe9-9520-4dfe-9f2e-280fe9f3ce41.jpg",
        "https://files.skg.com/skg/2023-03-24/abf46652-52b7-45dd-aefa-80dd487e163b.jpg",
        "https://files.skg.com/skg/2023-03-24/17899f22-63ae-4bb3-bd73-245580722f8d.jpg",
        "https://files.skg.com/skg/2023-03-24/18a2933a-4413-425a-ab76-969c1340122e.jpg",
        "https://files.skg.com/skg/2023-03-24/31b203d6-2d05-408f-bbef-96190cf0c32b.jpg",
        "https://files.skg.com/skg/2023-03-24/166810f6-b93f-4bb0-8e8a-fb03dee85fcb.jpg",
        "https://files.skg.com/skg/2023-03-24/ab1b4c74-2807-44e7-88a8-1ecc7031db65.jpg"
    ],
    K5EYE:[
        "https://files.skg.com/skg/2023-03-24/d9e7691e-1479-4b34-8be2-0a2b85d31a26.jpg",
        "https://files.skg.com/skg/2023-03-24/30fac4ad-6eba-4be3-a3b8-2c8be8bebd87.jpg",
        "https://files.skg.com/skg/2023-03-24/e0ad858a-9aae-44ba-b29c-4225b3b8b5ca.jpg",
        "https://files.skg.com/skg/2023-03-24/69467ff6-2798-4c5c-a531-5779206f8596.jpg",
        "https://files.skg.com/skg/2023-03-24/c47d13df-d7c6-451f-aac2-6500118480a9.jpg",
        "https://files.skg.com/skg/2023-03-24/b845e396-053b-499a-9218-b65a796ec3b5.jpg",
        "https://files.skg.com/skg/2023-03-24/ccaaff7f-af47-482c-8543-b9622b1b0b82.jpg",
        "https://files.skg.com/skg/2023-03-24/c96405ce-39a3-4abb-b544-2fc90189193e.gif",
        "https://files.skg.com/skg/2023-03-24/1daf0d7c-4b77-4ca6-b550-fb162e67187d.jpg",
        "https://files.skg.com/skg/2023-03-24/5e0d70f8-a29d-426c-9333-db254e5656da.gif",
        "https://files.skg.com/skg/2023-03-24/5cad4d8e-0748-4a0f-a25d-f102700529d1.jpg",
        "https://files.skg.com/skg/2023-03-24/c1ea612f-fdac-46da-b0f2-280e44aea22e.jpg",
        "https://files.skg.com/skg/2023-03-24/0991c285-a4d7-4787-a423-2e1a7ad8e368.jpg",
        "https://files.skg.com/skg/2023-03-24/724df26c-8a4d-47a5-bd38-c1f4392f3e40.jpg",
        "https://files.skg.com/skg/2023-03-24/2633984b-51fc-4b77-bd4e-e3976fa48b6b.jpg",
        "https://files.skg.com/skg/2023-03-24/6e4e63c4-eb82-4862-9035-687304e1c077.gif",
        "https://files.skg.com/skg/2023-03-24/cdbeb458-e405-4ef3-bfea-544419166fe6.jpg",
        "https://files.skg.com/skg/2023-03-24/3ee412a5-2e6c-4344-8f1f-e6d7f365626c.gif",
        "https://files.skg.com/skg/2023-03-24/54212dd6-a4e7-49c0-9e37-bdd11e399cfc.jpg",
        "https://files.skg.com/skg/2023-03-24/1f2ff7de-6142-426f-9e4c-f702d1e0c87d.jpg",
        "https://files.skg.com/skg/2023-03-24/681a158e-e3e4-426c-8702-4cfdaf3b21eb.jpg",
        "https://files.skg.com/skg/2023-03-24/5e048e79-33af-401b-a6ca-c1eab439215c.jpg",
        "https://files.skg.com/skg/2023-03-24/181226d5-1213-4888-b334-212985fefa3d.jpg",
        "https://files.skg.com/skg/2023-03-24/201480e3-1697-4245-93fd-e785cbd4bd6c.jpg",
        "https://files.skg.com/skg/2023-03-24/84e8c616-ecb0-4c13-9ff3-78ef1364c39a.jpg",
        "https://files.skg.com/skg/2023-03-24/0257a97e-7bc2-4230-869d-c1c40f9a51d7.jpg"
    ],
    K5Pro: [
        "https://files.skg.com/skg/2023-03-24/1ddf4a48-ca91-4a7f-a14e-485dfec934c9.jpg",
        "https://files.skg.com/skg/2023-03-24/5baeb7c6-3366-4bca-8f6f-8dd6827400c2.jpg",
        "https://files.skg.com/skg/2023-03-24/5aab55c8-5c1a-4996-a0b0-f78135319ac2.jpg",
        "https://files.skg.com/skg/2023-03-24/ef47248c-688d-4a94-aca4-87314da4d904.jpg",
        "https://files.skg.com/skg/2023-03-24/6cf9feb7-a819-4fdc-9149-0148252d6d1f.jpg",
        "https://files.skg.com/skg/2023-03-24/eb6d2f09-b11a-403a-ac75-d5beb9fb1c2b.jpg",
        "https://files.skg.com/skg/2023-03-24/bd5cc32a-c275-49a7-b6e6-653936e52485.jpg",
        "https://files.skg.com/skg/2023-03-24/a11707db-19ef-4f7e-9806-ca5ed29b1d6e.jpg",
        "https://files.skg.com/skg/2023-03-24/cbdcf230-73e1-445d-9153-190f7726fb2f.jpg",
        "https://files.skg.com/skg/2023-03-24/25390495-c6e6-44ba-acb2-fd4c09345b85.gif",
        "https://files.skg.com/skg/2023-03-24/819a40b4-3ac3-44a6-b3f9-5b8594bc90a6.jpg",
        "https://files.skg.com/skg/2023-03-24/51f05235-f85c-4443-b109-ee9d8a3f031f.gif",
        "https://files.skg.com/skg/2023-03-24/0b8f6910-23c7-4b71-bd35-57da8dcee6ca.jpg",
        "https://files.skg.com/skg/2023-03-24/30fc9e87-45b3-4ce5-929b-5ba636204382.gif",
        "https://files.skg.com/skg/2023-03-24/1778895e-d2b2-4d84-b7db-6abc7cb10fd2.jpg",
        "https://files.skg.com/skg/2023-03-24/f27015a7-fbfb-4eff-88c8-f887923b84a9.gif",
        "https://files.skg.com/skg/2023-03-24/ed9887d8-400c-4dd6-8db3-3131eb40fa3a.jpg",
        "https://files.skg.com/skg/2023-03-24/ccd4fceb-ced9-4f76-8c12-07691c6f3ea7.jpg",
        "https://files.skg.com/skg/2023-03-24/77100721-7584-4df6-9406-4f0d65a12de5.jpg",
        "https://files.skg.com/skg/2023-03-24/2019d30f-4c58-44bb-b139-dbb76daa8c2d.jpg",
        "https://files.skg.com/skg/2023-03-24/90c565b3-6a32-4e8a-987b-ee9f0b1460b2.jpg"
    ],
    H7: [
        "https://files.skg.com/skg/2023-03-24/6cd99415-3b0f-4297-af56-b59a340aa754.jpg",
        "https://files.skg.com/skg/2023-03-24/3bd3bd70-6274-4d9c-b42b-d01c0d24d677.jpg",
        "https://files.skg.com/skg/2023-03-24/4b418f61-c42f-40e5-9a2b-dac27a8e4d77.jpg",
        "https://files.skg.com/skg/2023-03-24/4be05d2f-880e-4564-ad5c-69e5cb32e01b.jpg",
        "https://files.skg.com/skg/2023-03-24/66f883b3-ad86-4d1c-9123-ac7da76b5a0a.jpg",
        "https://files.skg.com/skg/2023-03-24/45d84377-cd4b-4924-bdda-a091d80c0ba5.jpg",
        "https://files.skg.com/skg/2023-03-24/e8f4e689-b557-4258-92b5-0c899d9848d7.jpg",
        "https://files.skg.com/skg/2023-03-24/0870808a-7238-4886-baff-19b5db807969.jpg",
        "https://files.skg.com/skg/2023-03-24/80d724f6-1501-4081-8c78-21f1cf91cf66.jpg",
        "https://files.skg.com/skg/2023-03-24/be740889-cd9b-44c4-a12c-b415d03c28e2.jpg",
        "https://files.skg.com/skg/2023-03-24/0d1cb77e-b872-4a96-b608-9a3fb5de1435.jpg",
        "https://files.skg.com/skg/2023-03-24/8ce51950-1aa3-42b8-99d1-f59ed564a4f6.jpg",
        "https://files.skg.com/skg/2023-03-24/c558b07e-0c15-4174-be98-448ce4b15967.jpg",
        "https://files.skg.com/skg/2023-03-24/29451bed-eb9b-4654-abe2-139147b9f567.jpg",
        "https://files.skg.com/skg/2023-03-24/18a76c97-582a-47e9-a445-f5a1a8f798ba.jpg",
        "https://files.skg.com/skg/2023-03-24/da7ec6b3-6de8-4cea-8786-9fb47c3b1052.jpg",
        "https://files.skg.com/skg/2023-03-24/b40895ee-90d3-45fc-9e4c-75c0708e7f9a.jpg",
        "https://files.skg.com/skg/2023-03-24/aacf0f8e-79b6-4946-8195-bf4457687f44.jpg",
        "https://files.skg.com/skg/2023-03-24/850f1cf9-ef01-4ff2-ab4b-f5a5fb5c5748.jpg"
    ],
    newK5Ultra: [
        "https://files.skg.com/skg/2023-03-24/0d8d04e9-b8b7-4320-8073-c7bcd4e5fbfb.jpg",
        "https://files.skg.com/skg/2023-03-24/c19a8435-9b26-4eb7-b05d-27a2605175f3.jpg",
        "https://files.skg.com/skg/2023-03-24/ef59b025-2de1-40d9-a699-9db44f7725f5.jpg",
        "https://files.skg.com/skg/2023-03-24/2da47f21-9ad2-4600-959f-2d5d16e5ac4f.gif",
        "https://files.skg.com/skg/2023-03-24/a74ce88a-b526-4e47-92a1-cd100ef86a15.gif",
        "https://files.skg.com/skg/2023-03-24/e389b0c1-46f6-489f-aae7-8b594015bbc1.gif",
        "https://files.skg.com/skg/2023-03-24/d084139f-a1ae-4ad2-b2d8-71456c9aa9b8.gif",
        "https://files.skg.com/skg/2023-03-24/a1937323-fafc-4470-a5e8-b9d17d9cae38.jpg",
        "https://files.skg.com/skg/2023-03-24/bfefeabc-8f1b-4997-b37f-bf640f45f63c.jpg",
        "https://files.skg.com/skg/2023-03-24/88c101a4-f4e6-4b84-9f7c-1852d1c7dc69.gif",
        "https://files.skg.com/skg/2023-03-24/1ffbefec-aad3-4573-a5a4-68776347035e.jpg",
        "https://files.skg.com/skg/2023-03-24/bef3059e-d65a-4024-b890-742801e716bd.gif",
        "https://files.skg.com/skg/2023-03-24/aef183d8-3e20-4290-9022-6b5b70575c5c.jpg",
        "https://files.skg.com/skg/2023-03-24/1264ef05-64be-4b3f-9fa4-79b52395ac85.gif",
        "https://files.skg.com/skg/2023-03-24/db240ce2-0bcf-4a5d-a847-fb9922283495.jpg",
        "https://files.skg.com/skg/2023-03-24/a1035dfd-1f0b-43a0-9c29-3a92ecd7dd4d.gif",
        "https://files.skg.com/skg/2023-03-24/1552b959-fd30-4197-aab9-399c71c5b99a.gif",
        "https://files.skg.com/skg/2023-03-24/bc8ca61f-6473-4215-81c7-f921ffd5231a.jpg",
        "https://files.skg.com/skg/2023-03-24/f1d41233-1044-44bc-9b68-7c73f923e754.jpg",
        "https://files.skg.com/skg/2023-03-24/df24418b-d14f-4732-8d38-b55481d03315.jpg",
        "https://files.skg.com/skg/2023-03-24/bdd44d0d-fdd2-4262-a678-efb17fe49c11.gif",
        "https://files.skg.com/skg/2023-03-24/9d6a39a5-3bf6-435a-8086-4664d7cb1353.gif",
        "https://files.skg.com/skg/2023-03-24/476717e6-1d7d-480a-b9ac-9011aa8efb7c.jpg",
        "https://files.skg.com/skg/2023-03-24/67c5e27e-2c95-4477-9825-df694e1c93bd.gif",
        "https://files.skg.com/skg/2023-03-24/17b6a0f7-d250-4506-973e-16b3dd0d04b7.jpg",
        "https://files.skg.com/skg/2023-03-24/08f55d79-8ec1-4dc6-98cf-c5e42025f25c.gif",
        "https://files.skg.com/skg/2023-03-24/8f012160-8c82-47e7-a006-3eae89fad346.gif",
        "https://files.skg.com/skg/2023-03-24/409f1df9-83cc-4565-a01a-aecb7785e626.jpg",
        "https://files.skg.com/skg/2023-03-24/f192ab18-ce03-4e46-9240-d4036b44fee9.jpg",
        "https://files.skg.com/skg/2023-03-24/210d866c-b90f-4ad5-8061-74403a94fac6.jpg",
        "https://files.skg.com/skg/2023-03-24/e1cdf97f-9ce4-4a94-9080-fc7fe6090f78.jpg"
    ],
    W7Ultra:[
        "https://files.skg.com/skg/2023-03-24/738ce991-0430-42f7-919a-eac8d6afdf7a.jpg",
        "https://files.skg.com/skg/2023-03-24/4cacd278-4876-4fa2-b849-431bb9c5e9e8.jpg",
        "https://files.skg.com/skg/2023-03-24/e28a6c02-0310-4258-887e-0469b97eee4d.jpg",
        "https://files.skg.com/skg/2023-03-24/15ded174-b921-4833-8724-e8201dfc43a4.gif",
        "https://files.skg.com/skg/2023-03-24/57ef1270-9c1d-4654-943a-da6b345bbf03.gif",
        "https://files.skg.com/skg/2023-03-24/9b57b0e2-fe07-46b2-b768-8fc21d762ea0.gif",
        "https://files.skg.com/skg/2023-03-24/7d171139-9414-43c6-9018-863ac9db424a.gif",
        "https://files.skg.com/skg/2023-03-24/a0c1ba1b-144e-4d4d-b84d-f4382d6361ff.jpg",
        "https://files.skg.com/skg/2023-03-24/27741d23-d32e-44e4-a104-f005680b5547.gif",
        "https://files.skg.com/skg/2023-03-24/d435feed-13c0-48cd-a01a-c4a3745658b1.jpg",
        "https://files.skg.com/skg/2023-03-24/546a3583-f4fb-46be-ad96-ea326ffb90d7.jpg",
        "https://files.skg.com/skg/2023-03-24/f7669feb-a9b5-406c-9a21-f0ef00335d28.gif",
        "https://files.skg.com/skg/2023-03-24/9770b193-0cf9-4471-a32c-639ab5d1f933.jpg",
        "https://files.skg.com/skg/2023-03-24/89c0d432-6233-4b77-b456-420f558090b3.gif",
        "https://files.skg.com/skg/2023-03-24/bf344ef4-a955-4213-b7e6-ab543d7331c9.jpg",
        "https://files.skg.com/skg/2023-03-24/4d154b67-9918-4930-92e5-f372368f6abc.gif",
        "https://files.skg.com/skg/2023-03-24/57794752-9386-42cb-a4c1-d5b000f80ab1.jpg",
        "https://files.skg.com/skg/2023-03-24/9fc26e03-87f1-437c-8758-dea34cfa4d7e.gif",
        "https://files.skg.com/skg/2023-03-24/163f65cb-7897-4154-a655-39ab6b370f0a.gif",
        "https://files.skg.com/skg/2023-03-24/4aaa20cc-b7c7-4029-954b-cd2413313e05.jpg",
        "https://files.skg.com/skg/2023-03-24/c95e3636-2fcb-4e3a-a5d4-27e26c82fd99.jpg",
        "https://files.skg.com/skg/2023-03-24/e1b90ded-b045-4ca7-b916-372c1903a569.jpg",
        "https://files.skg.com/skg/2023-03-24/527356a7-0012-4e31-a403-1278303b2f9b.jpg",
        "https://files.skg.com/skg/2023-03-24/baa2211c-ddeb-4d74-b113-915b0818c713.jpg",
        "https://files.skg.com/skg/2023-03-24/645933cc-4bf3-4030-989b-59ac376b60dd.jpg",
        "https://files.skg.com/skg/2023-03-24/b9f58552-8dbb-43a8-9e53-01a6a872ad86.jpg",
        "https://files.skg.com/skg/2023-03-24/97d63d58-0f44-44d7-8957-f0f429795911.jpg",
        "https://files.skg.com/skg/2023-03-24/2f61d0bf-486d-48f0-a807-1f5127cbbc40.jpg"
    ],
    V9C: [
        "https://files.skg.com/skg/2023-04-27/7db27948-d8e1-4f2e-877c-a3b1849ee686.jpg",
        "https://files.skg.com/skg/2023-04-27/ef3fe8dc-56b5-4543-b19a-2836159f6d5f.jpg",
        "https://files.skg.com/skg/2023-04-27/f9ccbb49-ff28-4540-bf2c-d7d7cc3a693d.jpg",
        "https://files.skg.com/skg/2023-04-27/3a8240f1-6da8-4ebc-945f-3a7a3f6af59c.jpg",
        "https://files.skg.com/skg/2023-04-27/cbfddca2-1cc5-4fa5-9b93-ca741114829b.jpg",
        "https://files.skg.com/skg/2023-04-27/8f06b55c-becb-49c5-a921-e86ed3b6a9ea.jpg",
        "https://files.skg.com/skg/2023-04-27/c86524ea-6707-4f3c-a2a2-35b527e1b50f.jpg",
        "https://files.skg.com/skg/2023-04-27/11471ea1-39cf-4592-8fce-fb12f5d97a73.jpg",
        "https://files.skg.com/skg/2023-04-27/0287ee9c-dad5-4884-a805-9c4607949232.jpg",
        "https://files.skg.com/skg/2023-04-27/09c661cc-0f9a-4bcf-b125-8878a546bb19.jpg",
        "https://files.skg.com/skg/2023-04-27/9e6b90dd-083d-4e5f-a79b-857c70537c84.jpg",
        "https://files.skg.com/skg/2023-04-27/4d3cfd79-a9b7-44a1-a3ef-d556a8c04332.jpg",
        "https://files.skg.com/skg/2023-04-27/29f87269-b6d4-4f02-8426-ba875360f53a.jpg",
        "https://files.skg.com/skg/2023-04-27/e5dda97b-f375-474e-9dc6-908ff08bb3cf.jpg",
        "https://files.skg.com/skg/2023-04-27/4b239ae5-75cc-4340-aa8b-83225ae8df3a.jpg",
        "https://files.skg.com/skg/2023-04-27/e8fbcdb5-be73-4298-9da6-7f9d4d1fa003.jpg",
        "https://files.skg.com/skg/2023-04-27/d4f74fbf-cda2-4c3f-935b-e4ac86e5a0fb.jpg",
        "https://files.skg.com/skg/2023-04-27/2b0b6a2c-91de-4905-93de-07584b9cf982.jpg",
        "https://files.skg.com/skg/2023-04-27/14e12ac7-15f7-4026-8cb1-af64e5cb94d1.jpg",
        "https://files.skg.com/skg/2023-04-27/03fccba4-c12d-403a-a12b-ca6c3d31a575.jpg"
    ],
    G7waist: [
      "https://files.skg.com/skg/2023-12-20/560cc217-52de-42a2-a64e-3d0ec538f958.jpg",
      "https://files.skg.com/skg/2023-12-20/515876cf-4d59-4135-9540-45b924288de2.gif",
      "https://files.skg.com/skg/2023-12-20/ec27a5af-fb7c-4534-94df-10668c894634.gif",
      "https://files.skg.com/skg/2023-12-20/33b739c3-8d8a-477c-aa60-2d0c6c8609c4.gif",
      "https://files.skg.com/skg/2023-12-20/88249e9d-df32-4853-b8e6-3ac056f73474.jpg",
      "https://files.skg.com/skg/2023-12-20/fc38a6c6-d5db-4661-9723-ace18975e878.gif",
      "https://files.skg.com/skg/2023-12-20/f9bf4230-9f3f-4e26-a342-1ffd22306dab.jpg",
      "https://files.skg.com/skg/2023-12-20/bac0816c-cc96-4af4-a33b-f5fb5d0991fe.gif",
      "https://files.skg.com/skg/2023-12-20/a97c3314-8937-4f20-b632-ea65ceff6223.jpg",
      "https://files.skg.com/skg/2023-12-20/1c466a1d-3c9b-4c0d-8d15-ef19b575a527.gif",
      "https://files.skg.com/skg/2023-12-20/e97728c8-9c5a-4ecb-82dd-8e8d31f20488.jpg",
      "https://files.skg.com/skg/2023-12-20/f7d2c1fb-ba2a-4edb-81e8-4e745f3949e4.gif",
      "https://files.skg.com/skg/2023-12-20/88ceac03-22e2-46d6-afad-a7e8b920a855.jpg",
      "https://files.skg.com/skg/2023-12-20/afbaa07c-15da-4699-87f1-a1fbbf69180c.gif",
      "https://files.skg.com/skg/2023-12-20/7194d6d2-4bf3-4f31-b7e0-25e99afd4eda.jpg",
      "https://files.skg.com/skg/2023-12-20/28849b5e-2075-49af-8ac4-e68c12341ca5.gif",
      "https://files.skg.com/skg/2023-12-20/2562ea58-5297-4e0b-ae51-7194c7ea9ce7.jpg",
      "https://files.skg.com/skg/2023-12-20/0ce96832-80cd-4cd2-a8e9-453f78564a5a.gif",
      "https://files.skg.com/skg/2023-12-20/d376b9c9-73d0-4e1a-8713-e35c9488c9b1.gif",
      "https://files.skg.com/skg/2023-12-20/bf5baf42-7ac5-4822-a05e-f0ddead163a0.jpg",
      "https://files.skg.com/skg/2023-12-20/cccaaaee-59ae-4008-b57a-35f2a92ee86a.gif",
      "https://files.skg.com/skg/2023-12-20/43e554ac-f369-4559-b111-a03aeb36ec4c.jpg",
      "https://files.skg.com/skg/2023-12-20/8ea3f0d3-9771-44ea-b7d4-0e127e509216.jpg",
      "https://files.skg.com/skg/2023-12-20/e8f56f41-ba5c-4d8c-bbbd-96fd16e02081.jpg",
      "https://files.skg.com/skg/2023-12-20/46ace417-7f3f-446a-b862-8ca9f0e5b5a0.jpg",
      "https://files.skg.com/skg/2023-12-20/d4148dc7-7739-4334-9658-8cebaa2bad36.jpg",
      "https://files.skg.com/skg/2023-12-20/f6a50c87-1386-48bb-b6a5-336bb273afb2.jpg",
      "https://files.skg.com/skg/2023-12-20/0e378194-881e-4d25-b337-1126ef0d42c2.jpg"
    ],
    H3: [
        "https://files.skg.com/skg/2024-03-14/614123ea-f8c5-4305-8786-f2172a69dcfd.jpg",
        "https://files.skg.com/skg/2024-03-14/a6ffd62d-3fa4-4cba-b595-a60f0100d579.jpg",
        "https://files.skg.com/skg/2024-03-14/9862c70b-e150-4d54-bbc3-f7aefe39cdc5.gif",
        "https://files.skg.com/skg/2024-03-14/eaa8fa5a-9d31-4d1b-8fe9-ea71e7900b17.gif",
        "https://files.skg.com/skg/2024-03-14/ba059133-4b17-47a1-a9ae-2b9467340f0f.gif",
        "https://files.skg.com/skg/2024-03-14/8cfc7417-baca-4637-904a-8f7f4dc4eeba.jpg",
        "https://files.skg.com/skg/2024-03-14/7f9686e6-f5e4-496c-86f5-365f59d73171.jpg",
        "https://files.skg.com/skg/2024-03-14/39e70271-91c2-4254-8e9e-27b2826ee37f.gif",
        "https://files.skg.com/skg/2024-03-14/0156c336-35c5-4bfe-bafe-e6d1ce0fe713.gif",
        "https://files.skg.com/skg/2024-03-14/2f39e185-3f68-40bd-aa7b-a981f1564f99.gif",
        "https://files.skg.com/skg/2024-03-14/eff73413-9dac-4057-b2d5-f0e3e507020e.jpg",
        "https://files.skg.com/skg/2024-03-14/271e2b3b-0ee9-482e-bfc1-2323b18bdf09.gif",
        "https://files.skg.com/skg/2024-03-14/f6d8f43c-1a9c-4424-bd6f-bc28183c2693.jpg",
        "https://files.skg.com/skg/2024-03-14/e8a190d0-fe33-44ee-8abd-b157c670159d.gif",
        "https://files.skg.com/skg/2024-03-14/833c278c-03e0-4e11-839e-1f1cf4a2ed13.jpg",
        "https://files.skg.com/skg/2024-03-14/c213d128-1851-447f-8a40-350192bdc578.jpg",
        "https://files.skg.com/skg/2024-03-14/43a18a4b-539c-45ce-b592-22a7f35e100e.jpg",
        "https://files.skg.com/skg/2024-03-14/2e2d7df2-efc5-482f-916b-b46023c28d53.jpg",
        "https://files.skg.com/skg/2024-03-14/bdef0fa7-492d-4be3-b90d-bdf1f51e2603.jpg",
        "https://files.skg.com/skg/2024-03-14/a05864cd-5217-4900-aee1-3497b23d9446.gif",
        "https://files.skg.com/skg/2024-03-14/00f7d1ea-66a3-4cbb-949d-417572b1722c.jpg",
        "https://files.skg.com/skg/2024-03-14/3c49ecbe-8069-4876-bace-a0015af9354d.jpg",
        "https://files.skg.com/skg/2024-03-14/fcbc71d2-70e7-43fd-9c3e-30a7767a00b0.jpg",
        "https://files.skg.com/skg/2024-03-14/d0ea5e34-4a57-4c0b-bd7f-bbd23044590a.jpg",
        "https://files.skg.com/skg/2024-03-14/b6bed0a3-6b71-4292-9b7e-6cd506f0e8fe.jpg",
        "https://files.skg.com/skg/2024-03-14/7e5970d7-9759-4a50-b9bf-2e7b84a6ef23.jpg",
        "https://files.skg.com/skg/2024-03-14/bdf2d8a9-0dd6-4ad4-9d62-170669512951.jpg"
    ],
    H5: [
        "https://files.skg.com/skg/2024-03-20/e36413ca-9177-4686-9950-e4d821231727.jpg",
        "https://files.skg.com/skg/2024-03-20/a9fabaad-ea17-4a58-8d84-046ba405f58b.jpg",
        "https://files.skg.com/skg/2024-03-20/2d1d5f8a-831a-4291-a842-210c30215f1f.jpg",
        
        "https://files.skg.com/skg/2024-03-20/c38d38b0-c6db-4428-a21b-18a6e85339ec.jpg",
        "https://files.skg.com/skg/2024-03-20/04c092d9-6a90-4266-a1b0-8b63b67646f3.jpg",
        "https://files.skg.com/skg/2024-03-20/05ebee7c-f7a0-4634-b79b-2e7ff115c433.jpg",
        "https://files.skg.com/skg/2024-03-20/5d5643d4-b1ac-443d-912b-9e409efab506.jpg",
        "https://files.skg.com/skg/2024-03-20/ae03b8ff-3b29-46ec-8606-3091f734c06d.jpg",
        "https://files.skg.com/skg/2024-03-20/b83165b2-eb49-4b01-8d72-0cf2b36f42a5.jpg",
        "https://files.skg.com/skg/2024-03-20/77ff1842-d5d1-4d68-9280-1b52c6e181f8.jpg",
        "https://files.skg.com/skg/2024-03-20/c4027247-c77a-435c-9c2c-5bbecde4d1b7.jpg",
        "https://files.skg.com/skg/2024-03-20/13f5e965-4046-43b7-9a4b-408de7a85288.jpg"
    ],
    H52PRO: [
        "https://files.skg.com/skg/2024-11-15/d5fa4460-21f1-4363-ad26-a86e0f4431c3.jpg",
        "https://files.skg.com/skg/2024-05-22/ace5ccf2-9454-466b-8787-63f43254472d.jpg",
        "https://files.skg.com/skg/2024-05-22/d78b7a0f-d3d9-4acd-bf7a-51ab74a99f97.jpg",
        "https://files.skg.com/skg/2024-05-22/68bb8e30-168a-4567-aa0c-62e162ff67aa.jpg",
        "https://files.skg.com/skg/2024-05-22/4d3c0cb1-ddc7-41f5-b591-166c8e03ce75.jpg",
        "https://files.skg.com/skg/2024-05-22/8bb1f82f-f8e2-4b54-a47c-2ef5b4e93e67.jpg",
        "https://files.skg.com/skg/2024-05-22/1bb9cdf2-c1ad-49ed-9fcc-05e8c815987d.jpg",
        "https://files.skg.com/skg/2024-05-22/f5183d70-885a-45d3-a1c3-a21187f6d77f.jpg",
        "https://files.skg.com/skg/2024-05-22/76bfaf62-eed6-49f3-adc3-c4f3dca1965c.jpg",
        "https://files.skg.com/skg/2024-05-22/8c0a06d0-5789-497f-922b-76d16c31c417.jpg",
        "https://files.skg.com/skg/2024-05-22/96bdaebc-15f0-4ade-b4b6-c7937ac7e0d7.jpg",
        "https://files.skg.com/skg/2024-05-22/eccc98db-ea43-45f7-bf3b-39143c41cca7.jpg",
        "https://files.skg.com/skg/2024-05-22/25ca7f44-4d46-4bab-afe3-873187fbbec2.jpg",
        "https://files.skg.com/skg/2024-05-22/363e295f-c9c9-4f80-ae4a-842900db24c9.jpg"
    ],
    H5PRO: [
        "https://files.skg.com/skg/2024-04-15/eacbb6bb-6bbc-4b5d-8e4b-58e9289f8079.jpg",
        "https://files.skg.com/skg/2024-04-15/36f0bed6-b84f-47fe-ac2f-e9e6f1702821.jpg",
        "https://files.skg.com/skg/2024-04-15/9daa9240-9578-463a-910c-ac49a1496b19.gif",
        "https://files.skg.com/skg/2024-04-15/06335ff9-a88f-4721-be2d-fe384f2ab4fa.gif",
        "https://files.skg.com/skg/2024-04-15/168ac604-d999-4508-843b-bfa092b9b913.gif",
        "https://files.skg.com/skg/2024-04-15/3474effa-d845-4d55-bd5d-30f544ac916f.gif",
        "https://files.skg.com/skg/2024-04-15/3c2b6675-c5cc-4683-9a51-1a2e409a7be5.jpg",
        "https://files.skg.com/skg/2024-04-15/004120f3-0e2c-4c00-8fc5-b6c6d161b284.jpg",
        "https://files.skg.com/skg/2024-04-15/e22564cf-40a2-45ab-b175-e6460313738b.gif",
        "https://files.skg.com/skg/2024-04-15/5d903ebd-3e3e-4ac6-ac0f-cd9d430f12a8.jpg",
        "https://files.skg.com/skg/2024-04-15/e17d0ebd-51a2-411d-91a8-e67b4b9ec11d.gif",
        "https://files.skg.com/skg/2024-04-15/54a46592-92c1-45e2-91a5-71069f14a844.jpg",
        "https://files.skg.com/skg/2024-04-15/e0ba1437-9e34-4f6d-ba46-8ddcd86596f6.gif",
        "https://files.skg.com/skg/2024-04-15/5a8b528a-64f4-48ad-9975-a72bb9b9a35f.jpg",
        "https://files.skg.com/skg/2024-04-15/7fd11cb0-2bd5-48b1-80e5-235557894285.gif",
        "https://files.skg.com/skg/2024-04-15/060dfbc8-44fa-4c84-80e3-d0c4a6d13e6b.jpg",
        "https://files.skg.com/skg/2024-04-15/ea2f8c0d-de94-4c01-babc-ee287b81bb01.gif",
        "https://files.skg.com/skg/2024-04-15/75d7ec7c-cc16-4848-9956-dc9dd29781e9.gif",
        "https://files.skg.com/skg/2024-04-15/3126481d-e833-473e-8e1e-9213e0eac05b.jpg",
        "https://files.skg.com/skg/2024-04-15/019ada13-9538-48c2-8e42-ecb46f007677.gif",
        "https://files.skg.com/skg/2024-04-15/da55a9e1-3156-4b7b-a092-bcd837d7c594.jpg",
        "https://files.skg.com/skg/2024-04-15/87d79ca1-b807-42af-854f-3b22bef2b1d3.gif",
        "https://files.skg.com/skg/2024-04-15/8dad153e-9f88-40f7-8413-fecbb1e53344.jpg",
        "https://files.skg.com/skg/2024-04-15/1f826c8b-5c68-4293-aa8d-d1bf95b1ea94.jpg",
        "https://files.skg.com/skg/2024-04-15/f8d0678d-dbf8-4886-991a-763d13a956f7.gif",
        "https://files.skg.com/skg/2024-04-15/11aeccc0-325a-4b3f-8f7d-23255339fe17.jpg",
        "https://files.skg.com/skg/2024-04-15/f5c42e63-56f4-4b20-8b61-c0a9bbf93f76.gif",
        "https://files.skg.com/skg/2024-04-15/c18f2c8d-c967-4c47-8f46-3f1e1d9592f5.jpg",
        "https://files.skg.com/skg/2024-04-15/63e416d6-8a89-40e6-bbab-1b69e8b33c5c.jpg",
        "https://files.skg.com/skg/2024-04-15/6a250241-4a0f-4c44-83ab-e7b636fd9473.jpg",
        "https://files.skg.com/skg/2024-04-15/768a7e0b-950b-4f31-92d2-f927aae98833.jpg",
        "https://files.skg.com/skg/2024-04-15/a77bd0da-8231-4897-879b-5337deea4487.jpg",
        "https://files.skg.com/skg/2024-04-15/672d946d-c1f6-4abd-9aea-0f156909203e.jpg",
        "https://files.skg.com/skg/2024-04-15/b564a033-64cf-4bc3-980b-95757aa522d2.jpg",
        "https://files.skg.com/skg/2024-04-15/b20627ea-1d5e-442d-935a-9acf1ff44f07.jpg"
    ],
    K53: [
        "https://files.skg.com/skg/2024-04-15/9634aaf0-58b8-499e-bbc7-1a896268d622.png",
        "https://files.skg.com/skg/2024-04-15/3c961e7d-936e-451e-9c5c-a2678e32d391.jpg",
        "https://files.skg.com/skg/2024-04-15/45af5378-f3f1-43b4-b6a2-6bae0ef85893.jpg",
        "https://files.skg.com/skg/2024-04-15/a7fb9435-f710-415c-ba76-360c2119aa8e.jpg",
        "https://files.skg.com/skg/2024-04-15/efc6d17d-957a-4089-a707-659c2aeea817.jpg",
        "https://files.skg.com/skg/2024-04-15/abf50f4b-5c11-45a1-8fea-68379ff6693d.jpg",
        "https://files.skg.com/skg/2024-04-15/0fda9558-1089-4274-842f-bd3463e16647.jpg",
        "https://files.skg.com/skg/2024-04-15/aa06a1be-d77d-4005-9f2c-46e4efa7c575.jpg",
        "https://files.skg.com/skg/2024-04-15/44a04427-8755-41c9-8215-2866edbb967b.jpg",
        "https://files.skg.com/skg/2024-04-15/3775a896-001b-4042-9344-03a8b2da7b1e.jpg",
        "https://files.skg.com/skg/2024-04-15/6557aec3-2b56-4361-b569-ef87d551f1c6.jpg",
        "https://files.skg.com/skg/2024-04-15/b46ac633-8dc0-427f-abe5-e1932314dc82.jpg",
        "https://files.skg.com/skg/2024-04-15/29065644-7121-4ad8-8c60-3d10b407fb45.jpg",
        "https://files.skg.com/skg/2024-04-15/bd6624e4-ec37-4e35-8612-66556dbe2940.jpg"
    ],
    V9PRO: [
        "https://files.skg.com/skg/2024-04-16/ed34578b-736a-404d-8ec5-83a7d0c1a3fb.jpg",
        "https://files.skg.com/skg/2024-04-16/25cc1de7-1e17-46b7-a45e-4aac0a58eb6c.jpg",
        "https://files.skg.com/skg/2024-04-16/94403e7f-201c-4843-b669-c4a37fe3fe47.jpg",
        "https://files.skg.com/skg/2024-04-16/9f65a190-b3ad-4d68-af4b-0beee62f283d.jpg",
        "https://files.skg.com/skg/2024-04-16/677bd634-22ab-486c-958d-cba700ce55fd.jpg",
        "https://files.skg.com/skg/2024-04-16/93c658db-abd2-49af-be9c-c6460ae9fc63.jpg",
        "https://files.skg.com/skg/2024-04-16/3cfe68c9-c7de-4cf0-bbfb-34a836b0e1cb.jpg",
        "https://files.skg.com/skg/2024-04-16/377b25da-8568-44c6-b5c9-e12cf0028bff.jpg",
        "https://files.skg.com/skg/2024-04-16/f8643e8f-ed3c-493c-afea-cc451e6c9b11.jpg",
        "https://files.skg.com/skg/2024-04-16/ca36155a-cffc-49ba-ad14-832c7d0a64c8.jpg",
        "https://files.skg.com/skg/2024-04-16/fdf42729-7355-4160-80f1-cd3d6d3bbdea.jpg",
        "https://files.skg.com/skg/2024-04-16/122e80ec-2aae-49e9-9aab-346668b0964f.jpg",
        "https://files.skg.com/skg/2024-04-16/5a10994e-d041-46c2-9f19-84c84e27585a.jpg",
        "https://files.skg.com/skg/2024-04-16/56d955a7-1385-41e2-859b-7d181f44439c.jpg",
        "https://files.skg.com/skg/2024-04-16/b2444ff0-c98b-4d94-8f7d-58108dc4537b.jpg"
    ],
    S7: [
        "https://files.skg.com/skg/2024-11-29/d30e261f-7912-466c-9a1c-3b0eb1098435.jpg",
        "https://files.skg.com/skg/2024-11-29/1a9ee765-d61b-403c-94b6-19e74bd20e50.jpg",
        "https://files.skg.com/skg/2024-11-29/e9335cd7-51e1-469b-879b-12cd7f9cb7d7.jpg",
        "https://files.skg.com/skg/2024-11-29/ebbec755-3429-485b-b575-7e1b9801894d.jpg",
        "https://files.skg.com/skg/2024-11-29/59d4f1dd-8a3d-471c-b978-d744fc7b2373.jpg",
        "https://files.skg.com/skg/2024-11-29/647f6aad-8397-453f-8ff7-352ec535f8fc.jpg",
        "https://files.skg.com/skg/2024-11-29/3b12eb1b-d52c-418e-a511-9dae36489564.jpg",
        "https://files.skg.com/skg/2024-11-29/e43c4740-78ff-4532-94b7-fd15018c8a61.jpg",
        "https://files.skg.com/skg/2024-11-29/450dc153-cbff-47e5-a41d-fdad3b1ecded.jpg",
        "https://files.skg.com/skg/2024-11-29/e53797e8-0952-4993-8e4b-1e85f8498f43.jpg",
        "https://files.skg.com/skg/2024-11-29/07aaef69-06e5-47c7-94de-11173dc338e4.jpg",
        "https://files.skg.com/skg/2024-11-29/81b5bcbd-ff13-468c-b752-12a6a516c33a.jpg",
        "https://files.skg.com/skg/2024-11-29/82628918-479f-4fc5-8abe-1540f9da45e7.jpg",
        "https://files.skg.com/skg/2024-11-29/d8d2e039-854a-4aad-b6ea-627f9f328ad8.jpg",
        "https://files.skg.com/skg/2024-11-29/4c42c264-9d7c-4180-b9e7-700ec1a9775f.jpg",
        "https://files.skg.com/skg/2024-11-29/8c6a471f-f013-4f42-a29f-2d7d591dfbec.jpg",
        "https://files.skg.com/skg/2024-11-29/dd4b686e-e72d-4bea-92fc-66bb83aa6bb2.jpg",
        "https://files.skg.com/skg/2024-11-29/6d528dc3-2154-462b-b0ae-e6d070fa64cb.jpg",
        "https://files.skg.com/skg/2024-11-29/e5c6a243-3184-487d-a19b-0f1f28ba8f54.jpg",
        "https://files.skg.com/skg/2024-11-29/eb223a0a-c60b-4d6d-9700-23ace05cb659.jpg",
        "https://files.skg.com/skg/2024-11-29/17f48fc1-aa2f-4b18-b053-b8a33e145759.jpg",
        "https://files.skg.com/skg/2024-11-29/ad53f326-d8e5-40c7-92c4-ad34c5563b16.jpg",
        "https://files.skg.com/skg/2024-11-29/0e6a7029-6a7e-4382-a8b4-decf9ea1eeae.jpg"
    ],
    V72: [
        "https://files.skg.com/skg/2024-04-16/588b63d9-9955-4340-910e-e86fe2f60b45.jpg",
        "https://files.skg.com/skg/2024-04-16/afbc7060-d443-4a9f-b9e5-d7ec334b22fb.jpg",
        "https://files.skg.com/skg/2024-04-16/5050cc14-6c8e-4b5a-81c9-638417c6ca8a.jpg",
        "https://files.skg.com/skg/2024-04-16/1dc2e68f-c50e-4200-802e-97a942c53525.jpg",
        "https://files.skg.com/skg/2024-04-16/daaeeeab-a580-4741-bc6b-6a02e84d505b.gif",
        "https://files.skg.com/skg/2024-04-16/ccfa097f-9d9e-4a26-9e75-a0393bf10a53.jpg",
        "https://files.skg.com/skg/2024-04-16/64461755-1379-42e3-af6f-486d5f030551.jpg",
        "https://files.skg.com/skg/2024-04-16/440380d1-038a-429c-8623-cfbef9b037d0.gif",
        "https://files.skg.com/skg/2024-04-16/a91cac27-35ff-48cf-b276-164eba988dd6.jpg",
        "https://files.skg.com/skg/2024-04-16/bdb7de97-f4d1-4c22-a41d-7db835ba659b.jpg",
        "https://files.skg.com/skg/2024-04-16/1a54332e-af05-43a3-96db-3bd199f3e8c3.gif",
        "https://files.skg.com/skg/2024-04-16/20803976-65c9-46d9-acc8-953dff57ef29.jpg",
        "https://files.skg.com/skg/2024-04-16/c18516ec-b8fa-433c-96ee-810e5d014483.jpg",
        "https://files.skg.com/skg/2024-04-16/bdd08b0e-d557-4363-9948-9c037ef07dfe.jpg",
        "https://files.skg.com/skg/2024-04-16/a82ebbc7-c868-4cf9-88cb-110fda722494.jpg",
        "https://files.skg.com/skg/2024-04-16/6dce1d2c-2637-4385-a5a1-49d0bd1daeda.jpg",
        "https://files.skg.com/skg/2024-04-16/8afca212-6c30-46ab-844c-4147f1012259.jpg",
        "https://files.skg.com/skg/2024-04-16/124d48ec-d79b-409d-a87e-6df730728285.jpg",
        "https://files.skg.com/skg/2024-04-16/952a73b2-1f7c-4c37-81dc-bfd0c49202ee.jpg",
        "https://files.skg.com/skg/2024-04-16/9ece4ac6-5fac-4aff-9b2b-45013e982093.jpg",
        "https://files.skg.com/skg/2024-04-16/dec7d366-1b27-4c0f-b715-f615e6998235.jpg",
        "https://files.skg.com/skg/2024-04-16/f6ab66b5-2fab-4499-ac7a-a69784dcf3c4.jpg",
        "https://files.skg.com/skg/2024-04-16/df33479c-fdf0-4676-9073-bd2b87bb19e0.jpg",
        "https://files.skg.com/skg/2024-04-16/6884422f-2178-41a2-9373-ded2a7e0b4d2.jpg"
    ],
    E32: [
        "https://files.skg.com/skg/2024-04-16/f9c3ec30-9f9f-43df-927d-963b229804e4.png",
        "https://files.skg.com/skg/2024-04-16/fb0989db-02bf-41d7-a78d-21a970646f99.png",
        "https://files.skg.com/skg/2024-04-16/ed862bdf-c7f6-4061-9ca0-bf05f7eebf49.png",
        "https://files.skg.com/skg/2024-04-16/9f427cf3-afe0-406a-8eed-9ba95063092f.png",
        "https://files.skg.com/skg/2024-04-16/a4effcf9-7071-4f07-a070-8f894784c736.png",
        "https://files.skg.com/skg/2024-04-16/fb790ad1-b3bf-41e7-9c9e-d2cade470568.png",
        "https://files.skg.com/skg/2024-04-16/1a8dcc9e-51be-41cb-9b2b-a8de34d9fb00.png",
        "https://files.skg.com/skg/2024-04-16/54ffb169-5579-4e98-8b3f-34def7f1fd77.png",
        "https://files.skg.com/skg/2024-04-16/c9906c12-2d63-4195-8fca-687c85bea951.png",
        "https://files.skg.com/skg/2024-04-16/9bd2a28f-ccde-4e1c-9096-b0d9bd494180.png",
        "https://files.skg.com/skg/2024-04-16/fd1df2ef-874d-4121-98eb-5b0ae93d43b0.png"
    ],
    W32: [
        "https://files.skg.com/skg/2024-04-16/e5200a3e-9feb-4c0b-979f-cf42a41f5980.jpg",
        "https://files.skg.com/skg/2024-04-16/36c933a6-6644-4feb-a93d-e6cd66279ec9.jpg",
        "https://files.skg.com/skg/2024-04-16/7e33f780-34c5-4a3a-a720-865239926739.jpg",
        "https://files.skg.com/skg/2024-04-16/91c5bb29-9461-4ad6-ae63-206b86700406.jpg",
        "https://files.skg.com/skg/2024-04-16/d5ec4d3e-4887-40a7-86cb-405fbc3e28f5.jpg",
        "https://files.skg.com/skg/2024-04-16/540eb7cd-bae2-4f0c-af72-b916f9730820.jpg",
        "https://files.skg.com/skg/2024-04-16/ba76202b-4e30-439d-89db-6dbffd791de0.jpg",
        "https://files.skg.com/skg/2024-04-16/6be74373-07df-42e1-8f6e-0d4e098f72fa.jpg",
        "https://files.skg.com/skg/2024-04-16/e2c3ef78-b642-48d4-bff9-0b592ab71610.jpg",
        "https://files.skg.com/skg/2024-04-16/d6450521-1712-4013-8d28-7f511dfcba6b.jpg",
        "https://files.skg.com/skg/2024-04-16/e30c62d1-f304-4779-9dd7-d6a432969789.jpg",
        "https://files.skg.com/skg/2024-04-16/588d568f-b803-4e1e-8c27-973cdd4e47b4.jpg"
    ],
    F32: [
        "https://files.skg.com/skg/2024-04-16/9a07058f-0a55-435f-a293-50d94925dd90.jpg",
        "https://files.skg.com/skg/2024-04-16/194782ea-6b6d-4be5-accd-3f42bcb1a7c1.jpg",
        "https://files.skg.com/skg/2024-04-16/ff13386f-dca4-404b-b580-09b3d96480fb.gif",
        "https://files.skg.com/skg/2024-04-16/b0fd98dd-3e90-4c54-bb9d-fd09102068f9.jpg",
        "https://files.skg.com/skg/2024-04-16/8845130f-e534-4202-8de9-e246ad534d88.gif",
        "https://files.skg.com/skg/2024-04-16/bea2b275-df93-4cb8-9895-80e978b4746b.gif",
        "https://files.skg.com/skg/2024-04-16/b7c8120e-5dba-4c89-8c4f-1106fcb685ee.jpg",
        "https://files.skg.com/skg/2024-04-16/a1e9c5d5-a32c-46a5-96a0-b9592122b630.jpg",
        "https://files.skg.com/skg/2024-04-16/9afa72ba-753c-459e-abfd-a9a9c36af84d.jpg",
        "https://files.skg.com/skg/2024-04-16/6740d96a-0cb8-436b-9475-01846a4c60da.jpg",
        "https://files.skg.com/skg/2024-04-16/a3fc8e5b-c14e-41c5-9297-5220b901c1f8.jpg",
        "https://files.skg.com/skg/2024-04-16/33711d48-b963-42e3-a417-f0f5a653f544.jpg",
        "https://files.skg.com/skg/2024-04-16/f09d253f-f493-4a9f-a355-ebfdfe2bcc08.jpg",
        "https://files.skg.com/skg/2024-04-16/423978a2-5b81-4b68-80e5-f7f8de58a227.jpg",
        "https://files.skg.com/skg/2024-04-16/19faaac9-f6dc-4a33-86c7-a9036ee47c4e.jpg",
        "https://files.skg.com/skg/2024-04-16/c4743453-1b40-4b4a-a328-c929569f7b9c.gif",
        "https://files.skg.com/skg/2024-04-16/0f2f7484-6d55-4b43-a7d0-f8ede107f606.jpg",
        "https://files.skg.com/skg/2024-04-16/7162b858-059c-4119-863f-6ba0779a2629.gif",
        "https://files.skg.com/skg/2024-04-16/d406b465-20df-4fb7-ae06-361b1da0d78a.jpg",
        "https://files.skg.com/skg/2024-04-16/95bff63e-33fc-4d3b-b093-c11348abbab4.jpg",
        "https://files.skg.com/skg/2024-04-16/fd065cc2-2139-449b-a4fa-ab3af3d0686a.jpg",
        "https://files.skg.com/skg/2024-04-16/fc8962a6-2a7b-4159-b954-a862031dcd2a.jpg",
        "https://files.skg.com/skg/2024-04-16/2093a279-b824-4c7f-8f49-58fb6b853a0c.jpg",
        "https://files.skg.com/skg/2024-04-16/3afe3cf9-5e5e-4668-9ae9-4115b664a14f.jpg",
        "https://files.skg.com/skg/2024-04-16/914dbc5e-7bb1-4d67-9d48-ccec0ace3b36.jpg",
        "https://files.skg.com/skg/2024-04-16/86be2f9f-4d29-497c-af64-8362cfbe2ff6.gif",
        "https://files.skg.com/skg/2024-04-16/1485747b-0efb-481c-9328-a544b7148b5d.jpg",
        "https://files.skg.com/skg/2024-04-16/805d6e5d-760b-44e6-806b-98541838b3ad.jpg",
        "https://files.skg.com/skg/2024-04-16/bf6def64-9ebe-47c9-a59a-f29d2af7cbf5.jpg",
        "https://files.skg.com/skg/2024-04-16/4dcdccfd-e509-4b58-8141-8663f68efd1e.jpg"
    ],
    K52W: [
        "https://files.skg.com/skg/2024-04-23/a2c2f59b-e1a8-4d7f-8258-72c85f020f22.jpg",
        "https://files.skg.com/skg/2024-04-23/713f3084-d132-42ac-b610-f87d44d01581.png",
        "https://files.skg.com/skg/2024-04-23/bfd9c4af-698d-4710-916d-525786197db4.gif",
        "https://files.skg.com/skg/2024-04-23/20c50788-ff31-4b01-9c46-fe667d496cca.gif",
        "https://files.skg.com/skg/2024-04-23/a26751bb-149c-4472-a998-b3f4f6c4b198.jpg",
        "https://files.skg.com/skg/2024-04-23/31b080a6-83c0-4cda-8c96-91930a5a6ef9.jpg",
        "https://files.skg.com/skg/2024-04-23/72752c9f-3451-4b98-a8dd-41e6b1afede4.gif",
        "https://files.skg.com/skg/2024-04-23/e4923c84-d62a-4026-a0dc-b309a2b44466.jpg",
        "https://files.skg.com/skg/2024-04-23/9563dcef-cd23-4f29-9e1b-77d7e16fa7b0.gif",
        "https://files.skg.com/skg/2024-04-23/238ec0bf-1e11-44a3-bf22-9de59f4dfcb0.jpg",
        "https://files.skg.com/skg/2024-04-23/e3767c84-a5d6-401e-8072-903acd45c25b.gif",
        "https://files.skg.com/skg/2024-04-23/1eb1a8e8-b24d-45d2-940d-905d01c6b178.gif",
        "https://files.skg.com/skg/2024-04-23/4af2edd3-4f7f-4afb-8b9b-818d92395913.jpg",
        "https://files.skg.com/skg/2024-04-23/0f3451fb-c218-45b0-994d-d195075ba5b0.jpg",
        "https://files.skg.com/skg/2024-04-23/94e3672c-db7e-4a4b-a0fe-b22d3bd8a4f3.gif",
        "https://files.skg.com/skg/2024-04-23/05a5d862-f22d-44a7-82dc-6690d2c14c2d.gif",
        "https://files.skg.com/skg/2024-04-23/62b459bf-c49a-4a32-9bea-894f4c9b7b81.jpg",
        "https://files.skg.com/skg/2024-04-23/91c01349-d682-4caa-be94-2744df595641.jpg",
        "https://files.skg.com/skg/2024-04-23/3cc362dd-1b9b-41e3-bee5-00075fd68c42.jpg",
        "https://files.skg.com/skg/2024-04-23/e09e7e48-fb94-411f-8b48-32e3336f1e24.gif",
        "https://files.skg.com/skg/2024-04-23/5916de82-fe4b-4979-9d59-481503a9b6d1.jpg",
        "https://files.skg.com/skg/2024-04-23/68eb3430-9830-4d16-8b51-115d8bd44f84.gif",
        "https://files.skg.com/skg/2024-04-23/ea50bd9f-3fbe-4cc2-a299-720dc6fcca7d.jpg",
        "https://files.skg.com/skg/2024-04-23/32032b60-da27-44c3-982d-d43fa5ae9c83.jpg",
        "https://files.skg.com/skg/2024-04-23/a74c28db-9aac-4f39-a5fc-6a517646498f.jpg",
        "https://files.skg.com/skg/2024-04-23/5515d4e1-a14d-4ea7-a7f4-d37c25479420.jpg",
        "https://files.skg.com/skg/2024-04-23/26eb05d9-9980-4c89-ab4a-89a4ef466691.jpg",
        "https://files.skg.com/skg/2024-04-23/9da2bd65-cb66-4f0c-9399-095e7b622908.jpg"
    ],
    productDetail: {
      G7waist: [
        {
          name:　'G7',
          title: 'G7简介_作用与功效_参数_常见问题-SKG官网',
          keywords: 'SKG按摩腰带G7,按摩腰带,SKG按摩腰带,腰部按摩仪,腰部按摩器,SKG腰部按摩仪,SKG腰部按摩器',
          description: 'SKG按摩腰带G7,是一款轻松上手的智能腰部按摩腰带,栏目提供SKG这款按摩腰带使用方法,产品参数,图片,品牌等信息.买按摩腰带,就认准SKG品牌'
        }
      ],
      k6: [
        {
          name: 'K6炫彩浅蓝',
          title: '颈椎按摩仪SKG K6炫彩浅蓝简介_K6炫彩浅蓝原理_参数_常见问题_评价-SKG官网',
          keywords: 'SKG K6炫彩浅蓝,SKG颈椎按摩仪K6炫彩浅蓝,颈椎按摩仪,颈椎按摩器,智能脖子颈部按摩仪,脖子按摩器,颈部按摩仪',
          description: '颈椎按摩仪，颈椎按摩器K6炫彩浅蓝简介_K6炫彩浅蓝原理,参数,常见问题,评价。SKG作为时尚的颈椎按摩仪品牌，集研发、生产、销售、培训为一体，主要经营颈椎按摩仪,护颈仪,颈部按摩器,颈椎按摩器等。'
        },
        {
          name: 'K6炫彩浅粉',
          title: '颈椎按摩仪SKG K6炫彩浅粉简介_K6炫彩浅粉原理_参数_常见问题_评价-SKG官网',
          keywords: 'SKG K6炫彩浅粉,SKG颈椎按摩仪K6炫彩浅粉,颈椎按摩仪,颈椎按摩器,智能脖子颈部按摩仪,脖子按摩器,颈部按摩仪',
          description: '颈椎按摩仪，颈椎按摩器K6炫彩浅粉简介_K6炫彩浅粉原理,参数,常见问题,评价。SKG作为时尚的颈椎按摩仪品牌，集研发、生产、销售、培训为一体，主要经营颈椎按摩仪,护颈仪,颈部按摩器,颈椎按摩器等。'
        },
        {
          name: 'K6炫彩墨绿',
          title: '颈椎按摩仪SKG K6炫彩墨绿简介_K6炫彩墨绿原理_参数_常见问题_评价-SKG官网',
          keywords: 'SKG K6炫彩墨绿,SKG颈椎按摩仪K6炫彩墨绿,颈椎按摩仪,颈椎按摩器,智能脖子颈部按摩仪,脖子按摩器,颈部按摩仪',
          description: '颈椎按摩仪，颈椎按摩器K6炫彩墨绿简介_K6炫彩墨绿原理,参数,常见问题,评价。SKG作为时尚的颈椎按摩仪品牌，集研发、生产、销售、培训为一体，主要经营颈椎按摩仪,护颈仪,颈部按摩器,颈椎按摩器等。'
        },
        {
          name: 'K6炫彩蓝',
          title: '颈椎按摩仪SKG K6炫彩蓝简介_K6炫彩蓝原理_参数_常见问题_评价-SKG官网',
          keywords: 'SKG K6炫彩蓝,SKG颈椎按摩仪K6炫彩蓝,颈椎按摩仪,颈椎按摩器,智能脖子颈部按摩仪,脖子按摩器,颈部按摩仪',
          description: '颈椎按摩仪，颈椎按摩器K6炫彩蓝简介_K6炫彩蓝原理,参数,常见问题,评价。SKG作为时尚的颈椎按摩仪品牌，集研发、生产、销售、培训为一体，主要经营颈椎按摩仪,护颈仪,颈部按摩器,颈椎按摩器等。'
        },
        {
          name: 'K6炫彩浅银',
          title: '颈椎按摩仪SKG K6炫彩浅银简介_K6炫彩浅银原理_参数_常见问题_评价-SKG官网',
          keywords: 'SKG K6炫彩浅银,SKG颈椎按摩仪K6炫彩浅银,颈椎按摩仪,颈椎按摩器,智能脖子颈部按摩仪,脖子按摩器,颈部按摩仪',
          description: '颈椎按摩仪，颈椎按摩器颈椎按摩仪，颈椎按摩器K6炫彩浅银简介_k6原理,参数,常见问题,评价。SKG作为时尚的颈椎按摩仪品牌，集研发、生产、销售、培训为一体，主要经营颈椎按摩仪,护颈仪,颈部按摩器,颈椎按摩器等。'
        }
      ],
      newk5: [
        {
          name: '全新K5',
          title: '【SKG颈椎按摩仪K5】颈椎按摩器SKG K5简介_原理_参数_常见问题-SKG官网',
          keywords: 'SKG K5,SKG颈椎按摩仪K5,颈椎按摩仪,颈椎按摩器,智能脖子颈部按摩仪,脖子按摩器,颈部按摩仪',
          description: 'SKG颈椎按摩仪K6,简介_原理_参数_常见问题,椎按摩器SKG K6随时随地呵护颈椎健康!SKG主要经营颈椎按摩仪,护颈仪,颈部按摩器,颈椎按摩器等,'
        }
      ],
      k5ZG: [
        {
          name: 'k5尊贵',
          title: '颈椎按摩仪SKG k5尊贵简介_k5尊贵原理_参数_常见问题_评价-SKG官网',
          keywords: 'SKG k5尊贵,SKG颈椎按摩仪k5尊贵,颈椎按摩仪,颈椎按摩器,智能脖子颈部按摩仪,脖子按摩器,颈部按摩仪',
          description: '颈椎按摩仪，颈椎按摩器k5尊贵简介_k5尊贵原理,参数,常见问题,评价。SKG作为时尚的颈椎按摩仪品牌，集研发、生产、销售、培训为一体，主要经营颈椎按摩仪,护颈仪,颈部按摩器,颈椎按摩器等。'
        }
      ],
      k5Classic: [
        {
          name: '4098经典',
          title: '颈椎按摩仪SKG 4098经典简介_4098经典原理_参数_常见问题_评价-SKG官网',
          keywords: 'SKG 4098经典,SKG颈椎按摩仪4098经典,颈椎按摩仪,颈椎按摩器,智能脖子颈部按摩仪,脖子按摩器,颈部按摩仪',
          description: '颈椎按摩仪，颈椎按摩器4098经典简介_4098经典原理,参数,常见问题,评价。SKG作为时尚的颈椎按摩仪品牌，集研发、生产、销售、培训为一体，主要经营颈椎按摩仪,护颈仪,颈部按摩器,颈椎按摩器等。'
        }
      ],
      k5BLE: [
        {
          name: '4098蓝牙',
          title: '颈椎按摩仪SKG 4098蓝牙简介_4098蓝牙原理_参数_常见问题_评价-SKG官网',
          keywords: 'SKG 4098蓝牙,SKG颈椎按摩仪4098蓝牙,颈椎按摩仪,颈椎按摩器,智能脖子颈部按摩仪,脖子按摩器,颈部按摩仪',
          description: '颈椎按摩仪，颈椎按摩器4098蓝牙简介_4098蓝牙原理,参数,常见问题,评价。SKG作为时尚的颈椎按摩仪品牌，集研发、生产、销售、培训为一体，主要经营颈椎按摩仪,护颈仪,颈部按摩器,颈椎按摩器等。'
        }
      ],
      newK4: [
        {
          name: '全新K4',
          title: '颈椎按摩仪SKG 全新K4简介_全新K4原理_参数_常见问题_评价-SKG官网',
          keywords: 'SKG 全新K4,SKG颈椎按摩仪全新K4,颈椎按摩仪,颈椎按摩器,智能脖子颈部按摩仪,脖子按摩器,颈部按摩仪',
          description: '颈椎按摩仪，颈椎按摩器全新K4简介_全新K4原理,参数,常见问题,评价。SKG作为时尚的颈椎按摩仪品牌，集研发、生产、销售、培训为一体，主要经营颈椎按摩仪,护颈仪,颈部按摩器,颈椎按摩器等。'
        }
      ],
      k4: [
        {
          name: 'k4',
          title: '颈椎按摩仪SKG k4简介_k4原理_参数_常见问题_评价-SKG官网',
          keywords: 'SKG k4,SKG颈椎按摩仪k4,颈椎按摩仪,颈椎按摩器,智能脖子颈部按摩仪,脖子按摩器,颈部按摩仪',
          description: '颈椎按摩仪，颈椎按摩器k4简介_k4原理,参数,常见问题,评价。SKG作为时尚的颈椎按摩仪品牌，集研发、生产、销售、培训为一体，主要经营颈椎按摩仪,护颈仪,颈部按摩器,颈椎按摩器等。'
        }
      ],
      4588: [
        {
          name: '4588',
          title: 'SKG 4588简介_4588原理_参数_常见问题_评价-SKG官网',
          keywords: 'SKG 4588,SKG颈椎按摩仪4588,颈椎按摩仪,颈椎按摩器,智能脖子颈部按摩仪,脖子按摩器,颈部按摩仪',
          description: '4588简介_4588原理,参数,常见问题,评价。SKG作为时尚的颈椎按摩仪品牌，集研发、生产、销售、培训为一体，主要经营颈椎按摩仪,护颈仪,颈部按摩器,颈椎按摩器等。'
        }
      ],
      k3_2: [
        {
          name: 'k3浅灰',
          title: '颈椎按摩仪SKG k3浅灰简介_k3浅灰原理_参数_常见问题_评价-SKG官网',
          keywords: 'SKG k3浅灰,SKG颈椎按摩仪k3浅灰,颈椎按摩仪,颈椎按摩器,智能脖子颈部按摩仪,脖子按摩器,颈部按摩仪',
          description: '颈椎按摩仪，颈椎按摩器k3浅灰简介_k3浅灰原理,参数,常见问题,评价。SKG作为时尚的颈椎按摩仪品牌，集研发、生产、销售、培训为一体，主要经营颈椎按摩仪,护颈仪,颈部按摩器,颈椎按摩器等。'
        }
      ],
      k3_3: [
        {
          name: 'k3象牙白',
          title: '颈椎按摩仪SKG k3象牙白简介_k3象牙白原理_参数_常见问题_评价-SKG官网',
          keywords: 'SKG k3象牙白,SKG颈椎按摩仪k3象牙白,颈椎按摩仪,颈椎按摩器,智能脖子颈部按摩仪,脖子按摩器,颈部按摩仪',
          description: '颈椎按摩仪，颈椎按摩器k3象牙白简介_k3象牙白原理,参数,常见问题,评价。SKG作为时尚的颈椎按摩仪品牌，集研发、生产、销售、培训为一体，主要经营颈椎按摩仪,护颈仪,颈部按摩器,颈椎按摩器等。'
        }
      ],
      dz_1: [
        {
          name: 'K6(娜小古粉色定制款)',
          title: '颈椎按摩仪SKG K6(娜小古粉色定制款)简介_K6(娜小古粉色定制款)原理_参数_常见问题_评价-SKG官网',
          keywords: 'SKG K6(娜小古粉色定制款),SKG颈椎按摩仪K6(娜小古粉色定制款),颈椎按摩仪,颈椎按摩器,智能脖子颈部按摩仪,脖子按摩器,颈部按摩仪',
          description: '颈椎按摩仪，颈椎按摩器K6(娜小古粉色定制款)简介_K6(娜小古粉色定制款)原理,参数,常见问题,评价。SKG作为时尚的颈椎按摩仪品牌，集研发、生产、销售、培训为一体，主要经营颈椎按摩仪,护颈仪,颈部按摩器,颈椎按摩器等。'
        }
      ],
      dz_2: [
        {
          name: 'K6(娜小古白色定制款)',
          title: '颈椎按摩仪SKG K6(娜小古白色定制款)简介_K6(娜小古白色定制款)原理_参数_常见问题_评价-SKG官网',
          keywords: 'SKG K6(娜小古白色定制款),SKG颈椎按摩仪K6(娜小古白色定制款),颈椎按摩仪,颈椎按摩器,智能脖子颈部按摩仪,脖子按摩器,颈部按摩仪',
          description: '颈椎按摩仪，颈椎按摩器K6(娜小古白色定制款)简介_K6(娜小古白色定制款)原理,参数,常见问题,评价。SKG作为时尚的颈椎按摩仪品牌，集研发、生产、销售、培训为一体，主要经营颈椎按摩仪,护颈仪,颈部按摩器,颈椎按摩器等。'
        }
      ],
      dz_3: [
        {
          name: 'K6(施华洛世奇水晶限量款)',
          title: '颈椎按摩仪SKG K6(施华洛世奇水晶限量款)简介_K6(施华洛世奇水晶限量款)原理_参数_常见问题_评价-SKG官网',
          keywords: 'SKG K6(施华洛世奇水晶限量款),SKG颈椎按摩仪K6(施华洛世奇水晶限量款),颈椎按摩仪,颈椎按摩器,智能脖子颈部按摩仪,脖子按摩器,颈部按摩仪',
          description: '颈椎按摩仪，颈椎按摩器K6(施华洛世奇水晶限量款)简介_K6(施华洛世奇水晶限量款)原理,参数,常见问题,评价。SKG作为时尚的颈椎按摩仪品牌，集研发、生产、销售、培训为一体，主要经营颈椎按摩仪,护颈仪,颈部按摩器,颈椎按摩器等。'
        }
      ],
      dz_4: [
        {
          name: 'K6(娜小古红色定制款)',
          title: '颈椎按摩仪SKG K6(娜小古红色定制款)简介_K6(娜小古红色定制款)原理_参数_常见问题_评价-SKG官网',
          keywords: 'SKG K6(娜小古红色定制款),SKG颈椎按摩仪K6(娜小古红色定制款),颈椎按摩仪,颈椎按摩器,智能脖子颈部按摩仪,脖子按摩器,颈部按摩仪',
          description: '颈椎按摩仪，颈椎按摩器K6(娜小古红色定制款)简介_K6(娜小古红色定制款)原理,参数,常见问题,评价。SKG作为时尚的颈椎按摩仪品牌，集研发、生产、销售、培训为一体，主要经营颈椎按摩仪,护颈仪,颈部按摩器,颈椎按摩器等。'
        }
      ],
      F3: [
        {
          name: 'F3筋膜枪',
          title: '【SKG筋膜枪F3】筋膜枪SKG F3简介_原理_参数_常见问题-SKG官网',
          keywords: '筋膜枪,SKG筋膜枪,SKG筋膜枪F3,SKG F3',
          description: 'SKG筋膜枪F3,众多明星都在用,无人机电机驱动,准确控制合适力度,四档变频,随时随地都能用,深度放松按摩用SKG F3筋膜枪!'
        }
      ],
      E3: [
        {
          name: 'E3',
          title: '【SKG眼部按摩仪E3】眼部按摩器简介_作用与功效_参数_常见问题-SKG官网',
          keywords: '眼部按摩仪,眼部按摩器,SKG眼部按摩仪E3',
          description: 'SKG眼部按摩仪E3,6个智能气压,多频震动按摩,5大模式,超大热敷面积,亲肤材质,蓝牙音乐,好舒服带着都能睡,SKG眼部按摩仪E3和眼部不适说bye bye.'
        }
      ],
      E3PRO: [
        {
          name: 'E3PRO',
          title: '【SKG眼部按摩仪E3PRO】眼部按摩器简介_作用与功效_参数_常见问题-SKG官网',
          keywords: '眼部按摩仪,眼部按摩器,SKG眼部按摩仪E3PRO',
          description: 'SKG眼部按摩仪E3PRO,6个智能气压,多频震动按摩,5大模式,超大热敷面积,亲肤材质,蓝牙音乐,好舒服带着都能睡,SKG眼部按摩仪E3和眼部不适说bye bye.'
        }
      ],
      4301: [
        {
          name: '4301',
          title: '【SKG眼部按摩仪4301】眼部按摩器简介_作用与功效_参数_常见问题-SKG官网',
          keywords: '眼部按摩仪,眼部按摩器,SKG眼部按摩仪4301',
          description: 'SKG眼部按摩仪4301,达人点赞,循环揉捏,柔滑材质,多档力度,护眼卫士,可拆卸清洗,SKG眼部按摩仪4301只按眼眶一圈,不压眼球按得更舒服,'
        }
      ],
      G7: [
        {
          name: 'G7',
          title: '【SKG颈椎按摩仪G7】颈椎按摩器SKG G7简介_原理_参数_常见问题-SKG官网',
          keywords: 'SKG G7,SKG颈椎按摩仪G7,颈椎按摩仪,颈椎按摩器,智能脖子颈部按摩仪,脖子按摩器,颈部按摩仪',
          description: 'SKG颈椎按摩仪G7,新一代G7系列,热灸推揉颈椎按摩仪,9D推揉按摩,红光聚能热灸,再一次重新定义颈椎按摩仪,颈椎按摩器SKG G7让脖子加倍舒爽!'
        }
      ],
      G7PRO: [
        {
          name: 'G7PRO',
          title: '【SKG颈椎按摩仪G7Pro】颈椎按摩器SKG G7Pro简介_原理_参数_常见问题-SKG官网',
          keywords: 'SKG G7Pro,SKG颈椎按摩仪G7Pro,颈椎按摩仪,颈椎按摩器,智能脖子颈部按摩仪,脖子按摩器,颈部按摩仪',
          description: 'SKG颈椎按摩仪G7Pro,脉冲加推揉,全能按摩手,9D推揉按摩,TENS+EMS双脉冲,红光聚能热灸,大面积热敷,缓解颈椎酸痛,远离亚健康,颈椎按摩器SKG G7Pro值得拥有!'
        }
      ],
      G72: [
        {
          name: 'G72',
          title: '【SKG颈椎按摩仪G7二代豪华款】颈椎按摩器SKG G7二代豪华款简介_原理_参数_常见问题-SKG官网',
          keywords: 'SKG G7二代豪华款,SKG颈椎按摩仪G7二代豪华款,颈椎按摩仪,颈椎按摩器,智能脖子颈部按摩仪,脖子按摩器,颈部按摩仪',
          description: 'SKG颈椎按摩仪G7二代豪华款,脉冲加推揉,全能按摩手,9D推揉按摩,TENS+EMS双脉冲,红光聚能热灸,大面积热敷,缓解颈椎酸痛,远离亚健康,颈椎按摩器SKG G7二代豪华款值得拥有!'
        }
      ],
      F5: [
        {
          name: 'F5',
          title: '【SKG筋膜枪F5】筋膜枪SKG F5简介_原理_参数_常见问题-SKG官网',
          keywords: '筋膜枪,SKG筋膜枪,SKG筋膜枪F5,SKG F5',
          description: 'SKG筋膜枪F5,一机多用,适合全身按摩的掌上筋膜枪,科技感十足,久持不累手,SKG F5筋膜枪随时随地哪里酸痛按哪里!'
        }
      ],
      F32: [
        {
          name: 'F32',
          title: '【SKG筋膜枪F3二代】筋膜枪SKG F3二代简介_原理_参数_常见问题-SKG官网',
          keywords: '筋膜枪,SKG筋膜枪,SKG筋膜枪F5,SKG F3二代',
          description: 'SKG筋膜枪F3二代,一机多用,适合全身按摩的掌上筋膜枪,科技感十足,久持不累手,SKG F3二代筋膜枪随时随地哪里酸痛按哪里!'
        }
      ],
      E4: [
        {
          name: 'E4',
          title: '【SKG眼部按摩仪E4】眼部按摩器简介_作用与功效_参数_常见问题-SKG官网',
          keywords: '眼部按摩仪,眼部按摩器,SKG眼部按摩仪E4',
          description: 'SKG眼部按摩仪E4,创新震撼按摩技术,自适应音频共振,可视化设计,语音操作提醒,眼睛按摩轻松,就常用SKG眼部按摩仪E4'
        }
      ],
      W5: [
        {
          name: 'W5',
          title: '【SKG按摩腰带W5】腰部按摩仪器简介_作用及功效_参数_常见问题_评价-SKG官网',
          keywords: 'SKG按摩腰带W5,按摩腰带,SKG按摩腰带,腰部按摩仪,腰部按摩器,SKG腰部按摩仪,SKG腰部按摩器',
          description: 'SKG按摩腰带W5,是一款轻松上手的智能腰部按摩腰带,栏目提供SKG这款按摩腰带使用方法,产品参数,图片,品牌等信息.买按摩腰带,就认准SKG品牌,'
        }
      ],
      K5mini: [
        {
          name: "K5mini",
          title: '【SKG颈椎按摩仪K5mini】颈椎按摩器SKG K5mini简介_原理_参数_常见问题-SKG官网',
          keywords: 'SKG 5mini,SKG颈椎按摩仪5mini,颈椎按摩仪,颈椎按摩器,智能脖子颈部按摩仪,脖子按摩器,颈部按摩仪',
          description: 'SKG颈椎按摩仪K5mini,简介_原理_参数_常见问题,椎按摩器SKG K5mini更大面积热敷更轻巧!SKG主要经营颈椎按摩仪,护颈仪,颈部按摩器,颈椎按摩器等,'
        }
      ],
      BP3: [
        {
          name: "BP3",
          title: '【SKG颈椎按摩BP3】颈椎按摩器SKG BP3简介_原理_参数_常见问题-SKG官网',
          keywords: 'SKGBP3,SKG颈椎按摩BP3,颈椎按摩仪,颈椎按摩器,智能脖子颈部按摩仪,脖子按摩器,颈部按摩仪',
          description: 'SKG颈椎按摩BP3,简介_原理_参数_常见问题,椎按摩器SKGBP3更大面积热敷更轻巧!SKG主要经营颈椎按摩仪,护颈仪,颈部按摩器,颈椎按摩器等,'
        }
      ],
      T5: [
        {
          name:　'T5',
          title: '【SKG肩颈按摩仪T5】挂脖式肩颈按摩器简介_作用与功效_参数_常见问题-SKG官网',
          keywords: '肩颈按摩仪,肩颈按摩器,SKG肩颈按摩仪,SKG肩颈按摩器,SKG肩颈按摩仪T5',
          description: 'SKG肩颈按摩仪T5,中频10倍能量专业按摩,900万次每秒高周波渗透,点穴按摩覆盖肩颈三大酸痛区,无感佩戴轻盈便携,SKG肩颈按摩器T5助你击溃肩颈酸痛!'
        }
      ],
      W5ST: [
        {
          name:　'W5ST',
          title: '【SKG按摩腰带5ST】腰部按摩仪器简介_作用及功效_参数_常见问题_评价-SKG官网',
          keywords: 'SKG按摩腰带W5ST,按摩腰带,SKG按摩腰带,腰部按摩仪,腰部按摩器,SKG腰部按摩仪,SKG腰部按摩器',
          description: 'SKG按摩腰带W5ST,是一款轻松上手的智能腰部按摩腰带,栏目提供SKG这款按摩腰带使用方法,产品参数,图片,品牌等信息.买按摩腰带,就认准SKG品牌,'
        }
      ],
      K5: [
        {
          name:　'K5',
          title: '【SKG按摩腰带K5】腰部按摩仪器简介_作用及功效_参数_常见问题_评价-SKG官网',
          keywords: 'SKG按摩腰带K5,按摩腰带,SKG按摩腰带,腰部按摩仪,腰部按摩器,SKG腰部按摩仪,SKG腰部按摩器',
          description: 'SKG按摩腰带K5,是一款轻松上手的智能腰部按摩腰带,栏目提供SKG这款按摩腰带使用方法,产品参数,图片,品牌等信息.买按摩腰带,就认准SKG品牌,'
        }
      ],
      K52W: [
        {
          name:　'K52W',
          title: '【SKG按摩腰带K5二代】腰部按摩仪器简介_作用及功效_参数_常见问题_评价-SKG官网',
          keywords: 'SKG按摩腰带K5二代,按摩腰带,SKG按摩腰带,腰部按摩仪,腰部按摩器,SKG腰部按摩仪,SKG腰部按摩器',
          description: 'SKG按摩腰带K5二代,是一款轻松上手的智能腰部按摩腰带,栏目提供SKG这款按摩腰带使用方法,产品参数,图片,品牌等信息.买按摩腰带,就认准SKG品牌,'
        }
      ],
      F7: [
        {
          name:　'F7',
          title: '【SKG筋膜枪F7】筋膜枪SKG F7简介_原理_参数_常见问题-SKG官网',
          keywords: '筋膜枪,SKG筋膜枪,SKG筋膜枪F7,SKG F7',
          description: 'SKG筋膜枪F7,新一代运动级更强大更舒爽,全新四大升级更专业的掌上筋膜枪,畅销海外权威媒体推荐,SKG F7筋膜枪帮你守护ta的肌肉健康!'
        }
      ],
      W5TE: [
        {
          name:　'W5TE',
          title: '【SKG按摩腰带W5TE 】腰部按摩仪器简介_作用及功效_参数_常见问题_评价-SKG官网',
          keywords: 'SKG按摩腰带W5TE,按摩腰带,SKG按摩腰带,腰部按摩仪,腰部按摩器,SKG腰部按摩仪,SKG腰部按摩器',
          description: 'SKG按摩腰带W5TE,一款腰痛腰酸一按就舒服的腰部按摩仪器,栏目提供SKG这款按摩腰带作用及功效,产品参数,图片,品牌等信息.买按摩腰带,就认准SKG品牌,'
        }
      ],
      BW3: [
        {
          name:　'BW3',
          title: '【SKG按摩腰带BW3】腰部按摩仪器简介_作用及功效_参数_常见问题_评价-SKG官网',
          keywords: 'SKG按摩腰带BW3,按摩腰带,智能按摩腰带,SKG按摩腰带,腰部按摩仪,腰部按摩器,SKG腰部按摩仪,SKG腰部按摩器',
          description: 'SKG按摩腰带BW3,一款腰部按摩仪器,栏目提供SKG这款按摩腰带作用及功效,产品参数,图片,品牌等信息.SKG按摩腰带 为久坐人士保驾护航,'
        }
      ],
      W7: [
        {
          name:　'W7',
          title: '【SKG按摩腰带W7】腰部按摩仪器简介_作用及功效_参数_常见问题_评价-SKG官网',
          keywords: 'SKG按摩腰带W7,按摩腰带,SKG按摩腰带,腰部按摩仪,腰部按摩器,SKG腰部按摩仪,SKG腰部按摩器',
          description: 'SKG按摩腰带W7,轻薄随身隐形的腰部按摩仪器,中频10倍能量密度,直击深处酸痛源;3挡热敷,快速温暖腰间;约130g轻盈自在,SKG按摩腰带,科技健康礼送自己送爱的人,'
        }
      ],
      X7: [
        {
          name:　'X7',
          title: '【SKG筋膜枪X7】专业级筋膜枪SKG X7简介_原理_参数_常见问题-SKG官网',
          keywords: '筋膜枪,SKG筋膜枪,SKG筋膜枪X7,SKG X7',
          description: 'SKG筋膜枪X7,14mm大冲程直达深层,拉满运动能量;采用大扭矩无刷电机,高速澎湃冲击颈爽全身;5000m㎡热敷理疗,SKG筋膜枪X7,SKG X7筋膜枪专业运动员的选择,你的随身运动康复师!'
        }
      ],
      T5YIBO: [
        {
          name:　'T5YIBO',
          title: '【SKG肩颈按摩仪T5】挂脖式肩颈按摩器简介_作用与功效_参数_常见问题-SKG官网',
          keywords: '肩颈按摩仪,肩颈按摩器,SKG肩颈按摩仪,SKG肩颈按摩器,SKG肩颈按摩仪T5',
          description: 'SKG肩颈按摩仪T5,中频10倍能量专业按摩,900万次每秒高周波渗透,点穴按摩覆盖肩颈三大酸痛区,无感佩戴轻盈便携,SKG肩颈按摩器T5助你击溃肩颈酸痛!'
        }
      ],
      R6: [
        {
          name:　'R6',
          title: '【SKG健康监测手表】R6_血压心电健康手表_老人智能手表_简介_原理_参数-SKG官网',
          keywords: 'SKG健康监测手表,skg健康智能监测手表,KG血压心电图血氧心率监测,血压计,健康手表,老人手表,智能手环,简介,原理,参数',
          description: 'SKG健康监测手表R6,是一款血压心电图血氧心率监测健康监测手表/老人手表/智能手环/血压计,主动心血管风险预警,给到医生报告解读,是我们身边的健康小管家,为您提供此手表的简介,功能,原理,参数等信息,'
        }
      ],
      V9C: [
        {
          name:　'V9C',
          title: '【SKG健康监测手表】V9C_血压心电健康手表_老人智能手表_简介_原理_参数-SKG官网',
          keywords: 'SKG健康监测手表,skg健康智能监测手表,KG血压心电图血氧心率监测,血压计,健康手表,老人手表,智能手环,简介,原理,参数',
          description: 'SKG健康监测手表V9C,是一款血压心电图血氧心率监测健康监测手表/老人手表/智能手环/血压计,主动心血管风险预警,给到医生报告解读,是我们身边的健康小管家,为您提供此手表的简介,功能,原理,参数等信息,'
        }
      ],
      V9PRO: [
        {
          name:　'V9PRO',
          title: '【SKG健康监测手表】V9豪华款_血压心电健康手表_老人智能手表_简介_原理_参数-SKG官网',
          keywords: 'SKG健康监测手表,skg健康智能监测手表,KG血压心电图血氧心率监测,血压计,健康手表,老人手表,智能手环,简介,原理,参数',
          description: 'SKG健康监测手表V9豪华款,是一款血压心电图血氧心率监测健康监测手表/老人手表/智能手环/血压计,主动心血管风险预警,给到医生报告解读,是我们身边的健康小管家,为您提供此手表的简介,功能,原理,参数等信息,'
        }
      ],
      S7: [
        {
          name:　'S7',
          title: '【SKG健康监测手表】S7_血压心电健康手表_老人智能手表_简介_原理_参数-SKG官网',
          keywords: 'SKG健康监测手表,skg健康智能监测手表,KG血压心电图血氧心率监测,血压计,健康手表,老人手表,智能手环,简介,原理,参数',
          description: 'SKG健康监测手表S7,是一款血压心电图血氧心率监测健康监测手表/老人手表/智能手环/血压计,主动心血管风险预警,给到医生报告解读,是我们身边的健康小管家,为您提供此手表的简介,功能,原理,参数等信息,'
        }
      ],
      V72: [
        {
          name:　'V72',
          title: '【SKG健康监测手表】V72_血压心电健康手表_老人智能手表_简介_原理_参数-SKG官网',
          keywords: 'SKG健康监测手表,skg健康智能监测手表,KG血压心电图血氧心率监测,血压计,健康手表,老人手表,智能手环,简介,原理,参数',
          description: 'SKG健康监测手表V72,是一款血压心电图血氧心率监测健康监测手表/老人手表/智能手环/血压计,主动心血管风险预警,给到医生报告解读,是我们身边的健康小管家,为您提供此手表的简介,功能,原理,参数等信息,'
        }
      ],
      P7: [
        {
          name:　'P7',
          title: '【SKG颈椎按摩仪P7】颈椎按摩器SKG P7简介_原理_参数_常见问题-SKG官网',
          keywords: 'SKG P7,颈椎按摩仪,颈椎按摩器,SKG颈椎按摩仪P7,智能脖子颈部按摩仪,脖子按摩器,颈部按摩仪',
          description: 'SKG颈椎按摩仪P7,滚转揉捏颈上推拿,自动位移揉捏,红外偏振光红光热灸,双电机12D按摩,颈椎按摩器SKGP7,颈椎放松好帮手!'
        }
      ],
      N5: [
        {
          name:　'N5',
          title: '【SKG颈椎按摩仪N5】颈椎按摩器SKG N5简介_原理_参数_常见问题-SKG官网',
          keywords: 'SKG N5,颈椎按摩仪,颈椎按摩器,SKG颈椎按摩仪N5,智能脖子颈部按摩仪,脖子按摩器,颈部按摩仪',
          description: 'SKG颈椎按摩仪N5,按摩头梯形排布,环颈支撑舒适热敷,强力双电机,颈椎按摩器SKG N5护颈正反带,按摩面积再UP,酸痛说拜拜!'
        }
      ],
      E4Pro: [
        {
          name:　'E4Pro',
          title: '【SKG眼部按摩仪E4Pro】眼部按摩器简介_作用与功效_参数_常见问题-SKG官网',
          keywords: '眼部按摩仪,眼部按摩器,SKG眼部按摩仪E4Pro',
          description: 'SKG眼部按摩仪E4Pro,16大触电立体震感按摩,升级恒温热敷,四大按摩模式,自适应音频共振,亲肤材质,只能语音提醒,SKG眼部按摩仪E4Pro赶走眼疲劳,给你双眼舒适关怀,'
        }
      ],
      BM3: [
        {
          name:　'BM3',
          title: '【SKG腿膝按摩仪BM3】腿部按摩器BM3_多功能小腿按摩仪-SKG官网',
          keywords: '腿部按摩器,SKG腿膝按摩器,SKG腿膝按摩仪,腿膝按摩器,腿膝按摩仪,SKG腿膝按摩仪BM3',
          description: 'SKG腿膝按摩仪BM3,是一款强劲揉捏解压腿部按摩器,气压揉捏肌肉胖瘦腿可用.此栏目提供SKG BM3腿部按摩仪,简介,原理,参数,常见问题等信息.'
        }
      ],
      W32: [
        {
          name:　'W32',
          title: '【SKG腿膝按摩仪W3二代】腿部按摩器W3二代_多功能小腿按摩仪-SKG官网',
          keywords: '腿部按摩器,SKG腿膝按摩器,SKG腿膝按摩仪,腿膝按摩器,腿膝按摩仪,SKG腿膝按摩仪W3二代',
          description: 'SKG腿膝按摩仪W3二代,是一款强劲揉捏解压腿部按摩器,气压揉捏肌肉胖瘦腿可用.此栏目提供SKG W3二代腿部按摩仪,简介,原理,参数,常见问题等信息.'
        }
      ],
      BK3: [
        {
          name:　'BK3',
          title: '【SKG腿膝按摩仪BK3】全膝穴位热敷膝部按摩器BK3_全自动疏通经络暖腿神器-SKG官网',
          keywords: '膝部按摩器,SKG腿膝按摩器,SKG腿膝按摩仪,腿膝按摩器,腿膝按摩仪,SKG腿膝按摩仪BK3',
          description: 'SKG腿膝按摩仪BK3,是一款全膝穴位热敷膝部按摩器,电加热护膝保暖膝关节,无线轻便,透气保暖.此栏目提供SKG BK3腿膝按摩仪,简介,原理,参数,常见问题等信息.'
        }
      ],
      BC3: [
        {
          name:　'BC3',
          title: '【SKG头部按摩器BC3】头部按摩仪简介_作用与功效_参数_常见问题-SKG官网',
          keywords: '头部按摩器,头部按摩仪,SKG头部按摩器,SKG头部按摩仪,BC3,SKG官网',
          description: 'SKG头部按摩器BC3,一款舒缓养护头皮按摩抓,四爪抓柔舒服上头,深层按摩放松,呵护头皮健康,洗头小能手,头部含有丰富的神经血管和穴位,通过SKG头部按摩器按摩激活头皮,缓解神经,唤醒活力,放松好心情,'
        }
      ],
      E5: [
        {
          name:　'E5',
          title: '【SKG眼部按摩仪E5】眼部按摩器简介_作用与功效_参数_常见问题-SKG官网',
          keywords: '眼部按摩仪,眼部按摩器,SKG眼部按摩仪E5',
          description: 'SKG眼部按摩仪E5,眼部按摩蓝牙音乐穴位热敷按摩仪,空气波按揉护眼有度,穴位按摩缓解眼疲劳,眼袋热敷拯救熊猫眼,音乐助眠,SKG眼部按摩仪E5缓解疲劳得心应手,'
        }
      ],
      E32: [
        {
          name:　'E32',
          title: '【SKG眼部按摩仪E32】眼部按摩器简介_作用与功效_参数_常见问题-SKG官网',
          keywords: '眼部按摩仪,眼部按摩器,SKG眼部按摩仪E32',
          description: 'SKG眼部按摩仪E32,眼部按摩蓝牙音乐穴位热敷按摩仪,空气波按揉护眼有度,穴位按摩缓解眼疲劳,眼袋热敷拯救熊猫眼,音乐助眠,SKG眼部按摩仪E32缓解疲劳得心应手,'
        }
      ],
      T5YK: [
        {
          name:　'T5YK',
          title: '【SKG按摩腰靠T5】背部腰椎按摩靠垫简介_作用与功效_参数_常见问题-SKG官网',
          keywords: 'SKG按摩腰靠T5,按摩腰靠,腰部按摩靠枕,SKG按摩腰带,腰部按摩仪,腰部按摩器,背部腰椎按摩靠垫',
          description: 'SKG按摩腰靠T5,多功能背部腰椎按摩靠垫.按摩护腰背,久坐也不累,四头挤揉推拿深层缓解酸痛,温感热敷暖暖呵护你的腰,腰部肩颈多部位可用,无线便携随时乐享按摩.'
        }
      ],
      W3: [
        {
          name:　'W3',
          title: 'W3简介_作用与功效_参数_常见问题-SKG官网',
          keywords: 'SKG按摩腰带W3,按摩腰带,SKG按摩腰带,腰部按摩仪,腰部按摩器,SKG腰部按摩仪,SKG腰部按摩器',
          description: 'SKG按摩腰带W3,是一款轻松上手的智能腰部按摩腰带,栏目提供SKG这款按摩腰带使用方法,产品参数,图片,品牌等信息.买按摩腰带,就认准SKG品牌'
        }
      ],
      H7: [
        {
          name:　'H7',
          title: '【SKG肩颈按摩仪H7】挂脖式肩颈按摩器简介_作用与功效_参数_常见问题-SKG官网',
          keywords: '肩颈按摩仪,肩颈按摩器,SKG肩颈按摩仪,SKG肩颈按摩器,SKG肩颈按摩仪H7',
          description: 'SKG肩颈按摩仪H7,中频10倍能量专业按摩,900万次每秒高周波渗透,点穴按摩覆盖肩颈三大酸痛区,无感佩戴轻盈便携,SKG肩颈按摩器H7助你击溃肩颈酸痛!'
        }
      ],
      K5EYE: [
        {
          name:　'K5',
          title: 'K5简介_作用与功效_参数_常见问题-SKG官网',
          keywords: '眼部按摩仪,眼部按摩器,SKG眼部按摩仪K5',
          description: 'SKG眼部按摩仪K5,眼部按摩蓝牙音乐穴位热敷按摩仪,空气波按揉护眼有度,穴位按摩缓解眼疲劳,眼袋热敷拯救熊猫眼,音乐助眠,SKG眼部按摩仪K5缓解疲劳得心应手'
        }
      ],
      XMY: [
        {
          name:　'XMY',
          title: '小蛮腰简介_作用与功效_参数_常见问题-SKG官网',
          keywords: 'SKG按摩腰带小蛮腰,按摩腰带,SKG按摩腰带,腰部按摩仪,腰部按摩器,SKG腰部按摩仪,SKG腰部按摩器',
          description: 'SKG按摩腰带小蛮腰,是一款轻松上手的智能腰部按摩腰带,栏目提供SKG这款按摩腰带使用方法,产品参数,图片,品牌等信息.买按摩腰带,就认准SKG品牌'
        }
      ],
      K5Pro: [
        {
          name:　'K5Pro',
          title: '【SKG颈椎按摩仪K5Pro】颈椎按摩器SKG K5Pro简介_原理_参数_常见问题-SKG官网',
          keywords: 'SKG K5Pro,颈椎按摩仪,颈椎按摩器,SKG颈椎按摩仪K5Pro,智能脖子颈部按摩仪,脖子按摩器,颈部按摩仪',
          description: 'SKG颈椎按摩仪K5Pro,SKG颈椎按摩仪K5Pro,智能脖子颈部按摩仪,脖子按摩器,颈部按摩仪!'
        }
      ],
      newK5Ultra: [
        {
          name: '全新K5尊贵款',
          title: '【SKG颈椎按摩仪全新K5尊贵款】颈椎按摩器SKG K5简介_原理_参数_常见问题-SKG官网',
          keywords: 'SKG K5,SKG颈椎按摩仪K5,全新K5尊贵款，颈椎按摩仪,颈椎按摩器,智能脖子颈部按摩仪,脖子按摩器,颈部按摩仪',
          description: 'SKG颈椎按摩仪全新K5尊贵款,简介_原理_参数_常见问题,椎按摩器SKG K6随时随地呵护颈椎健康!SKG主要经营颈椎按摩仪,护颈仪,颈部按摩器,颈椎按摩器等,'
        }
      ],
      W7Ultra: [
        {
          name:　'W7尊贵款',
          title: '【SKG按摩腰带W7尊贵款】腰部按摩仪器简介_作用及功效_参数_常见问题_评价-SKG官网',
          keywords: 'SKG按摩腰带W7尊贵款,按摩腰带,SKG按摩腰带,腰部按摩仪,腰部按摩器,SKG腰部按摩仪,SKG腰部按摩器',
          description: 'SKG按摩腰带W7尊贵款,轻薄随身隐形的腰部按摩仪器,中频10倍能量密度,直击深处酸痛源;3挡热敷,快速温暖腰间;约130g轻盈自在,SKG按摩腰带,科技健康礼送自己送爱的人,'
        }
      ],
      H3: [
        {
          name:　'H3',
          title: '【SKG肩颈按摩仪H3二代豪华款】挂脖式肩颈按摩器简介_作用与功效_参数_常见问题-SKG官网',
          keywords: '肩颈按摩仪,肩颈按摩器,SKG肩颈按摩仪,SKG肩颈按摩器,SKG肩颈按摩仪H3二代豪华款',
          description: 'SKG肩颈按摩仪H3二代豪华款,中频10倍能量专业按摩,900万次每秒高周波渗透,点穴按摩覆盖肩颈三大酸痛区,无感佩戴轻盈便携,SKG肩颈按摩仪H3二代豪华款助你击溃肩颈酸痛!' 
        }
      ],
      K53: [
        {
          name:　'K53',
          title: '【SKG肩颈按摩仪K5三代豪华款】肩颈按摩器简介_作用与功效_参数_常见问题-SKG官网',
          keywords: '肩颈按摩仪,肩颈按摩器,SKG肩颈按摩仪,SKG肩颈按摩器,SKG肩颈按摩仪K5三代豪华款',
          description: '肩颈按摩仪,肩颈按摩器,SKG肩颈按摩仪,SKG肩颈按摩器,SKG肩颈按摩仪K5三代豪华款' 
        }
      ],
      H5: [
        {
          name:　'H5',
          title: '【SKG肩颈按摩仪H5舒享款】挂脖式肩颈按摩器简介_作用与功效_参数_常见问题-SKG官网',
          keywords: '肩颈按摩仪,肩颈按摩器,SKG肩颈按摩仪,SKG肩颈按摩器,SKG肩颈按摩仪H5舒享款',
          description: '肩颈按摩仪,肩颈按摩器,SKG肩颈按摩仪,SKG肩颈按摩器,SKG肩颈按摩仪H5舒享款' 
        }
      ],
      H5PRO: [
        {
          name:　'H5PRO',
          title: '【SKG肩颈按摩仪H5豪华款】挂脖式肩颈按摩器简介_作用与功效_参数_常见问题-SKG官网',
          keywords: '肩颈按摩仪,肩颈按摩器,SKG肩颈按摩仪,SKG肩颈按摩器,SKG肩颈按摩仪H5豪华款',
          description: '肩颈按摩仪,肩颈按摩器,SKG肩颈按摩仪,SKG肩颈按摩器,SKG肩颈按摩仪H5豪华款' 
        }
      ],
      H52PRO: [
        {
          name:　'H52PRO',
          title: '【SKG肩颈按摩仪H5二代豪华款】挂脖式肩颈按摩器简介_作用与功效_参数_常见问题-SKG官网',
          keywords: '肩颈按摩仪,肩颈按摩器,SKG肩颈按摩仪,SKG肩颈按摩器,SKG肩颈按摩仪H5豪华款',
          description: '肩颈按摩仪,肩颈按摩器,SKG肩颈按摩仪,SKG肩颈按摩器,SKG肩颈按摩仪H5豪华款' 
        }
      ],
    }
    }
  },
  methods:{
    getDetail(){
      let that = this
      console.log("this.$route.params.id",this.$route.params.id)
      switch(this.$route.params.id){
        case "4301": this.proKind = this.eyes_4301;break;
        case "BW3": this.proKind = this.BW3;break;
        case "BM3": this.proKind = this.BM3;break;
        case "BK3": this.proKind = this.BK3;break;
        case "BC3": this.proKind = this.BC3;break;
        case "dz_1": this.proKind = this.dz_1;break;  // 下架
        case "dz_2": this.proKind = this.dz_1;break;  // 下架
        case "dz_3": this.proKind = this.k6SLH;break;  // 下架
        case "dz_4": this.proKind = this.dz_1;break;  // 下架
        case "E4Pro": this.proKind = this.E4Pro;break;
        case "E3": this.proKind = this.E3;break;
        case "E3PRO": this.proKind = this.E3PRO;break;
        case "E4": this.proKind = this.E4;break;
        case "E5": this.proKind = this.E5;break;
        case "F3": this.proKind = this.F3;break;
        case "F5": this.proKind = this.F5;break;
        case "F7": this.proKind = this.F7;break;
        case "G7": this.proKind = this.G7;break; 
        case "G7PRO": this.proKind = this.G7PRO;break; 
        case "G72": this.proKind = this.G72;break; 
        case "k6": this.proKind = this.k6;break;
        case "k5Classic": this.proKind = this.k5JD;break;  // 下架
        case "k5BLE": this.proKind = this.k5BL;break;  // 
        case "K5Pro": this.proKind = this.K5Pro;break;  // 下架
        case "K5EYE": this.proKind = this.K5EYE;break;
        case "k5ZG": this.proKind = this.k5ZG;break;  // 下架
        case "K5mini": this.proKind = this.K5mini;break; 
        case "k4": this.proKind = this.k4;break; 
        case "k3_2": this.proKind = this.k3_2;break;
        case "k3_3": this.proKind = this.k3_3;break; 
        case "newK4": this.proKind = this.newK4;break;
        case "newk5": this.proKind = this.newk5;break;
        case "V72": this.proKind = this.V72;break;
        case "E32": this.proKind = this.E32;break;
        case "N5": this.proKind = this.N5;break; 
        case "newK5Ultra": this.proKind = this.newK5Ultra;break;
        case "P7": this.proKind = this.P7;break;
        // case "R6": this.proKind = this.R6;break;   // 下架
        case "W3": this.proKind = this.W3;break;
        case "W5": this.proKind = this.W5;break;  // 重复
        case "W7": this.proKind = this.W7;break; 
        case "V9PRO": this.proKind = this.V9PRO;break; 
        case "W7Ultra": this.proKind = this.W7Ultra;break;
        case "W5ST": this.proKind = this.W5ST;break;
        case "W5TE": this.proKind = this.W5TE;break;
        case "T5YIBO": this.proKind = this.T5YIBO;break;
        case "T5": this.proKind = this.T5;break; 
        case "X7": this.proKind = this.X7;break;
        case "T5YK": this.proKind = this.T5YK;break; 
        case "XMY": this.proKind = this.XMY;break; 
        case "H3": this.proKind = this.H3;break;
        case "H5": this.proKind = this.H5;break;
        case "K53": this.proKind = this.K53;break;
        case "S7": this.proKind = this.S7;break;
        case "F32": this.proKind = this.F32;break;
        case "W32": this.proKind = this.W32;break;
        case "K5": this.proKind = this.K5;break;
        case "H7": this.proKind = this.H7;break;
        case "V9C": this.proKind = this.V9C;break;
        case "K52W": this.proKind = this.K52W;break;
        case "BP3": this.proKind = this.BP3;break;
        case "H5PRO": this.proKind = this.H5PRO;break;
        case "H52PRO": this.proKind = this.H52PRO;break;
        case "G7waist": this.proKind = this.G7waist;break; // 
        default: that.$router.push({name: 'home'}); break;
      }
    }
  },
  mounted(){
    window.scrollTo(0,0)
    this.getDetail();
    let index = this.$route.query && this.$route.query.index || 0
    let productInfo = this.productDetail[this.$route.params.id][index]
    document.title= productInfo&&productInfo.title
    document.querySelector('meta[name="Keywords"]').setAttribute('content', productInfo.description)
    document.querySelector('meta[name="description"]').setAttribute('content', productInfo.keywords)
  }

}
</script>

<style lang="scss" scoped>
.proDetail {
  padding-top: 50px;
  overflow-x: hidden;
  .photo {
    width: 100vw;
    img{
      width: 100%;
      display: block;
    }
  }
}
@media screen and (min-width: 768px) {
  .proDetail {
  padding-top: 60px;
  overflow-x: hidden;
    .photo {
      width: 900px;
      margin: 0 auto;
      box-shadow: 0 0 10px #eee;
    }
    .ads{
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
}
</style>
