<template>
    <div class="about">
        <skgMenu />
        <div class="mission">
         <div class="missionTxt">
           <p>让每个人更年轻健康</p>
           <p>尽享健康科技</p>
          </div>
        </div>
        <div class="aboutVideo">
          <div class="videoBox">
            <video
            src="https://files.skg.com/skg/2023-02-23/b1fc46e5-430c-48ce-9b00-1bd8609915e7.mp4"
            poster="https://files.skg.com/skg/2023-02-23/4730e570-aaa4-4315-abed-0cbda5c65901.png"
            class="Video"
            controls
            id="video"
            ></video>
          </div>
          <p class="aboutStory">品牌介绍</p>
          <div class="descripttion">
            在SKG，我们热衷于健康领域的探索，敢于把别人眼中的“不可能”，变成“可能”。无论是按摩科技、个护健康、健康家居还是健康医疗，SKG秉承让每个人更年轻健康的使命，坚信，每一个生命健康问题都能找到更好的答案。 <br class="brshow"/>
            2007年，我们创立了SKG，并已在新加坡、美国、荷兰、中国开设公司。目前产品已遍布50多个国家和地区，实现了全球的战略布局。作为健康科技行业的引领者，我们以顾客为先、以科技创新为驱动，秉承“敢于不同，只为更好”的品牌基因，以智能化的产品技术重新定义了”健康科技”行业。我们发明了很多全新产品，满足了很多顾客未被满足的需求，打造出很多好看、好用、有用、常用的健康科技产品，至今仍未停下探索让生命更健康的脚步。<br class="brshow"/>
          </div>
        </div>
        <div class="history">
        </div>
        <div class="history history1" v-if="historyShow">
        </div>
        <div class="more" v-show="!historyShow" @click="showHistory">查看更多</div>
        <div class="culture">
          <div class="conetent">
            <div class="item">
              <div class="title">使命</div>
              <div class="desc">致力于用科技力量，</div>
              <div class="desc">为个人和家庭提供健康穿戴、医疗器械产品和服务，</div>
              <div class="desc">让每个人更年轻健康。</div>
            </div>
            <div class="item">
              <div class="title">愿景</div>
              <div class="desc">全球一百个国家每一座城市</div>
              <div class="desc">都能看到SKG产品；</div>
              <div class="desc">是一亿用户喜欢的健康穿戴品牌；</div>
              <div class="desc">成就一万员工精彩人生！</div>
            </div>
          </div>
          <aboutSwiper  class="aboutSwiper"/>
        </div>
        <!-- 移动端 -->
        <div class="aboutInfo" v-for="(item,index) in infoData" :key="index">
          <div class="tit" :style="{background: item.color }">
            <p class="aboutTit">{{item.title}}</p>
            <router-link :to="item.path">
              <p class="knowMore">了解更多</p>
            </router-link>
          </div>
          <img :src="item.imgUrl" alt="" class="inFoImg">
        </div>
        <!-- PC端 -->
        <div class="pcAboutInfo">
          <aboutInfoSwiper />
        </div>
        <div class="joinUs">
          <p class="job">工作机会</p>
          <router-link to="/joinUs">加入我们</router-link>
        </div>
        <Foot />
    </div>
</template>


<script>
import skgMenu from "@/components/Modules/menu/menu.vue"
import Foot from "@/components/Modules/footer/footer.vue"
import aboutSwiper from '@/components/Modules/aboutSwiper/aboutSwiper.vue'
import historySwiper from '@/components/Modules/historySwiper/historySwiper.vue'
import aboutInfoSwiper from '@/components/Modules/aboutSwiper/aboutInfoSwiper.vue'
export default {
  name: "About",
  components: {
    skgMenu,
    Foot,
    historySwiper,
    aboutSwiper,
    aboutInfoSwiper
  },
  data() {
    return {
      title: 'SKG简介_品牌发展历程_企业文化-SKG官网',
      description: 'SKG简介,品牌发展历程,企业文化.未来穿戴健康科技股份有限公司(SKG)是一家专注于可穿戴按摩仪研发,设计,生产的高科技公司.为用户提供高品质的可穿戴按摩仪产品和服务体验,现已拥有全球855项核心专利.',
      Keywords: 'SKG未来穿戴,SKG可穿戴按摩仪,可穿戴按摩仪,未来穿戴健康科技股份有限公司',
      videoAutoPlay: require("@/static/img/autoPlay.png"),
      patent: 400,
      historyShow: false,
      videoBottom: require("@/static/img/videoBottom.png"),
      cultureImg: "https://files.skg.com/skg.com/20200724/6545d6ae9cd4459fba25fa8249bd2932.png",
      infoData: [
        {
          title: "知识产权及专利技术",
          imgUrl: "https://files.skg.com/skg.com/20200724/d2203ff63d39456bb8a703754fd7f3ea.png",
          color: "#42d7c8",
          path: "/patent"
        },
        {
          title: "新闻中心",
          imgUrl:"https://files.skg.com/skg.com/20200724/8739f8c0b9994b5abfedc1d8e99ac8a7.png",
          color: "#fff",
          path: "/news"
        },
        {
          title: "加入我们",
          imgUrl:"https://files.skg.com/skg.com/20200724/c20397e064f442b1884b9bbb1d084e49.png",
          color: "#42d7c8",
          path: "/joinUs"
        },
      ]

    }
  },
  methods: {
    getPatentData(){
      let that = this
      let opt={
          url:`${this.$store.state.testUrl}web/config/getByKey/patent`,
          method:"get"
      }
      this.$http(opt)
      .then(res=>{
        if(res.code==0){
          that.patent= res.value
        }
      })
    },
    showHistory() {
      this.historyShow = true
    }
  },
  created(){
    this.getPatentData()
    document.title= this.title
    document.querySelector('meta[name="Keywords"]').setAttribute('content', this.Keywords)
    document.querySelector('meta[name="description"]').setAttribute('content', this.description)
  }
}
</script>
<style lang="scss" scoped>
.about {
  padding-top: 50px;
  overflow-x: hidden;
  background-color: #F4F7FA;
}
.mission {
  height: 94vw;
  width: 100vw;
  font-size: 28px;
  font-family:  DFPGB_Y9;
  text-align: center;
  background: url("https://files.skg.com/skg.com/20200724/9be415927fc84807a632d411b901ea0b.png");
  background-size: 100% 100%;
  .missionTxt {
    padding-top: 15vw;
    p {
      line-height: 35px;
      margin: 0;
    }
  }
}
.more {
  display: none;
}
.aboutVideo {
  width: 100vw;
  .aboutStory {
    margin-top: 20px;
    color: #000;
    font-family: DFPGB_Y5;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    line-height: 18px;
  }
  .aboutStoryEn {
    font-size: 12px;
    line-height: 12px;
    margin-bottom: 10px;
    color: #F2F2F2;
    text-align: center;
  }
  .videoBox {
    width: 90vw;
    height: 50vw;
    /* background-image: url("@/static/img/TV.png");
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: 0 16px; */
    margin-top: 2vh;
    margin-left: 5vw;
    position: relative;
  }
  .Video {
   width: 90vw;
   height: 50vw;
  }
  #videoAutoPlay {
    width: 20vw;
    height: 20vw;
    position: absolute;
    left: 40%;
    top: 27%;
  }
  .videoBottom {
   width:80vw;
   height: 1vh;
   position: absolute;
   bottom: 0.5vh;
   left: 7vw;
  }
  .videoBottom img {
    width: 95%;
    height: 4vh;
  }
  .descripttion {
    width: 80vw;
    margin: 0 auto;
    height: 30vh;
    padding: 0 15px;
    font-family: DFPGB_Y5;
    font-size: 12px;
    line-height: 20px;
    color: #857171;
    overflow-y: auto;
  }
}
.history {
  width: 100vw;
  height: 244vw;
  background: url("https://files.skg.com/skg.com/20210129/d113dc29b2574c9191b6cbb8a6c9d7c0.jpg");
  background-size: 100%;
  .pcSwiper {
    display: none;
  }
}
.culture{
  width: 100vw;
  background: #42d7c8;
  img{
    width: 90+vw;
    margin-left: 5vw;
    height: 80vw;
  }
  .order {
    padding-top: 25px;
    text-align: center;
    font-family: DFPGB_Y5;
    font-size: 16px;
  }
  .orderContent {
    font-size: 18px;
    font-family: DFPGB_Y5;
    text-align: center;
    line-height: 40px;
  }
}
.aboutInfo {
  box-sizing: border-box;
  .tit {
    width: 100vw;
    height: 40vw;
    .aboutTit {
      font-size: 24px;
      font-family: DFPGB_Y9;
      text-align: center;
      padding-top: 12vw;
    }
    .knowMore {
      text-align: center;
      font-family: DFPGB_Y5;
      font-size: 14px;
      margin-top: 5vw;
    }
  }
  .inFoImg {
    display: block;
    width: 100%;
  }
  .aboutSwiper {
    display: block;
  }
}
.pcAboutInfo {
  display: none;
}
.conetent {
  display: none;
}
.joinUs {
  display: none;
}
.historyTit {
  display: none;
}
.brshow {
  display: none;
}
@media screen  and (min-width: 768px){
  .mission {
    background: url("https://files.skg.com/skg/2023-02-22/dc3c070d-4373-45c4-8a42-a2e12b3d9760.gif") no-repeat;
    background-size: 100%;
    height: 54vw;
    .missionTxt {
      display: none;
    }
  }
  .aboutVideo  {
    .videoBox{
      width: 53vw;
      height: 30vw;
      margin-left: 23vw;
    }
    #videoAutoPlay {
      width: 15vw;
      height: 15vw;
    }
    .Video {
      width: 54vw;
      height: 30vw;
    }
    .videoBottom {
      width: 54vw;
      left: 2vw;
    }
    .aboutStory {
      font-size: 30px;
      margin-top: 45px;
       line-height: 30px;
    }
    .aboutStoryEn {
      font-size: 14px;
      line-height: 14px;
    }
    .descripttion {
      padding-top: 20px;
      text-align: left;
      width: 53vw;
      margin-left: 23vw;
      font-size: 18px;
      line-height: 30px;
    }
  }
  .history {
    background: url("https://files.skg.com/skg/2023-02-23/4058d839-5ea2-476a-89bf-bb36d1e7a896.jpg") no-repeat;
    background-size: 100%;
    width: 100vw;
    height: 56vw;
    .historyTit {
      font-size: 26px;
      text-align: center;
      display: block;
      font-family: DFPGB_Y5;
      padding-top: 30px;
      font-weight: bold;
    }
    .pcSwiper {
      display: block;
      overflow: hidden;
    }
  }
  .descripttion {
    width: 100vw;
    height: 18vh;
    padding: 0 15px;
    font-family: DFPGB_Y5;
    font-size: 12px;
    line-height: 20px;
    color: #857171;
  }
  .history1 {
    background: url("https://files.skg.com/skg/2023-02-23/19e90e54-f70f-48f8-ad24-9181843eb221.jpg") no-repeat;
    background-size: 100%;
    animation: sildIn 2s linear;
    -moz-animation: sildIn 2s linear;	/* Firefox */
    -webkit-animation: sildIn 2s linear;	/* Safari 和 Chrome */
    -o-animation: sildIn 2s linear;	/* Opera */
  }
  @keyframes sildIn
    {
    0%   {
      height: 0vw;
    }
    25%   {
      height: 11.25vw;
    }
    50%   {
      height: 22.5vw;
    }
    75%   {
      height: 33.75vw;
    }
    100% {
      height: 45vw;
    }
  }
  .more {
    display: block;
    width: 180px;
    height: 54px;
    border: 1px solid #000;
    border-radius: 30px;
    text-align: center;
    line-height: 54px;
    font-size: 24px;
    margin: 20px auto;
    cursor: pointer;
  }
  .aboutSwiper {
    display: none;
  }
  .culture {
    background: url("https://files.skg.com/skg/2023-02-23/cb2c4599-8811-49d1-a29f-b46a8ccdd885.png") no-repeat;
    background-position: center;
    background-size: 100%;
    height: 55vw;
    padding-top: 30vw;
    box-sizing: border-box;
    .conetent {
      width: 70vw;
      margin-left: 15vw;
      background-color: #fff;
      height: 20vw;
      border-radius: 20px;
      padding: 50px 0;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .item {
        width: 50%;
        padding-left: 100px;
        &:first-child {
          border-right: 1px solid #888;
        }
      }
      .title {
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 30px;
      }
      .desc {
        font-size: 16px;
        line-height: 24px;
        color: #888;
      }
    }
  }
  .aboutInfo {
    display: none;
  }
  .pcAboutInfo {
    display: block;
    width: 100vw;
    height: 50vw;
  }
  .joinUs {
    display: block;
    width: 100%;
    height: 35vw;
    background: url("https://files.skg.com/skg/2023-02-27/6c269b57-1372-4d93-99c6-da82ed2f57be.png") no-repeat;
  
    .job {
      text-align: center;
      font-size: 36px;
      font-family: DFPGB_Y5;
      color: #fff;
      padding-top: 8vw;
    }
    a {
      display: block;
      width: 10vw;
      height: 2vw;
      border-radius: 50px;
      text-align: center;
      font-family: DFPGB_Y5;
      font-size: 14px;
      margin-top: 1vw;
      line-height: 2vw;
      color: #fff;
      margin-left: 45vw;
      border: 1px solid #ffff;
    }
  }
  .brshow {
    display: block;
  }
}
</style>
